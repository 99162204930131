import React from 'react';
import styled from 'styled-components';
import {
  endOfCurrentDay,
  endOfLastMonth,
  endOfLastQuarter,
  insertQueryParamsIntoURL,
  startOfCurrentYear,
  startOfLastMonth,
  startOfLastQuarter,
} from 'poly-utils';
import { number, shape } from 'prop-types';
import { routes } from '../../routes.js';
import { TotalItem } from '../../components/TotalItem.js';

const SpendTotalContainerS = styled.div`
  width: 100%;
  height: 175px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  padding: 30px 30px 20px 30px;
  background: #fff;
`;

const spentTotalItemPropTypes = shape({
  total: number,
  growthRate: number,
});

const constructSpendDetailsUrl = (startDate, endDate) =>
  insertQueryParamsIntoURL(
    {
      startDate,
      endDate,
    },
    routes.spendReport,
  );

export function SpendTotalInfo({
  lastMonthSpend,
  lastQuarterSpend,
  yearToDateSpend,
  completedProjectsLast30Days,
}) {
  return (
    <SpendTotalContainerS>
      <TotalItem
        title="Last month spend"
        growthRate={lastMonthSpend.growthRate}
        total={lastMonthSpend.total}
        url={constructSpendDetailsUrl(startOfLastMonth(), endOfLastMonth())}
      />
      <TotalItem
        title="Last quarter spend"
        growthRate={lastQuarterSpend.growthRate}
        total={lastQuarterSpend.total}
        url={constructSpendDetailsUrl(startOfLastQuarter(), endOfLastQuarter())}
      />
      <TotalItem
        title="Year date to spend"
        growthRate={yearToDateSpend.growthRate}
        total={yearToDateSpend.total}
        url={constructSpendDetailsUrl(startOfCurrentYear(), endOfCurrentDay())}
      />
      <TotalItem
        title="Work orders completed last 30 days"
        growthRate={completedProjectsLast30Days.growthRate}
        total={completedProjectsLast30Days.total}
        iconName="work-order"
        isMoney={false}
        alignTooltipLeft
      />
    </SpendTotalContainerS>
  );
}

SpendTotalInfo.propTypes = {
  lastMonthSpend: spentTotalItemPropTypes,
  lastQuarterSpend: spentTotalItemPropTypes,
  yearToDateSpend: spentTotalItemPropTypes,
  completedProjectsLast30Days: spentTotalItemPropTypes,
};

SpendTotalInfo.defaultProps = {
  lastMonthSpend: {},
  lastQuarterSpend: {},
  yearToDateSpend: {},
  completedProjectsLast30Days: {},
};
