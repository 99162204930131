import * as R from 'ramda';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { startOfDay, endOfDay, subMonths } from 'date-fns';
import { shape, string, instanceOf, func, bool, arrayOf } from 'prop-types';
import { PrintAndExportButtons, A, DateRangePiker, ALERTS } from 'poly-site-ui';
import { Layout, Header, Heading, Button, S } from 'poly-book';
import {
  CREATE_CLIENT_SPEND_REPORT_WITH_HIERARCHY_MUTATION,
  getSpendReportsXlsConfig,
  performExcelExport,
  showBase64PDF,
} from 'poly-client-utils';
import {
  DEFAULT_PROPERTY_HIERARCHY_OPTION,
  DEFAULT_PROPERTY_HIERARCHY_NAME,
  EXPORT_XLS_CAPTION,
} from 'poly-constants';
import {
  getPropertiesDeepByHierarchyNodeR,
  isNilOrEmpty,
  formatDate,
  toDate,
} from 'poly-utils';

import { Navigation } from '../Navigation.js';
import { PropertySelect } from './PropertySelect.js';
import { HierarchySelect } from './HierarchySelect.js';
import { spendReportHierarchyPropTypes } from './prop-types.js';
import { useHierarchyOptions } from '../../hooks/useHierarchyOptions.js';
import { toMutationInput } from './helpers.js';

const ButtonS = styled(Button)`
  margin-left: 35px;
`;

function ExportButtonComponent({ exportFunction }) {
  return <A onClick={exportFunction}>{EXPORT_XLS_CAPTION}</A>;
}

ExportButtonComponent.propTypes = {
  exportFunction: func,
};

// prepareExportSpendReport :: [SpendReport] -> [ClientPortalReportData]
const prepareExportSpendReport = R.compose(
  R.flatten,
  R.map(getPropertiesDeepByHierarchyNodeR([])),
);

// viewSpendReportPdfP :: ClientSpendReportResult -> Promise _ _
const viewSpendReportPdfP = R.compose(
  showBase64PDF,
  R.path(['createClientSpendReportWithHierarchy', 'fileString']),
);

export function SpendReportHeader({
  reports,
  clientId,
  clientName,
  isTransparent,
  setFilter,
  filter,
  isClientWithoutAnyHierarchy,
  defaultStartDate,
  defaultEndDate,
  selectedPropertyOptions,
}) {
  const { options, loading } = useHierarchyOptions();
  const [createClientSpendReportWithHierarchyMutation] = useMutation(
    CREATE_CLIENT_SPEND_REPORT_WITH_HIERARCHY_MUTATION,
  );
  const [filterValues, setFilterValues] = useState({
    startDate: defaultStartDate
      ? toDate(defaultStartDate)
      : startOfDay(subMonths(new Date(), 1)),
    endDate: defaultStartDate ? toDate(defaultEndDate) : endOfDay(new Date()),
    hierarchyId: DEFAULT_PROPERTY_HIERARCHY_OPTION,
    ...selectedPropertyOptions,
  });

  const exportSpendReport = () => {
    if (isNilOrEmpty(reports)) {
      toast.error(ALERTS.NoDataToDisplay);
      return;
    }

    const config = getSpendReportsXlsConfig(
      prepareExportSpendReport(reports),
      clientName,
      formatDate(filter.startDate),
      formatDate(filter.endDate),
      isTransparent,
    );
    performExcelExport(config);
  };

  const printSpendReport = async () => {
    if (isNilOrEmpty(reports)) {
      toast.error(ALERTS.NoDataToDisplay);
      return;
    }

    const { data } = await createClientSpendReportWithHierarchyMutation({
      variables: {
        input: {
          ...toMutationInput(filter),
          clientId,
        },
      },
    });

    await viewSpendReportPdfP(data);
  };

  const onPreview = () => setFilter(filterValues);

  const onChangeDateRange = ({ startDate, endDate }) =>
    setFilterValues({
      ...filterValues,
      startDate: startDate ? startOfDay(toDate(startDate)) : null,
      endDate: endDate ? endOfDay(toDate(endDate)) : null,
    });

  const onChangeProperty = (propertyId = []) => {
    setFilterValues({
      ...filterValues,
      propertyId,
    });
  };

  const onChangeHierarchy = (hierarchy) => {
    setFilterValues({
      ...filterValues,
      ...(hierarchy ? { hierarchyId: hierarchy.value } : {}),
    });
  };

  return (
    <Layout.Header>
      <Navigation />
      <Header.Sub>
        <Header.Row>
          <Header.Block>
            <Heading.h1 lighter>Spend Report</Heading.h1>
          </Header.Block>
          <Header.Block>
            <PrintAndExportButtons
              printTitle="Spend Report"
              exportFunction={exportSpendReport}
              printFunction={() => printSpendReport}
              ExportButtonComponent={ExportButtonComponent}
            />
          </Header.Block>
        </Header.Row>
        <Header.Row>
          <Header.Block>
            <DateRangePiker
              startDate={filterValues.startDate}
              endDate={filterValues.endDate}
              onChange={onChangeDateRange}
            />
            <PropertySelect
              onChange={onChangeProperty}
              value={filterValues.propertyId}
            />
            {!isClientWithoutAnyHierarchy && (
              <HierarchySelect
                value={filterValues.hierarchyId}
                onChange={onChangeHierarchy}
                isDisabled={loading}
                options={[
                  {
                    value: DEFAULT_PROPERTY_HIERARCHY_OPTION,
                    label: DEFAULT_PROPERTY_HIERARCHY_NAME,
                  },
                  ...options,
                ]}
              />
            )}
            <ButtonS onClick={onPreview}>
              <S type="title">Preview</S>
            </ButtonS>
          </Header.Block>
        </Header.Row>
      </Header.Sub>
    </Layout.Header>
  );
}

SpendReportHeader.propTypes = {
  clientId: string,
  clientName: string,
  setFilter: func.isRequired,
  isTransparent: bool,
  isClientWithoutAnyHierarchy: bool,
  reports: arrayOf(shape(spendReportHierarchyPropTypes)),
  filter: shape({
    startDate: instanceOf(Date),
    endDate: instanceOf(Date),
    propertyId: arrayOf(
      shape({ value: string.isRequired, label: string.isRequired }),
    ),
    hierarchyId: string,
  }).isRequired,
  defaultStartDate: string,
  defaultEndDate: string,
  selectedPropertyOptions: shape({
    propertyId: arrayOf(
      shape({
        value: string.isRequired,
        label: string.isRequired,
      }),
    ),
  }),
};
