import * as R from 'ramda';
import { projectsChanged, projectsQuery } from 'poly-site-ui';
import { useSelector } from 'react-redux';
import {
  commonSortQuery,
  useReactiveQuery,
  useScoredSortingWithSearchText,
} from 'poly-client-utils';
import { DESC_SORT_ORDER } from 'poly-constants';
import { getProjectsSearchQuery } from './helpers.js';
import { useCurrentClientId } from '../../hooks/useCurrentClientId.js';

export const useProjectsQuery = (
  status,
  fetchPolicy,
  sort = commonSortQuery(['createdAt'])(DESC_SORT_ORDER),
) => {
  const searchText = useSelector(R.prop('searchText'));

  const clientId = useCurrentClientId();

  const modifiedSort = useScoredSortingWithSearchText({ sort, searchText });

  const { currentPage, size } = useSelector(R.prop('paginator'));

  const query = getProjectsSearchQuery(status, clientId);

  const { data, loading, fetchMore } = useReactiveQuery(
    projectsQuery,
    projectsChanged,
    {
      queryOptions: {
        variables: {
          input: {
            searchTerm: searchText,
            query,
            sort: modifiedSort,
            from: (currentPage - 1) * size,
            size,
          },
        },
        ...(fetchPolicy ? { fetchPolicy } : {}),
      },
      subscriptionOptions: {
        variables: {
          input: {
            searchTerm: searchText,
            query,
            sort: modifiedSort,
            from: (currentPage - 1) * size,
            size,
          },
        },
      },
    },
  );

  return {
    projects: R.pathOr([], ['searchProjects', 'hits'], data),
    total: R.pathOr(0, ['searchProjects', 'total'], data),
    loading,
    fetchMore,
  };
};
