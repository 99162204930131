import React from 'react';
import * as R from 'ramda';
import { arrayOf, shape, string } from 'prop-types';
import { Icon } from 'poly-book';
import { formatDate } from 'poly-utils';
import styled from 'styled-components';

import { applyPathOrNothingUI } from 'poly-client-utils';
import { midDarkColor, textColor } from '../DetailsSection.js';
import { ProjectLink } from '../../../components/ProjectLink.js';

const WOHistoryItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 8px 0;
  :not(:last-child) {
    border-bottom: 1px solid rgba(188, 188, 188, 0.2);
  }
  cursor: pointer;
`;

const WOHistoryContentContainer = styled.div`
  position: absolute;
  top: 80px;
  right: 16px;
  left: 16px;
  bottom: 0;
  overflow-y: auto;
`;

const WOHistoryColumnTitle = styled.span`
  color: ${midDarkColor};
  text-transform: uppercase;
  font-weight: 500;
`;

const WOHistoryColumnText = styled.span`
  color: ${textColor};
`;

const AssetWOHistoryContainer = styled.div`
  display: grid;
  background-color: #fafafa;
  padding: 16px;
  align-content: flex-start;
  position: relative;
`;

const AssetWOHistoryTitleContainer = styled.div`
  display: grid;
  font-size: 16px;
  line-height: 24px;
  color: ${textColor};
  margin-bottom: 8px;
  grid-template-columns: auto 1fr;
  align-items: center;
  & > svg {
    margin-right: 5px;
  }
`;

// formatProjects :: [Project] -> [Project]
const formatProjects = R.map(
  R.applySpec({
    projectId: R.prop('projectId'),
    startDate: applyPathOrNothingUI(['startDate'], formatDate),
    endDate: applyPathOrNothingUI(['endDate'], formatDate),
  }),
);

export function AssetWOHistory({ projects }) {
  const items = formatProjects(projects);
  return (
    <AssetWOHistoryContainer>
      <AssetWOHistoryTitleContainer>
        <Icon name="history" dimensions={{ width: 18, height: 16 }} />
        Work Order History
      </AssetWOHistoryTitleContainer>
      <WOHistoryItemContainer>
        <WOHistoryColumnTitle>Project #</WOHistoryColumnTitle>
        <WOHistoryColumnTitle>Start Date</WOHistoryColumnTitle>
        <WOHistoryColumnTitle>End Date</WOHistoryColumnTitle>
      </WOHistoryItemContainer>
      <WOHistoryContentContainer>
        {items.map(({ projectId, startDate, endDate }) => (
          <WOHistoryItemContainer key={projectId}>
            <WOHistoryColumnText>
              <ProjectLink projectId={projectId} />
            </WOHistoryColumnText>
            <WOHistoryColumnText>{startDate}</WOHistoryColumnText>
            <WOHistoryColumnText>{endDate}</WOHistoryColumnText>
          </WOHistoryItemContainer>
        ))}
      </WOHistoryContentContainer>
    </AssetWOHistoryContainer>
  );
}

AssetWOHistory.propTypes = {
  projects: arrayOf(
    shape({
      projectId: string.isRequired,
      startDate: string.isRequired,
      endDate: string,
    }),
  ),
};
