import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Text = styled.span`
  display: inline-flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.meta};
  font-size: 16px;
  color: #2d2f33;

  > svg {
    flex-shrink: 0;
    margin-right: 12px;
  }
`;

const Link = styled(Text.withComponent('a'))`
  text-decoration: none;
  color: #4676b4;
`;

function Username({
  name,
  firstName,
  lastName,
  children,
  href,
  onClick,
  showLink,
}) {
  const Wrapper = href || showLink ? Link : Text;
  const wrapperProps = {
    ...(href ? { href } : { onClick }),
    title: name,
  };
  return (
    <Wrapper {...wrapperProps}>
      {children}
      {firstName ? `${firstName} ${lastName}` : name}
    </Wrapper>
  );
}

Username.defaultProps = {
  onClick: () => {},
};

Username.propTypes = {
  name: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  children: PropTypes.node,
  href: PropTypes.string,
  onClick: PropTypes.func,
  showLink: PropTypes.bool,
};

Username.displayName = 'Username';
Text.displayName = 'UsernameText';
Link.displayName = 'UsernameLink';

export default Username;
