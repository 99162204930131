import { css } from 'styled-components';

const wordBreak = 'word-break: break-word';

export const wordBreakFallback = css`
  ${wordBreak};

  @supports not (${wordBreak}) {
    & {
      word-break: break-all;
    }
  }
`;
