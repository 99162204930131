import styled from 'styled-components';
import Select from 'react-select';

import Loader from '../Loader/index.js';

export const LoaderS = styled(Loader)`
  left: auto;
`;

export const SelectS = styled(Select)`
  width: 100%;
  min-width: 180px;
  background-color: transparent;

  & > .Select__control {
    border-color: #e3e5ed;
    border-radius: 3px;
    box-shadow: none;
  }

  & > .Select__control:hover {
    border-color: #e3e5ed;
  }

  .Select__indicator.Select__dropdown-indicator {
    padding: 8px 10px 8px 0;
  }

  .Select__indicator.Select__dropdown-indicator > svg {
    fill: #000;
    stroke-width: 1px;
    stroke: #fff;
    height: 22px;
    width: 22px;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__indicator.Select__clear-indicator {
    padding: 8px 0;
  }

  .Select__indicator.Select__clear-indicator > svg {
    height: 16px;
    width: 16px;
    fill: #000;
  }

  & > .Select__control > .Select__value-container {
    padding: 10px 15px;
    font-size: 14px;
    line-height: normal;
    white-space: nowrap;
  }

  & > .Select__control > .Select__value-container > .Select__placeholder {
    width: 100%;
    padding-right: 15px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > .Select__control > .Select__value-container > div:last-child {
    margin: 0;
    padding: 0;
  }

  &.is-invalid > .Select__control,
  &.is-invalid.is-focused > .Select__control {
    border-color: #ee0d45;
  }

  & > .Select__menu {
    z-index: 10000;
  }
`;
