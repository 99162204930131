import * as R from 'ramda';
import { useQuery } from '@apollo/client';
import { PREVIEW_CLIENT_SPEND_REPORT_BY_HIERARCHY } from 'poly-client-utils';
import {
  ClientSpendReportTypes,
  DEFAULT_PROPERTY_HIERARCHY_OPTION,
} from 'poly-constants';
import {
  isNilOrEmpty,
  prepareHierarchyNotDuplicatedProperties,
} from 'poly-utils';
import { toMutationInput } from './helpers.js';

// getSpendReportData :: ID -> SpendReportQueryData -> [PropertyHierarchySpendReportResult]
const prepareSpendReport = (hierarchyId) =>
  R.compose(
    R.reject(R.isEmpty),
    R.map(R.omit(['filterHierarchyId'])),
    R.ifElse(
      R.either(
        R.pathSatisfies(isNilOrEmpty, ['0', 'filterHierarchyId']),
        R.pathEq(['0', 'filterHierarchyId'], DEFAULT_PROPERTY_HIERARCHY_OPTION),
      ),
      R.compose(
        R.map(R.over(R.lensProp('properties'), R.uniqBy(R.prop('propertyId')))),
        R.filter(R.propSatisfies(R.isNil, '_id')),
      ),
      prepareHierarchyNotDuplicatedProperties(hierarchyId),
    ),
    R.map(R.assoc('filterHierarchyId', hierarchyId)),
    R.propOr([], 'previewClientSpendReportByHierarchy'),
  );

// getClientDataByProperty :: SpendReportQueryData -> { clientName: String, isTransparent: true }
const getClientDataByProperty = R.compose(
  R.applySpec({
    clientName: R.prop('name'),
    isTransparent: R.pathEq(
      ['financial', 'spendReportType'],
      ClientSpendReportTypes.TRANSPARENT,
    ),
  }),
  R.pathOr({}, [
    'previewClientSpendReportByHierarchy',
    '0',
    'properties',
    '0',
    'client',
  ]),
);

export const useSpendReportQuery = (filter, clientId) => {
  const skip = R.isEmpty(filter) || !filter?.startDate || !filter?.endDate;

  const { data, loading } = useQuery(PREVIEW_CLIENT_SPEND_REPORT_BY_HIERARCHY, {
    variables: {
      input: { ...toMutationInput(filter), clientId },
    },
    skip: skip || !clientId,
    fetchPolicy: 'network-only',
  });

  const hierarchyId = filter ? filter.hierarchyId : null;
  const reports = prepareSpendReport(hierarchyId)(data);

  const { clientName, isTransparent } = getClientDataByProperty(data);

  return {
    reports,
    loading,
    clientId,
    clientName,
    isTransparent,
  };
};
