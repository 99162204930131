import * as R from 'ramda';
import { gql, useQuery } from '@apollo/client';
import { useCurrentClientId } from '../../hooks/useCurrentClientId.js';

const clientSpendTotalQuery = gql`
  query clientSpendTotalReportQuery($input: ClientSpendTotalReportInput!) {
    clientSpendTotalReport(input: $input) {
      lastMonthSpend {
        total
        growthRate
      }
      lastQuarterSpend {
        total
        growthRate
      }
      yearToDateSpend {
        total
        growthRate
      }
      completedProjectsLast30Days {
        total
        growthRate
      }
    }
  }
`;

export const useCurrentClientSpendTotalQuery = () => {
  const clientId = useCurrentClientId();
  const { data } = useQuery(clientSpendTotalQuery, {
    variables: { input: { clientId } },
    fetchPolicy: 'network-only',
  });

  const {
    lastMonthSpend,
    lastQuarterSpend,
    yearToDateSpend,
    completedProjectsLast30Days,
  } = R.propOr({}, 'clientSpendTotalReport', data);
  return {
    lastMonthSpend,
    lastQuarterSpend,
    yearToDateSpend,
    completedProjectsLast30Days,
  };
};
