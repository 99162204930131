import { useEffect } from 'react';
import { useLocation, useNavigate } from 'poly-client-routing';

import { useIsTokenValid } from './useIsTokenValid.js';

// eslint-disable-next-line import/no-unused-modules
export const useCheckTokenWithRedirect = (route) => {
  const { isTokenValid, loading } = useIsTokenValid();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const loginRoute = `${route}?previousUrl=${pathname}${search}`;

  useEffect(() => {
    if (!loading && !isTokenValid) {
      navigate(loginRoute);
    }
  }, [isTokenValid]);

  return { isTokenValid, loading };
};
