import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';

import { initialPaginatorState } from './redux/reducer.js';
import {
  setTotal as setTotalAction,
  resetPaginator,
  setCurrent,
  setLabel,
  setSize,
} from './redux/actions.js';

export const usePaginatorState = () => {
  const dispatch = useDispatch();
  const paginator = useSelector(R.propOr(initialPaginatorState, 'paginator'));

  const setPageSize = (size) => dispatch(setSize(size));

  const resetPagination = () => dispatch(resetPaginator());

  const setTotal = (total) => dispatch(setTotalAction(total));

  const setCurrentPage = (currentPage) => dispatch(setCurrent(currentPage));

  const setDropdownLabel = (dropdownLabel) => dispatch(setLabel(dropdownLabel));

  return {
    setTotal,
    paginator,
    setPageSize,
    setCurrentPage,
    resetPagination,
    setDropdownLabel,
  };
};
