import React from 'react';
import styled from 'styled-components';
import { Modal, IconButton } from 'poly-book';
import { bool, func, node, string } from 'prop-types';
import { Icon } from '../../components/Icon.js';

const Container = styled.div`
  width: 600px;
  max-height: 500px;
  margin-top: 15px;
  max-height: 300px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.span`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #1a1514;
`;

export function InvoicesModal({ show, closeModal, title, children }) {
  return (
    <Modal show={show} padding={20}>
      <HeaderContainer>
        <Title>{title}</Title>
        <IconButton onClick={closeModal}>
          <Icon name="close" dimensions={{ width: 10 }} />
        </IconButton>
      </HeaderContainer>
      <Container>{children}</Container>
    </Modal>
  );
}

InvoicesModal.propTypes = {
  show: bool.isRequired,
  closeModal: func.isRequired,
  children: node.isRequired,
  title: string.isRequired,
};
