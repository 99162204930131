import { string } from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const EmptyListMsgContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px;
  letter-spacing: 2px;
`;
export function EmptyListMsg({ entity }) {
  return <EmptyListMsgContainer>No {entity} To Display</EmptyListMsgContainer>;
}

EmptyListMsg.propTypes = {
  entity: string,
};
