import { createActions, handleActions } from 'redux-actions';

export const { setImagesGalleryModal } = createActions(
  'SET_IMAGES_GALLERY_MODAL',
);

export const imagesGalleryReducer = handleActions(
  {
    [setImagesGalleryModal]: (state, { payload }) => ({
      ...state,
      modal: payload,
    }),
  },
  {
    modal: null,
  },
);
