import * as R from 'ramda';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { string, bool, shape, arrayOf, object } from 'prop-types';
import { renameProp } from 'poly-utils';
import { Loader, GlobalSearchNavigation } from 'poly-site-ui';

import { ProjectsContainer } from './tabs/ProjectsContainer.js';
import { PropertiesContainer } from './tabs/PropertiesContainer.js';
import { clientPortalGlobalSearchQuery } from './global-search-query.js';

const SearchLoader = styled(Loader)`
  position: absolute;
  background-color: transparent;
`;

const Wrapper = styled.section`
  padding-top: 10px;
  overflow: auto;
  height: calc(100% - 40px);
`;

const clientPortalGlobalSearchConfigs = [
  {
    title: "WO's",
    value: 'searchProjectsWO',
    container: ProjectsContainer,
  },
  {
    title: 'Properties',
    value: 'searchProperties',
    container: PropertiesContainer,
  },
];

const noDataToDisplay = (entity) => (
  <div
    style={{
      textAlign: 'center',
      letterSpacing: '2px',
    }}
  >{`No ${entity} to Display`}</div>
);

function GlobalSearchResults({ currentConfig, searchTerm, loading, hits }) {
  if (loading) {
    return <SearchLoader />;
  }

  if (hits.length === 0) {
    return noDataToDisplay(currentConfig.title);
  }

  const Container = currentConfig.container;
  return <Container hits={hits} searchTerm={searchTerm} />;
}

GlobalSearchResults.propTypes = {
  currentConfig: shape({ title: string.isRequired }).isRequired,
  loading: bool,
  searchTerm: string,
  // eslint-disable-next-line react/forbid-prop-types
  hits: arrayOf(object).isRequired,
};

// sortConfigsByRelevance :: Object -> [SiteSearchConfig]
const sortConfigsByRelevance = (data) =>
  R.sortBy(
    R.compose(
      R.multiply(-1), // descending order
      R.pathOr(0, R.__, data),
      R.append('maxScore'),
      R.of,
      R.prop('value'),
    ),
  )(clientPortalGlobalSearchConfigs);

export function GlobalSearchDataContainer() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const searchTerm = useSelector(
    R.path(['globalSearch', 'globalSearchString']),
  );

  const isSearchFilled = useSelector(
    R.path(['globalSearch', 'isSearchFilled']),
  );

  // reset tab after every hide/show of panel
  useEffect(() => {
    setActiveTabIndex(0);
  }, [isSearchFilled]);

  const commonQueryInput = { searchTerm, size: 50 };
  const { data, loading } = useQuery(clientPortalGlobalSearchQuery, {
    variables: {
      input: commonQueryInput,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: searchTerm.length < 3,
  });

  const preparedData = renameProp('searchProjects', 'searchProjectsWO')(data);

  const sortedConfigs = sortConfigsByRelevance(preparedData);
  const activeSearchConfig = sortedConfigs[activeTabIndex];

  const hits = R.pathOr([], [activeSearchConfig.value, 'hits'], preparedData);

  return (
    <>
      <GlobalSearchNavigation
        configs={sortedConfigs}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      <Wrapper>
        <GlobalSearchResults
          currentConfig={activeSearchConfig}
          searchTerm={searchTerm}
          hits={hits}
          loading={loading}
        />
      </Wrapper>
    </>
  );
}
