import * as R from 'ramda';
import {
  createExcelExportCell,
  ExcelExportCellType,
  performExcelExport,
} from 'poly-client-utils';
import { formatDate, forceTitleCase } from 'poly-utils';
import { ProjectPrioritiesUIMap } from 'poly-constants';

const COLUMN_WIDTH = [15, 15, 15, 35, 35, 12, 12, 10];

const TITLE_ROW = [
  'WO #',
  'Priority',
  'Service Type',
  'Description',
  'Property',
  'Start',
  'End Date',
  'Status',
];

// getWorkOrdersRowConfig :: Project -> [ExcelExportDataCell]
const getWorkOrdersRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 8),
  ]),
  R.juxt([
    R.propOr('', 'projectId'),
    R.compose(R.prop(R.__, ProjectPrioritiesUIMap), R.prop('priority')),
    R.pathOr('', ['serviceType', 'name']),
    R.propOr('', 'description'),
    R.pathOr('', ['property', 'name']),
    R.compose(formatDate, R.propOr('', 'startDate')),
    R.compose(formatDate, R.propOr('', 'endDate')),
    R.compose(forceTitleCase, R.propOr('', 'status')),
  ]),
);

// getWorkOrdersDirectoryExcelConfig :: [Project] -> Object
export const getWorkOrdersDirectoryExcelConfig = (projects) => ({
  exportFileName: 'work_orders_directory_export.xlsx',
  columnWidths: COLUMN_WIDTH,
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 8)],
      TITLE_ROW,
    ),
    ...R.map(getWorkOrdersRowConfig, projects),
  ],
});

export const exportWorkOrdersDirectoryToXLS = R.compose(
  performExcelExport,
  getWorkOrdersDirectoryExcelConfig,
  R.pathOr([], ['data', 'searchProjects', 'hits']),
);
