import React from 'react';
import { OutSidebarProvider } from 'poly-client-utils';
import { node } from 'prop-types';
import { Layout, SidebarSiteLayout } from 'poly-book';
import { useCheckTokenWithRedirect } from 'poly-client-utils/src/hooks/useCheckTokenWithRedirect.js';

import { routes } from '../routes.js';

export function MainLayout({ children }) {
  const { isTokenValid, loading } = useCheckTokenWithRedirect(routes.login);

  if (loading || !isTokenValid) {
    return null;
  }

  return (
    <OutSidebarProvider Layout={SidebarSiteLayout}>
      <Layout>{children}</Layout>
    </OutSidebarProvider>
  );
}

MainLayout.propTypes = {
  children: node.isRequired,
};
