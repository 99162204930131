import * as R from 'ramda';
import { applyPathOrNothingUI, pathOrNothingUI } from 'poly-client-utils';
import { formatDate, formatTotal } from 'poly-utils';

// getAssetDetails :: Asset -> Object
export const getAssetDetails = R.applySpec({
  assetId: R.prop('_id'),
  status: R.prop('status'),
  photo: R.propOr({}, 'photo'),
  description: pathOrNothingUI(['description']),
  manufacturer: pathOrNothingUI(['manufacturerDoc', 'name']),
  model: pathOrNothingUI(['modelDoc', 'name']),
  serial: pathOrNothingUI(['serial']),
  equipmentType: pathOrNothingUI(['type', 'name']),
  location: pathOrNothingUI(['location']),
  polyAssetCode: pathOrNothingUI(['qrCodeId']),
  name: pathOrNothingUI(['name']),
  property: pathOrNothingUI(['property', 'name']),
  warrantyExpiration: applyPathOrNothingUI(['warrantyEnd'], formatDate),
  purchasePrice: applyPathOrNothingUI(['purchasePrice'], formatTotal),
  commissioningDate: applyPathOrNothingUI(['commissioningDate'], formatDate),
  projects: R.pathOr([], ['searchProjects', 'hits']),
  displayName: pathOrNothingUI(['displayName']),
});
