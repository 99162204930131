import * as R from 'ramda';
import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import { useRouterParams } from 'poly-client-routing';
import { READ_ASSET_PERMISSION } from 'poly-security';
import { NOTHING_UI_STRING } from 'poly-constants';
import { endOfCurrentDay } from 'poly-utils';
import { Loader } from 'poly-site-ui';
import { Layout } from 'poly-book';
import {
  useHasUserAccessWithPermission,
  formatAddressString,
  useReactiveQuery,
  pathOrNothingUI,
} from 'poly-client-utils';

import {
  propertyStatusesUIMap,
  propertyStatusesColorsMap,
} from '../../../constants/property.js';
import {
  DetailsRow,
  DetailsSection,
} from '../../ProjectsPage/DetailsSection.js';
import { Navigation } from '../../Navigation.js';
import { tabsNames } from '../../../constants/tabs.js';
import { PropertyFilesTab } from '../PropetyFilesTab.js';
import { PropertyLink } from '../../../components/Links.js';
import { PropertyUpdatesTab } from '../PropertyUpdatesTab.js';
import { TabsWithSearchSidebar } from '../../../components/Tabs.js';
import { PropertyWOsTotalSection } from './PropertyTotalsSection.js';
import { EntityOverviewContainer } from '../../../components/Layout.js';
import { getStartDateOfRange, ranges } from '../../../constants/common.js';
import { PropertyMasterProjectsTab } from '../PropertyMasterProjectsTab.js';
import { SubLinksComponent } from '../../../components/SubLinksComponent.js';
import { PropertyPMTab, PropertyProjectsTab } from '../PropertyProjectsTab.js';
import { DetailsHeader, HeaderContainer } from '../../../components/Header.js';
import { OverviewDateSelector } from '../../../components/OverviewDateSelector.js';
import { prepareTabProps } from '../../ProjectsPage/ProjectDetailsPage.js';
import {
  usePropertyAssetsQuery,
  PropertyAssetsTab,
} from '../PropertyAssetsTab.js';

const propertyDetailsQuery = gql`
  query propertyDetailsQuery(
    $propertyId: ID!
    $spendTotalInput: PropertySpendTotalInput!
  ) {
    property(id: $propertyId) {
      _id
      name
      status
      aacContractMGR {
        fullName
      }
      hierarchies {
        name
        path
      }
      isMaster
      childProperties {
        _id
        name
      }
      masterProperty {
        _id
        name
      }
      addressTwo
      address {
        address_parts {
          street_number
          route
          locality
          administrative_area_level_1
          postal_code
        }
      }
      spendTotal(input: $spendTotalInput)
    }
  }
`;

const propertySubscription = gql`
  subscription propertySubscription($input: SingleDocSubInput!) {
    propertyChanged(input: $input) {
      id
      type
    }
  }
`;

// getPropertyFromMutationData :: Object -> Property
const getPropertyFromMutationData = R.prop('property');

const usePropertyDetailsQuery = (propertyId, selectedRange) => {
  const { data, loading } = useReactiveQuery(
    propertyDetailsQuery,
    propertySubscription,
    {
      queryOptions: {
        variables: {
          propertyId,
          spendTotalInput: {
            startDate: getStartDateOfRange(selectedRange),
            endDate: endOfCurrentDay(),
          },
        },
      },
      subscriptionOptions: { variables: { input: { id: propertyId } } },
    },
  );
  return {
    property: getPropertyFromMutationData(data),
    loading,
  };
};

const tabs = [
  { title: 'WO’s', value: tabsNames.projects },
  { title: 'Recurring', value: tabsNames.recurring },
  { title: 'PMs', value: tabsNames.pms },
  { title: 'Files', value: tabsNames.files },
  { title: 'Updates', value: tabsNames.updates },
  { title: 'Assets', value: tabsNames.assets },
];

const propertyTabsMap = {
  [tabsNames.projects]: PropertyProjectsTab,
  [tabsNames.recurring]: PropertyMasterProjectsTab,
  [tabsNames.pms]: PropertyPMTab,
  [tabsNames.files]: PropertyFilesTab,
  [tabsNames.updates]: PropertyUpdatesTab,
  [tabsNames.assets]: PropertyAssetsTab,
};

// getPropertyDetails :: Property -> Object
const getPropertyDetails = R.applySpec({
  status: R.prop('status'),
  address: formatAddressString,
  masterProperty: R.prop('masterProperty'),
  hierarchies: R.pathOr([], ['hierarchies']),
  subProperties: R.pathOr([], ['childProperties']),
  manager: pathOrNothingUI(['aacContractMGR', 'fullName']),
});

function PropertyDetailsSection({ property }) {
  const {
    status,
    address,
    manager,
    hierarchies,
    subProperties,
    masterProperty,
  } = getPropertyDetails(property);

  return (
    <DetailsSection
      title="Property Detail"
      status={{
        text: propertyStatusesUIMap[status],
        color: propertyStatusesColorsMap[status],
      }}
    >
      <DetailsRow title="Address" content={address} />
      <DetailsRow title="Property Manager" content={manager} />
      {hierarchies.map((hierarchy) => (
        <DetailsRow
          key={hierarchy.name}
          title={hierarchy.name}
          content={hierarchy.path}
        />
      ))}
      {!!masterProperty && (
        <DetailsRow
          title="Master Property"
          content={<PropertyLink {...masterProperty} />}
        />
      )}
      {subProperties.length > 0 && (
        <DetailsRow
          title="Sub Properties"
          content={
            <SubLinksComponent
              subLinks={subProperties}
              LinkComponent={PropertyLink}
            />
          }
        />
      )}
    </DetailsSection>
  );
}

PropertyDetailsSection.propTypes = {
  property: shape({
    name: string.isRequired,
    status: string.isRequired,
  }),
};

export function PropertyDetailsPage() {
  const { propertyId } = useRouterParams(['propertyId']);
  const [selectedRange, setSelectedRange] = useState(ranges.days30);
  const { property, loading } = usePropertyDetailsQuery(
    propertyId,
    selectedRange,
  );

  const isAuthorizedToReadAssets = useHasUserAccessWithPermission(
    READ_ASSET_PERMISSION,
  );

  const { total, loading: propertyLoading } = usePropertyAssetsQuery(
    propertyId,
    null,
    !isAuthorizedToReadAssets,
  );

  const tabsProps = prepareTabProps(total === 0, {
    tabs,
    activeTabsMap: propertyTabsMap,
  });

  return (
    <Layout>
      <HeaderContainer>
        <Navigation />
        <DetailsHeader
          title={property ? property.name : NOTHING_UI_STRING}
          subtitle={property?.isMaster ? 'Master Property' : 'Property'}
        />
      </HeaderContainer>
      <Layout.Content columns="450px 1fr">
        {loading || propertyLoading ? (
          <Loader />
        ) : (
          <PropertyDetailsSection property={property} />
        )}
        <EntityOverviewContainer>
          <OverviewDateSelector
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
          />
          <PropertyWOsTotalSection
            propertyId={propertyId}
            selectedRange={selectedRange}
            property={property}
          />
          <TabsWithSearchSidebar
            defaultTab={tabsNames.projects}
            propertyId={propertyId}
            loading={loading}
            {...tabsProps}
          />
        </EntityOverviewContainer>
      </Layout.Content>
    </Layout>
  );
}
