import React from 'react';
import * as R from 'ramda';
import { arrayOf, shape, string, node, oneOfType, array } from 'prop-types';
import { useSelector } from 'react-redux';
import { DESC_SORT_ORDER } from 'poly-constants';
import { Loader, Paginator } from 'poly-site-ui';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import {
  useHighlightMatchesBySearch,
  useReactiveQuery,
} from 'poly-client-utils';
import { Holder } from 'poly-book';
import { DetailsRow } from '../DetailsSection.js';
import { AssetWOHistory } from './AssetsWOHistory.js';
import { getAssetDetails } from '../../AssetDetails/helpers.js';
import { AssetLink } from '../../../components/Links.js';
import { useViewAssetPhoto } from '../../AssetDetails/AssetDetailsSection.js';

const MAX_SIZE = 1000;

const ProjectAssetsPaginatorContainer = styled(Holder)`
  justify-content: flex-end;
  margin: 10px 20px 10px 0;
`;

const projectAssetsQuery = gql`
  query getProjectAssets(
    $searchInput: CollectionSearchParams
    $searchProjectsInput: CollectionSearchParams
  ) {
    searchAssets(input: $searchInput) {
      hits {
        _id
        displayName
        description
        photo {
          url
          fileName
        }
        manufacturerDoc {
          _id
          name
        }
        modelDoc {
          _id
          name
        }
        serial
        type {
          _id
          name
        }
        qrCodeId
        location
        commissioningDate
        equipmentType
        searchProjects(input: $searchProjectsInput) {
          hits {
            _id
            startDate
            endDate
            projectId
          }
        }
      }
      total
    }
  }
`;

const ProjectAssetsTabContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  align-content: start;
  position: relative;
`;

// getProjectAssets :: SearchProjectAssetsQueryResult -> [Asset]
const getProjectAssets = R.pathOr([], ['searchAssets', 'hits']);

// getProjectAssetsCount :: SearchProjectAssetsQueryResult -> Number
const getProjectAssetsCount = R.pathOr(0, ['searchAssets', 'total']);

const SEARCH_ASSETS_SUB = gql`
  subscription SEARCH_ASSETS_SUB($input: CollectionSearchParams!) {
    searchAssetChanged(input: $input) {
      id
      type
    }
  }
`;

const useProjectAssetsQuery = (assetIds) => {
  const searchText = useSelector(R.prop('searchText'));

  const { currentPage, size } = useSelector(R.prop('paginator'));

  const searchInput = {
    searchTerm: searchText,
    from: (currentPage - 1) * size,
    size,
    query: {
      terms: { _id: assetIds },
    },
  };

  const { data, loading } = useReactiveQuery(
    projectAssetsQuery,
    SEARCH_ASSETS_SUB,
    {
      queryOptions: {
        variables: {
          searchInput,
          searchProjectsInput: {
            from: 0,
            size: MAX_SIZE,
            sort: [{ startDate: DESC_SORT_ORDER }],
          },
        },
        skip: !assetIds.length,
        fetchPolicy: 'network-only',
      },
      subscriptionOptions: {
        variables: {
          input: searchInput,
        },
        skip: !assetIds.length,
      },
    },
  );

  return {
    assets: getProjectAssets(data),
    total: getProjectAssetsCount(data),
    loading,
  };
};

const AssetItemContainer = styled.div`
  display: grid;
  grid-template-columns: 30% 1fr 1fr;
  grid-template-rows: minmax(300px, 350px);
  grid-column-gap: 24px;
  padding: 20px;
  border-bottom: 1px solid #f2f2f2;
  overflow-y: auto;
  position: relative;
`;

const AssetImage = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const AssetDetailsContainer = styled.div`
  display: grid;
  align-content: flex-start;
`;

const AssetTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 15px;
`;

const AssetImageContainer = styled.div`
  background: #eaeaea;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const DefaultImage = styled.img.attrs({
  src: '/placeholder.png',
})`
  width: 50px;
  height: 50px;
`;

function AssetPhoto({ url }) {
  const onClickAssetPhoto = useViewAssetPhoto({ url });
  return (
    <AssetImageContainer>
      {url ? (
        <AssetImage src={url} onClick={onClickAssetPhoto} />
      ) : (
        <DefaultImage />
      )}
    </AssetImageContainer>
  );
}

AssetPhoto.propTypes = {
  url: string,
};

function AssetItem({
  assetId,
  displayName,
  photo,
  description,
  polyAssetCode,
  manufacturer,
  model,
  serial,
  equipmentType,
  location,
  commissioningDate,
  projects,
}) {
  return (
    <AssetItemContainer>
      <AssetPhoto url={photo.url} />
      <AssetDetailsContainer>
        <AssetTitle>
          <AssetLink displayName={displayName} _id={assetId} />
        </AssetTitle>
        <DetailsRow title="Description" content={description} />
        <DetailsRow title="Poly Asset Code" content={polyAssetCode} />
        <DetailsRow title="Manufacturer" content={manufacturer} />
        <DetailsRow title="Model Number" content={model} />
        <DetailsRow title="Serial Number" content={serial} />
        <DetailsRow title="Equipment Type" content={equipmentType} />
        <DetailsRow title="Location" content={location} />
        <DetailsRow title="Commissioning Date" content={commissioningDate} />
      </AssetDetailsContainer>
      <AssetWOHistory projects={projects} />
    </AssetItemContainer>
  );
}

AssetItem.propTypes = {
  displayName: oneOfType([string, array]).isRequired,
  photo: shape({ url: string, fileName: string }),
  description: node.isRequired,
  polyAssetCode: string.isRequired,
  manufacturer: node.isRequired,
  model: node.isRequired,
  serial: node.isRequired,
  equipmentType: node.isRequired,
  location: node.isRequired,
  commissioningDate: string.isRequired,
  projects: arrayOf(shape({ projectId: string.isRequired })),
  assetId: string.isRequired,
};

export function ProjectAssetsTab({ assetIds }) {
  const { assets, loading, total } = useProjectAssetsQuery(assetIds);
  const { highlightedRows } = useHighlightMatchesBySearch(
    R.map(getAssetDetails),
    [
      'description',
      'manufacturer',
      'model',
      'serial',
      'equipmentType',
      'location',
      'polyAssetCode',
      'displayName',
    ],
    assets,
    true,
  );

  return (
    <ProjectAssetsTabContainer>
      {loading ? (
        <Loader />
      ) : (
        <>
          {highlightedRows.map((asset) => (
            <AssetItem key={asset.assetId} {...asset} />
          ))}
          <ProjectAssetsPaginatorContainer>
            <Paginator total={total} />
          </ProjectAssetsPaginatorContainer>
        </>
      )}
    </ProjectAssetsTabContainer>
  );
}

ProjectAssetsTab.propTypes = {
  assetIds: arrayOf(string),
};
