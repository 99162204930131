import styled from 'styled-components';
import { Message, S } from 'poly-book';

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  background-color: #fcfcfc !important;
  width: 100%;
  margin: 0;
  padding: 24px;
  border-top: 1px solid rgba(197, 198, 201, 0.35);
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 !important;
  width: 100% !important;
  align-items: flex-start !important;
  justify-content: space-between;
`;

export const ToggleRepliesWrapper = styled(ButtonsContainer)`
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.5;
  width: 100%;
  padding: 10px 20px;
  background-color: #fcfcfc;
  ${({ isOpen }) =>
    isOpen ? 'border-bottom: 1px solid rgba(197, 198, 201, 0.35)' : ''};
`;

export const ToggleRepliesButton = styled.div`
  display: flex;
  cursor: pointer;

  > div {
    margin-left: 8px;
    font-size: 12px;
    line-height: 18px;
  }

  :hover {
    > svg path {
      fill: #000;
    }

    > div {
      color: #000;
    }
  }
`;

export const AdditionalReplyInfo = styled.div`
  color: #2d2f33;
  font-size: 12px;
  line-height: 18px;

  > span {
    color: #2d2f33;
    font-weight: 700;
  }
`;

export const RepliesWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #fcfcfc;
`;

export const MessageHeader = styled(Message.Header)`
  > a {
    font-size: 14px;

    > svg {
      margin-right: 16px;
    }
  }
`;

export const AttachmentsWrapper = styled.div`
  &:not(:empty) {
    margin-left: 40px;
    margin-top: 10px;
  }
`;

export const Content = styled(S)`
  white-space: pre-line;
  word-break: break-all;
`;

export const DateTime = styled(S)`
  white-space: pre-wrap;
`;

export const ImagesRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > div:not(:last-child) {
    margin-right: 20px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  height: 100px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 20px;

  :hover {
    opacity: 0.7;
  }

  > img {
    height: 100px;
    width: 100px;
  }
`;
