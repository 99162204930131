import { WorkOrderStatus as WO } from 'poly-constants';

// Statuses displayed in projects table
export const PROJECT_STATUS_COLORS = {
  active: '#9bdc53',
  pastDue: '#ee0d45',
  closed: '#9ea2a9',
  onHold: '#ffbc28',
  blocked: '#3f4c57',
};

const { active, onHold, closed, blocked } = PROJECT_STATUS_COLORS;

export const SITE_PROJECT_STATUS_TEXT_MAP = {
  [WO.ACTIVE]: 'ACTIVE',
  [WO.IN_PROCESS]: 'IN PROCESS',
  [WO.COMPLETED]: 'COMPLETED',
  [WO.REJECTED]: 'REJECTED',
  [WO.ON_HOLD_CLIENT]: 'ON HOLD CLIENT',
  [WO.ON_HOLD_PARTS]: 'ON HOLD PARTS',
  [WO.ON_HOLD_PROJECT]: 'ON HOLD PROJECT',
  [WO.BLOCKED]: 'BLOCKED',
};

export const PROJECT_STATUS_TEXT_MAP = {
  ...SITE_PROJECT_STATUS_TEXT_MAP,
  [WO.PENDING]: 'PENDING',
};

export const PROJECT_STATUS_COLOR_MAP = {
  [WO.ACTIVE]: active,
  [WO.IN_PROCESS]: active,
  [WO.COMPLETED]: closed,
  [WO.REJECTED]: closed,
  [WO.ON_HOLD_CLIENT]: onHold,
  [WO.ON_HOLD_PARTS]: onHold,
  [WO.ON_HOLD_PROJECT]: onHold,
  [WO.PENDING]: onHold,
  [WO.BLOCKED]: blocked,
};
