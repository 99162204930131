import React, { useState } from 'react';
import { string } from 'prop-types';
import { Grid, L, Modal, S } from 'poly-book';
import styled from 'styled-components';
import { HREF_PLACEHOLDER } from 'poly-constants';
import { ErrorText } from './Form/ValidationMessage.js';

const Link = S.withComponent(L);
const Wrapper = Modal.Item.withComponent(ErrorText);
const ErrorWrapper = styled(Grid.Cell)`
  grid-row-gap: 10px;
`;

const commonErrorMessage =
  'Oops, something went wrong. You may be able to try again.';

const href = HREF_PLACEHOLDER;

export function ServerError({ error, errorMessage, ...props }) {
  const [showDetails, setShowDetails] = useState(false);

  const onClick = () => setShowDetails(!showDetails);

  if (!error) {
    return null;
  }

  return (
    <Wrapper margin={40} {...props}>
      <ErrorWrapper>
        <S type="title" textColor="#a94442">
          {errorMessage || commonErrorMessage}
          <Link size={12} {...{ href, onClick }}>
            {showDetails ? 'hide details' : 'show details'}
          </Link>
        </S>
        {showDetails && (
          <S type="title" textColor="#a94442">
            {error}
          </S>
        )}
      </ErrorWrapper>
    </Wrapper>
  );
}

ServerError.propTypes = {
  error: string,
  errorMessage: string,
};
