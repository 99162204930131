import { createActions } from 'redux-actions';

export const { setSize, setLabel, setTotal, setCurrent, resetPaginator } =
  createActions(
    'SET_SIZE',
    'SET_LABEL',
    'SET_TOTAL',
    'SET_CURRENT',
    'RESET_PAGINATOR',
  );
