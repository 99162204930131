import React from 'react';
import PropTypes from 'prop-types';
import { vsprintf } from 'sprintf-js';
import styled from 'styled-components';

import Widget from '../Widget/index.js';
import IconButton from '../IconButton/index.js';

const iconAligns = ['left', 'right'];

const isReversed = (iconAlign) => iconAlign === 'right';

const alignIcon = ({ iconAlign }) =>
  iconAligns.includes(iconAlign) && `${iconAlign}: 10px`;

const addPaddings = (iconAlign) => {
  const aligns = isReversed(iconAlign) ? [...iconAligns].reverse() : iconAligns;

  return vsprintf('padding-%s: 35px; padding-%s: 15px', aligns);
};

const LabelElement = styled.label`
  width: 100%;
  display: block;
  position: relative;
  cursor: text;

  > svg,
  > ${IconButton} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${alignIcon};
  }
`;

const CommonStyles = styled(Widget)`
  width: 100%;
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #5e6271;
  background-color: transparent;

  &[disabled],
  &[disabled]:hover {
    user-select: none;
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &::placeholder {
    color: #babfd2;
  }

  ${({ invalid }) => invalid && 'border-color: #ee0d45;'};
`;

export const Input = styled(CommonStyles.withComponent('input')).attrs(
  ({ type, name }) => ({
    type: type || 'text',
    'aria-label': name,
  }),
)`
  padding: 10px 15px;
  line-height: normal;
  ${({ icon, iconAlign }) => icon && addPaddings(iconAlign)};
  ${({ type }) => type === 'number' && 'padding-right: 0'};
`;

Input.displayName = 'Input';

export const TextArea = styled(CommonStyles.withComponent('textarea'))`
  min-height: 98px; // 3 lines
  max-height: 230px; // 9 lines
  line-height: normal;
  padding: 15px;
  resize: vertical;
  ${({ icon, iconAlign }) => icon && addPaddings(iconAlign)};
`;

TextArea.displayName = 'TextArea';

export const InputWithIcon = React.forwardRef((props, ref) => {
  const { type, icon, iconAlign } = props;

  const Component = type === 'textarea' ? TextArea : Input;

  const content = [icon, <Component key="input" {...props} ref={ref} />];

  if (isReversed(iconAlign)) {
    content.reverse();
  }

  return <LabelElement {...{ iconAlign }}>{content}</LabelElement>;
});

InputWithIcon.displayName = 'InputWithIcon';

InputWithIcon.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.node.isRequired,
  iconAlign: PropTypes.oneOf(iconAligns).isRequired,
};

InputWithIcon.defaultProps = { type: 'text' };

const CharacterCount = styled.span`
  display: block;
  padding-bottom: 3px;
  font-size: 12px;
  line-height: 12px;
  color: #7e7e7e;
`;

export function InputWithLengthLimit({ limit, value, ...props }) {
  const charsLeft = typeof value === 'string' ? limit - value.length : 0;

  return (
    <div>
      <CharacterCount>{charsLeft} chars left</CharacterCount>
      <Input {...props} value={value} maxLength={limit} />
    </div>
  );
}

InputWithLengthLimit.propTypes = {
  value: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired,
};
