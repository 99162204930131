import * as R from 'ramda';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import { usePaginatorState } from './usePaginatorState.js';
import { Pagination } from './Pagination.js';
import { ALERTS } from '../../constants/alerts.js';

const MAX_SIZE = 10000;

export function Paginator({ total }) {
  const {
    paginator,
    setTotal,
    setPageSize,
    setCurrentPage,
    resetPagination,
    setDropdownLabel,
  } = usePaginatorState();

  const onChange = (page) => setCurrentPage(page);

  const onShowSizeChange = ({ value, label }) => {
    if (R.equals(value, MAX_SIZE)) {
      toast.error(ALERTS.MaxRangeError);
      return;
    }

    const newCurrent = total ? Math.ceil(total / value) : 1;

    if (paginator.currentPage > newCurrent) {
      setCurrentPage(newCurrent);
    }

    setPageSize(value);
    setDropdownLabel(label);
  };

  useEffect(() => {
    setTotal(total);

    if (total !== paginator.total) {
      setCurrentPage(1);
    }
    return () => {
      if (!total) {
        resetPagination();
      }
    };
  }, [total]);

  return <Pagination {...{ onChange, onShowSizeChange }} {...paginator} />;
}

Paginator.displayName = 'Paginator';
Paginator.propTypes = { total: PropTypes.number };
Paginator.defaultProps = { total: 0 };
