import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { centsToDollarsWithFormat, formatDate, isNilOrEmpty } from 'poly-utils';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants';
import { Grid } from 'poly-book';

import {
  FooterText,
  GridScrollBody,
  MoneyGridCell,
  TextCenter,
} from '../SpendReportPage/components.js';
import { spendReportCellType } from '../SpendReportPage/constants.js';
import { ProjectLink } from '../../components/ProjectLink.js';
import { AssetLink } from '../../components/Links.js';
import { SpendReportTableHeader } from '../SpendReportPage/SpendReportTableHeader.js';
import { CollapseBase } from '../SpendReportPage/SpendReportHierarchyTree.js';
import { TableWrapper, GridRow } from '../SpendReportPage/SpendReportTable.js';
import { spendReportTableHeaderPropTypes } from '../SpendReportPage/prop-types.js';

const AssetReactiveSpendReportTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  border-top: 1px solid #f5f2f2;
`;

const FooterTotal = styled.span`
  font-size: 16px;
`;

const getAssetReactiveSpendReportTableConfig = (isTransparent) => [
  [
    { title: 'Project #', type: spendReportCellType.default },
    ({ projectNumber }) => (
      <ProjectLink projectId={projectNumber} target="_blank" />
    ),
  ],
  [
    { title: 'Asset', type: spendReportCellType.default },
    ({ assetDisplayName, assetId }) => (
      <AssetLink displayName={assetDisplayName} _id={assetId} target="_blank" />
    ),
  ],
  [
    { title: 'Completion Date', type: spendReportCellType.default },
    R.compose(formatDate, R.prop('workCompletionDate')),
  ],
  [
    { title: 'Invoice Date', type: spendReportCellType.default },
    R.compose(formatDate, R.prop('clientInvoicesDate')),
  ],
  [
    { title: 'Description', type: spendReportCellType.default },
    R.propOr(NOTHING_UI_STRING, 'projectDescription'),
  ],
  ...(isTransparent
    ? [
        [
          { title: 'Cost', type: spendReportCellType.money },
          R.compose(
            centsToDollarsWithFormat,
            R.propOr(0, 'supplierInvoiceAmount'),
          ),
        ],
      ]
    : []),
  [
    { title: 'Client Invoice', type: spendReportCellType.money },
    R.compose(centsToDollarsWithFormat, R.propOr(0, 'clientInvoiceAmount')),
  ],
];

const useAssetReactiveSpendReportTableProps = ({ report, isTransparent }) => {
  const tableConfig = getAssetReactiveSpendReportTableConfig(isTransparent);

  return {
    rows: report?.reports || [],
    columns: R.map(R.nth(1), tableConfig),
    headers: R.map(R.nth(0), tableConfig),
    total: report?.total || 0,
    gridColumns: isTransparent
      ? 'minmax(50px, 100px) 1fr repeat(2, minmax(75px, 130px)) 1fr repeat(2, minmax(100px, 150px))'
      : 'minmax(75px, 100px) minmax(100px, 230px) repeat(2, minmax(75px, 150px)) 1fr minmax(100px, 150px)',
  };
};

function AssetReactiveSpendReportTableFooter({ total }) {
  return (
    <FooterWrapper>
      <FooterText>Total:</FooterText>
      <FooterTotal>{centsToDollarsWithFormat(total)}</FooterTotal>
    </FooterWrapper>
  );
}

AssetReactiveSpendReportTableFooter.propTypes = {
  total: number,
};

function AssetReactiveSpendReportComponent({
  columns,
  headers,
  gridColumns,
  assetsReport,
}) {
  return (
    <TableWrapper>
      <Grid columns={gridColumns}>
        <Grid.Body>
          {assetsReport.map((report) => (
            <GridRow key={`row_${report.assetId}_${report.projectNumber}`}>
              {columns.map((column, index) =>
                headers[index].type === spendReportCellType.money ? (
                  <MoneyGridCell key={`cell_${headers[index]?.title}`}>
                    {column(report)}
                  </MoneyGridCell>
                ) : (
                  <Grid.Cell
                    key={`cell_${report._id}_${headers[index]?.title}`}
                  >
                    {column(report)}
                  </Grid.Cell>
                ),
              )}
            </GridRow>
          ))}
        </Grid.Body>
      </Grid>
    </TableWrapper>
  );
}

AssetReactiveSpendReportComponent.propTypes = {
  columns: arrayOf(func),
  headers: arrayOf(shape(spendReportTableHeaderPropTypes)),
  gridColumns: string,
  assetsReport: arrayOf(
    shape({
      assetId: string,
      projectNumber: string,
      workCompletionDate: string,
      clientInvoicesDate: string,
      projectDescription: string,
      supplierInvoiceAmount: number,
      clientInvoiceAmount: number,
    }),
  ),
};

export function AssetReactiveSpendReportTable({ report, isTransparent }) {
  const { rows, total, ...tableProps } = useAssetReactiveSpendReportTableProps({
    report,
    isTransparent,
  });

  if (isNilOrEmpty(report.reports)) {
    return <TextCenter>No data to report</TextCenter>;
  }

  return (
    <AssetReactiveSpendReportTableWrapper>
      <div>
        <SpendReportTableHeader {...tableProps} />
        <GridScrollBody>
          {rows.map(
            ({ _id, properties, assetTypeName, total: assetTypeTotal }) => (
              <CollapseBase
                key={_id}
                title={assetTypeName}
                level={0}
                clientInvoiceTotal={centsToDollarsWithFormat(assetTypeTotal)}
                content={
                  <>
                    {properties.map(
                      ({
                        propertyName,
                        propertyId,
                        total: propertyTotal,
                        assetsReport,
                      }) => (
                        <CollapseBase
                          key={propertyId}
                          title={propertyName}
                          level={1}
                          clientInvoiceTotal={centsToDollarsWithFormat(
                            propertyTotal,
                          )}
                          withFooterTotals
                          content={
                            <AssetReactiveSpendReportComponent
                              assetsReport={assetsReport}
                              {...tableProps}
                            />
                          }
                        />
                      ),
                    )}
                  </>
                }
              />
            ),
          )}
        </GridScrollBody>
      </div>
      <AssetReactiveSpendReportTableFooter total={total} />
    </AssetReactiveSpendReportTableWrapper>
  );
}

AssetReactiveSpendReportTable.propTypes = {
  report: shape({ total: number }).isRequired,
  isTransparent: bool.isRequired,
};
