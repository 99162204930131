import styled from 'styled-components';

const Overlay = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
  position: ${({ absolute }) => (absolute && 'absolute') || 'relative'};
  background-color: rgba(
    ${({ darker }) => (darker && '200, 200, 200, 0.5') || '0, 0, 0, 0.05'}
  );
`;

export default Overlay;
