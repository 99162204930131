import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import S from '../S/index.js';
import Pip from '../Pip/index.js';
import { lighten } from '../utils/color.js';

const Active = css`
  ${S} {
    color: #2d2f33;
  }

  ${Pip} {
    box-shadow: 0 0 0 2px #fcfcfc,
      0 0 0 4px ${({ color }) => lighten(color, 30)};
  }
`;

const FilterByStatus = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 12px 15px;
  border-radius: 3px;
  background-color: ${({ active }) => (active ? '#fcfcfc' : 'transparent')};
  border: 1px solid ${({ active }) => (active ? '#e3e5ed' : 'transparent')};
  cursor: pointer;

  &:hover {
    ${Active};
  }

  ${({ active }) => active && Active};

  ${Pip} {
    margin-right: 10px;
    transition: box-shadow 0.3s;
  }

  ${S} {
    margin-top: 3px;
  }
`;

FilterByStatus.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

FilterByStatus.defaultProps = {
  color: '#9fa2ab',
};

FilterByStatus.displayName = 'FilterByStatus';

export default FilterByStatus;
