import React from 'react';
import { SiteGlobalStyle, SiteOldVersionDetector, Toaster } from 'poly-site-ui';

import { Router } from './Router.js';
import { AppProvider } from './providers/AppProvider.js';

export function App() {
  return (
    <AppProvider>
      <SiteGlobalStyle />
      <Router />
      <Toaster />
      <SiteOldVersionDetector />
    </AppProvider>
  );
}
