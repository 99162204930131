import styled from 'styled-components';

const Title = styled.section`
  display: flex;
  align-items: center;
`;

Title.Block = styled.section`
  min-height: 30px;
  display: flex;

  &:not(:empty):not(:last-child) {
    margin-right: 80px;
  }
`;

Title.Item = styled(Title)`
  &:not(:empty):not(:last-child) {
    margin-right: 20px;
    padding-right: 20px;
    position: relative;

    &::after {
      width: 1px;
      height: 100%;
      display: block;
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      top: 0;
      background-color: #babfd2;
      opacity: 0.3;
    }
  }
`;

Title.displayName = 'Title';
Title.Block.displayName = 'Title.Block';
Title.Item.displayName = 'Title.Item';

export default Title;
