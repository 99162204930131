import { gql } from '@apollo/client';

const commonUpdateCommentsFields = gql`
  fragment commonUpdateCommentsFields on UpdateComment {
    _id
    message
    attachments {
      fileName
      fileType
      url
    }
    mentions {
      users {
        fullName
      }
      groups {
        name
      }
    }
    createdAt
    createdBy {
      _id
      firstName
      lastName
      fullName
    }
  }
`;

export const updateDetailsFragment = gql`
  fragment updateFragment on Update {
    _id
    title
    message
    createdAt
    createdBy {
      _id
      fullName
      firstName
      lastName
    }
    attachments {
      fileName
      fileType
      url
    }
    updateComments {
      ...commonUpdateCommentsFields
    }
  }
  ${commonUpdateCommentsFields}
`;

export const updatesSubscription = gql`
  subscription updatesChanged($input: SearchUpdateChangedInput!) {
    searchUpdateChanged(input: $input) {
      id
      type
    }
  }
`;
