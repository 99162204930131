import styled, { css } from 'styled-components';

const baseBoxShadow = '0 0 0 1px #2065bd';

const Widget = styled.section`
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #e3e5ed;
`;

Widget.Item = styled.section`
  padding: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ selected }) => (selected && '#2065bd') || '#e3e5ed'};
  }

  ${({ selected }) =>
    selected &&
    css`
      border-radius: 3px;
      box-shadow: ${baseBoxShadow}, inset ${baseBoxShadow};
    `};
`;

Widget.displayName = 'Widget';
Widget.Item.displayName = 'Widget.Item';

export default Widget;
