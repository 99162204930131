import { gql } from '@apollo/client';
import * as R from 'ramda';
import { assocBy, formatDate, forceTitleCase } from 'poly-utils';
import { arrayOf, object, oneOfType, string } from 'prop-types';
import { usePaginatorState } from 'poly-site-ui';
import {
  commonSortQuery,
  keywordSortQuery,
  paginationToQueryParams,
  useReactiveQuery,
  useScoredSortingWithSearchText,
} from 'poly-client-utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { DESC_SORT_ORDER } from 'poly-constants';

import { ProjectLink } from './ProjectLink.js';
import { TableWithPagination } from './TableWithPaginationContainer.js';
import { useTablePropsWithHighLight } from '../hooks/useTablePropsWithHighlight.js';
import { useTableSorting } from '../hooks/useTableSorting.js';
import { blockedProjectsSearchQuery } from '../pages/ProjectsPage/helpers.js';
import { HighlightedCell } from '../pages/ProjectsPage/ProjectsTable.js';

const propertyProjectsQuery = gql`
  query propertyProjectsQuery($input: CollectionSearchParams!) {
    searchProjects(input: $input) {
      hits {
        _id
        description
        startDate
        endDate
        projectId
        status
      }
      total
    }
  }
`;

const projectsSubscription = gql`
  subscription projectsChanged($input: CollectionSearchParams!) {
    searchProjectChanged(input: $input) {
      id
      type
    }
  }
`;

// duplicateProjectIdForHighLight :: [Project] -> [Project]
const duplicateProjectIdForHighLight = R.map(
  assocBy('highlightedProjectId', R.prop('projectId')),
);

// getProjectsFromResult :: Object -> [Project]
const getProjectsFromResult = R.pathOr([], ['searchProjects', 'hits']);

// getProjectsTotalFromResult :: Object -> [Project]
const getProjectsTotalFromResult = R.pathOr(0, ['searchProjects', 'total']);

const projectsColumns = [
  ['WO #', R.identity, ProjectLink, keywordSortQuery(['projectId'])],
  [
    'Description',
    R.prop('description'),
    HighlightedCell,
    keywordSortQuery(['description']),
  ],
  ['End Date', R.prop('endDate'), formatDate, commonSortQuery(['endDate'])],
  ['Status', R.prop('status'), forceTitleCase, commonSortQuery(['status'])],
];

const excludeBlockedProjects = R.over(
  R.lensPath(['bool', 'must_not']),
  R.ifElse(
    R.isNil,
    R.always(blockedProjectsSearchQuery),
    R.append(blockedProjectsSearchQuery),
  ),
);

export function EntityProjectsTab({ searchQuery }) {
  const { paginator } = usePaginatorState();
  const searchText = useSelector(R.prop('searchText'));

  const { sort, ...tableSortingProps } = useTableSorting(
    3,
    DESC_SORT_ORDER,
    projectsColumns,
  );

  const modifiedSort = useScoredSortingWithSearchText({ sort, searchText });

  const { size, currentPage } = paginator;

  const searchInput = {
    query: excludeBlockedProjects(searchQuery),
    searchTerm: searchText,
    ...paginationToQueryParams({
      itemsPerPage: size,
      currentPage,
    }),
    sort: modifiedSort,
  };

  const { data, loading } = useReactiveQuery(
    propertyProjectsQuery,
    projectsSubscription,
    {
      queryOptions: {
        variables: {
          input: searchInput,
        },
      },
      subscriptionOptions: {
        variables: { input: searchInput },
      },
    },
  );

  const projects = getProjectsFromResult(data);
  const total = getProjectsTotalFromResult(data);

  const tableProps = useTablePropsWithHighLight(
    duplicateProjectIdForHighLight(projects),
    projectsColumns,
    ['highlightedProjectId', 'description'],
  );

  return (
    <TableWithPagination
      total={total}
      loading={loading}
      gridColumns="
      minmax(75px, 150px)
      minmax(150px, 1fr)
      minmax(75px, 150px)
      minmax(80px, 160px)
    "
      entity="Projects"
      {...tableProps}
      {...tableSortingProps}
    />
  );
}

EntityProjectsTab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  searchQuery: object.isRequired,
  sort: arrayOf(oneOfType([string, object])),
};

EntityProjectsTab.defaultProps = {
  sort: commonSortQuery(['startDate'])(DESC_SORT_ORDER),
};
