import styled from 'styled-components';

const Message = styled.article`
  display: flex;
  flex-direction: column;
`;

Message.Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

Message.Content = styled.section`
  margin-left: 40px;
  line-height: 20px;
  text-align: left;
`;

Message.displayName = 'Message';
Message.Header.displayName = 'Message.Header';
Message.Content.displayName = 'Message.Content';

export default Message;
