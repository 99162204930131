import { createAction, handleAction } from 'redux-actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const setSearchText = createAction('SET_SEARCH_TABLE');

export const searchTextReducer = handleAction(
  setSearchText,
  (_, { payload }) => payload,
  '',
);

export const useClearSearchText = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSearchText(''));
  }, []);
};
