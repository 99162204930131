import React from 'react';
import { TableSearchComponent, setCurrent } from 'poly-site-ui';

import { setSearchText } from '../redux/searchText.js';

export function TextSearchComponent(props) {
  return (
    <TableSearchComponent
      {...props}
      setTableSearchString={setSearchText}
      setPaginationCurrentPage={setCurrent}
    />
  );
}
