import { createActions, handleActions } from 'redux-actions';

export const { setFileModal } = createActions('SET_FILE_MODAL');

export const setFileModalReducer = handleActions(
  {
    [setFileModal]: (_, { payload }) => payload,
  },
  null,
);
