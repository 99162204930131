import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { MAX_FILE_SIZE } from 'poly-constants';
import { func, string } from 'prop-types';

import { ALERTS } from '../../constants/alerts.js';
import { UpdatesTabBase } from './UpdatesTabBase.js';
import { ImagesGallery } from '../ImagesGallery/ImagesGallery.js';
import { setImagesGalleryModal } from '../../redux/imagesGallery.js';

const addUpdateMutation = gql`
  mutation addUpdateMutation($input: CreateUpdateInput!) {
    createUpdate(input: $input) {
      _id
      createdAt
      message
    }
  }
`;

export function UpdatesTabWithAddUpdate({
  collection,
  documentId,
  type,
  handleSelectFile,
  refetch,
  updateTitle,
  ...props
}) {
  const dispatch = useDispatch();
  const [mutate, { loading }] = useMutation(addUpdateMutation);

  const [attachments, setAttachments] = useState([]);
  const [scrollbar, setTextAreaScrollbar] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const closeSelectedFile = () => setSelectedFile(null);

  const addUpdate = async (e) => {
    e.preventDefault();
    const message = R.path(['target', 'message', 'value'], e).trim();
    e.target.message.value = '';
    e.target.message.style.height = '40px';
    e.target.message.focus();

    if (!message && !attachments.length) {
      return;
    }

    try {
      await Promise.all([
        mutate({
          variables: {
            input: {
              filesAttachments: R.map(R.omit(['key']), attachments),
              collection,
              documentId,
              message,
              ...(updateTitle ? { title: updateTitle } : {}),
              ...(type ? { type } : {}),
            },
          },
        }),
        setAttachments([]),
      ]);
      toast.success(ALERTS.AddUpdateSuccess);
      refetch();
    } catch {
      toast.error(ALERTS.GeneralError);
    }
  };

  const onFileSelect = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
    }
  };

  const onFileConfirmed = (file) => {
    const fileSize = R.pathOr(0, ['upload', 'size'], file);

    if (fileSize > MAX_FILE_SIZE) {
      toast.error(ALERTS.MaxFileSizeError);
    } else {
      setAttachments([...attachments, file]);
      closeSelectedFile();
    }
  };

  const onFileRemove = (key) => {
    setAttachments(
      R.reject((attachment) => attachment.key === key, attachments),
    );
  };

  const onTextAreaChange = (event) => {
    const textArea = event.target;

    if (textArea.offsetHeight < textArea.scrollHeight) {
      textArea.style.height = `${textArea.scrollHeight}px`;
    } else {
      textArea.style.height = '1px';
      textArea.style.height = `${textArea.scrollHeight}px`;
    }

    const isScrollbar = textArea.offsetHeight >= 230;

    textArea.style['overflow-y'] = isScrollbar ? 'scroll' : 'hidden';
    setTextAreaScrollbar(isScrollbar);
  };

  const onImageClick = (images, index) =>
    dispatch(
      setImagesGalleryModal({
        images,
        currentIndex: index,
      }),
    );

  return (
    <>
      <UpdatesTabBase
        {...{
          selectedFile,
          closeSelectedFile,
          onFileSelect,
          onFileConfirmed,
          onFileRemove,
          onTextAreaChange,
          onImageClick,
          attachments,
          addUpdate,
          scrollbar,
          loading,
          ...props,
        }}
      />
      <ImagesGallery />
    </>
  );
}

UpdatesTabWithAddUpdate.propTypes = {
  collection: string.isRequired,
  documentId: string.isRequired,
  type: string,
  handleSelectFile: func,
  refetch: func,
  updateTitle: string,
};
