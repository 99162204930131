import * as R from 'ramda';
import React, { useState } from 'react';
import { Loader } from 'poly-site-ui';
import { useQuery } from '@apollo/client';
import { toDate } from 'poly-utils';
import { useRouterQuery } from 'poly-client-routing';

import { LayoutContent } from '../MainLayout.js';
import { SpendReportHierarchy } from './SpendReportHierarchy.js';
import { SpendReportHeader } from './SpendReportHeader.js';
import { useSpendReportQuery } from './useSpendReportQuery.js';
import { clientHierarchiesQuery } from '../../hooks/useHierarchyOptions.js';
import { useCurrentClientId } from '../../hooks/useCurrentClientId.js';

// checkIsClientWithoutAnyHierarchy :: { propertyHierarchies: [PropertyHierarchy] } -> Boolean
const checkIsClientWithoutAnyHierarchy = R.compose(
  R.isEmpty,
  R.propOr([], 'propertyHierarchies'),
  R.defaultTo({}),
);

export function SpendReportPage() {
  const clientId = useCurrentClientId();
  const { startDate, endDate, propertyId, propertyName } = useRouterQuery([
    'startDate',
    'endDate',
    'propertyId',
    'propertyName',
  ]);
  const selectedPropertyOptions = propertyId
    ? { propertyId: [{ value: propertyId, label: propertyName }] }
    : {};
  const [filter, setFilter] = useState({
    ...(startDate ? { startDate: toDate(startDate) } : {}),
    ...(endDate ? { endDate: toDate(endDate) } : {}),
    ...selectedPropertyOptions,
  });
  const { reports, isTransparent, clientName, loading } = useSpendReportQuery(
    filter,
    clientId,
  );

  const { data } = useQuery(clientHierarchiesQuery, {
    variables: { input: { clientId } },
  });

  const isClientWithoutAnyHierarchy = checkIsClientWithoutAnyHierarchy(data);

  return (
    <>
      <SpendReportHeader
        clientId={clientId}
        clientName={clientName}
        reports={reports}
        filter={filter}
        setFilter={setFilter}
        isTransparent={isTransparent}
        isClientWithoutAnyHierarchy={isClientWithoutAnyHierarchy}
        defaultStartDate={startDate}
        defaultEndDate={endDate}
        selectedPropertyOptions={selectedPropertyOptions}
      />
      <LayoutContent>
        {loading ? (
          <Loader absolute />
        ) : (
          <SpendReportHierarchy
            reports={reports}
            isTransparent={isTransparent}
            hierarchyId={filter.hierarchyId}
            isClientWithoutAnyHierarchy={isClientWithoutAnyHierarchy}
          />
        )}
      </LayoutContent>
    </>
  );
}
