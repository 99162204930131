import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Button from '../Button/index.js';
import Widget from '../Widget/index.js';
import Overlay from '../Overlay/index.js';

const ModalWrapper = styled(Widget.withComponent('section'))`
  margin: auto;
  margin-top: 50px;
  padding: ${R.prop('padding')}px;
  box-shadow: 0 0 15px 0 rgba(200, 200, 200, 0.5),
    0 0 4px 0 rgba(130, 145, 157, 0.5);
`;

const ModalOverlay = styled(Overlay)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  z-index: 100;
`;

function Modal({ show, children, className, ModalBtn, padding }) {
  if (!show) {
    return null;
  }

  return (
    <ModalOverlay {...{ className }} darker>
      {ModalBtn}
      <ModalWrapper padding={padding}>{children}</ModalWrapper>
    </ModalOverlay>
  );
}

Modal.Item = styled.section`
  &:not(:last-child) {
    margin-bottom: ${({ margin }) =>
      Number.isInteger(margin) ? margin : 15}px;
  }
`;

Modal.Content = styled.section`
  ${({ scroll }) =>
    scroll &&
    css`
      max-height: ${Number.isInteger(scroll) ? scroll : 300}px;
      overflow-y: auto;
    `};
`;

Modal.Buttons = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  ${Button}:not(:last-child) {
    margin-right: 20px;
  }
`;

Modal.Item.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

Modal.Content.propTypes = {
  scroll: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  ModalBtn: PropTypes.node,
  padding: PropTypes.number,
};

Modal.defaultProps = {
  className: '',
  ModalBtn: null,
  padding: 30,
};

ModalWrapper.displayName = 'ModalWrapper';
ModalOverlay.displayName = 'ModalOverlay';
Modal.displayName = 'Modal';
Modal.Item.displayName = 'Modal.Item';
Modal.Content.displayName = 'Modal.Content';
Modal.Buttons.displayName = 'Modal.Buttons';

export default Modal;
