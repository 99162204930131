import React from 'react';
import * as R from 'ramda';
import { bool, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { useCurrentUserQuery } from 'poly-client-utils';
import { CLIENT_PORTAL_APP_NAME } from 'poly-security';
import {
  fileNameFormatter,
  fileTypeFormatter,
  dateFormatter,
  FilesTab,
  Loader,
} from 'poly-site-ui';

// isClientPortalUser :: User -> Boolean
const isClientPortalUser = R.compose(
  R.includes(CLIENT_PORTAL_APP_NAME),
  R.map(R.path(['userGroup', 'defaultLoginApp'])),
  R.propOr([], 'userGroups'),
);

const filesColumns = [
  ['Type', R.prop('fileType'), fileTypeFormatter],
  ['Name', R.identity, fileNameFormatter],
  ['Created', R.prop('createdAt'), dateFormatter],
];

export function EntityFilesTab({ collection, documentId, withAddNewBtn }) {
  const searchText = useSelector(R.prop('searchText'));

  const { user, loading } = useCurrentUserQuery();

  if (loading) {
    return <Loader />;
  }

  const notClientPortalUser = !isClientPortalUser(user);

  return (
    <FilesTab
      collection={collection}
      documentId={documentId}
      fileColumns={filesColumns}
      withAddNewBtn={notClientPortalUser && withAddNewBtn}
      searchText={searchText}
    />
  );
}

EntityFilesTab.propTypes = {
  collection: string,
  documentId: string,
  withAddNewBtn: bool,
};
