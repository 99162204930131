import React, { useState, useMemo, useContext } from 'react';
import PropTypes, { string } from 'prop-types';
import * as R from 'ramda';
import { toast } from 'react-toastify';

import { PRINT_PDF_CAPTION } from 'poly-constants';
import { Holder, S } from 'poly-book';
import { ButtonWithLoading } from './ButtonWithLoading.js';
import { extractGQLOptionsFromProps } from '../utils/gql.js';
import { ALERTS } from '../constants/alerts.js';

// Context instead of redux because table props are too heavyweight
// and not serializable which makes redux devtools to crash the app
export const ExportTableContext = React.createContext({
  exportTableProps: {},
  setExportTableProps: () => {},
});

export const useExportTableContext = () => useContext(ExportTableContext);

export function ExportTableProvider({ children }) {
  const [exportTableProps, setExportTableProps] = useState({});
  const contextValue = useMemo(
    () => ({
      exportTableProps,
      setExportTableProps,
    }),
    [exportTableProps],
  );

  return (
    <ExportTableContext.Provider value={contextValue}>
      {children}
    </ExportTableContext.Provider>
  );
}

ExportTableProvider.propTypes = {
  children: PropTypes.element,
};

// eslint-disable-next-line react/prefer-stateless-function
class PrintButton extends React.Component {
  render() {
    const { exportTableProps } = this.context;
    return (
      <ButtonWithLoading
        title={PRINT_PDF_CAPTION}
        asyncAction={() => this.props.printFunction(exportTableProps)}
        textColor={this.props.textColor}
      />
    );
  }
}

PrintButton.contextType = ExportTableContext;
PrintButton.propTypes = {
  printFunction: PropTypes.func.isRequired,
  textColor: string,
};

const defaultTablePropsToVariables = (exportTableProps) => ({
  input: {
    ...extractGQLOptionsFromProps(exportTableProps).variables.input,
    from: 0,
    size: exportTableProps.total,
  },
});

function ExportButton(props) {
  const { exportTableProps } = useExportTableContext();
  const { tablePropsToVariablesForXlsQuery, exportFunction } = props;

  const asyncAction = () => {
    if (!exportTableProps.fetchMore) {
      toast.error(ALERTS.NoDataToDisplay);
      return Promise.resolve(null);
    }
    return R.composeWith(R.andThen, [
      exportFunction,
      exportTableProps.fetchMore,
    ])({
      variables: tablePropsToVariablesForXlsQuery(exportTableProps),
      updateQuery: R.identity,
    });
  };

  return <ButtonWithLoading asyncAction={asyncAction} />;
}

ExportButton.propTypes = {
  exportFunction: PropTypes.func.isRequired,
  tablePropsToVariablesForXlsQuery: PropTypes.func.isRequired,
};

export function PrintAndExportButtons({
  exportFunction,
  printFunction,
  marginRight,
  printTitle,
  tablePropsToVariablesForXlsQuery,
  ExportButtonComponent,
  textColor,
}) {
  return (
    <Holder style={{ marginRight }} margin={20}>
      <PrintButton
        printTitle={printTitle}
        printFunction={printFunction({ title: printTitle })}
        textColor={textColor}
      />
      <S
        type="badge"
        style={{ marginTop: 0 }}
        textColor={textColor || '#babfd2'}
      >
        |
      </S>
      <ExportButtonComponent
        exportFunction={exportFunction}
        tablePropsToVariablesForXlsQuery={tablePropsToVariablesForXlsQuery}
        textColor={textColor}
      />
    </Holder>
  );
}

PrintAndExportButtons.propTypes = {
  printTitle: PropTypes.string.isRequired,
  exportFunction: PropTypes.func.isRequired,
  marginRight: PropTypes.string,
  tablePropsToVariablesForXlsQuery: PropTypes.func,
  printFunction: PropTypes.func,
  ExportButtonComponent: PropTypes.func,
  textColor: PropTypes.string,
};

PrintAndExportButtons.defaultProps = {
  marginRight: '0px',
  tablePropsToVariablesForXlsQuery: defaultTablePropsToVariables,
  ExportButtonComponent: ExportButton,
};
