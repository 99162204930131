import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import { string } from 'prop-types';

import { ALERTS } from '../../constants/alerts.js';
import { ConfirmModal } from './ConfirmModal.js';
import { setFileModal } from '../../redux/fileModal.js';

const removeFile = gql`
  mutation removeFile($input: DeleteFileInput!) {
    deleteFile(input: $input) {
      id
    }
  }
`;

export function DeleteFileModal({ heading, collection, documentId }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fileModal = useSelector(R.prop('fileModal'));
  const dispatch = useDispatch();

  const [fileMutation] = useMutation(removeFile);

  const onConfirm = async () => {
    setLoading(true);

    try {
      await fileMutation({
        variables: {
          input: {
            collection,
            documentId,
            fileId: R.path(['data', '_id'], fileModal),
          },
        },
      });

      setLoading(false);
      toast.success('File Successfully Deleted');
      dispatch(setFileModal(null));
    } catch (err) {
      setLoading(false);
      setError(err.message);
      toast.error(ALERTS.GeneralError);
    }
  };

  const closeModal = () => {
    dispatch(setFileModal(null));
    return setError(null);
  };

  return (
    <ConfirmModal
      onConfirm={onConfirm}
      loading={loading}
      error={error}
      showModal={R.propEq('type', 'remove', fileModal)}
      closeModal={closeModal}
      heading={heading}
      subHeading="Delete File?"
      text="Are you sure that you want to delete the selected file?"
    />
  );
}

DeleteFileModal.propTypes = {
  heading: string,
  collection: string,
  documentId: string,
};
