import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Widget from '../Widget/index.js';
import { wordBreakFallback } from '../utils/typography.js';

const ALIGNS = ['start', 'center', 'end', 'stretch'];
const [DEFAULT_ALIGN] = ALIGNS;

const validateAlign = (align) =>
  (ALIGNS.includes(align) && align) || DEFAULT_ALIGN;

const Row = styled.section`
  display: grid;
`;

const GridRow = styled(Row)`
  position: relative;
  background-color: #fff;
`;

const GridHead = styled(Row)`
  background-color: transparent;
`;

const GridBody = styled(Widget)`
  ${({ withoutBorder }) => withoutBorder && 'border: none'};
`;

const GridCell = styled(Row)`
  justify-content: ${({ horizontal }) => validateAlign(horizontal)};
  align-content: ${({ vertical }) => validateAlign(vertical)};
  ${wordBreakFallback};
`;

const Grid = styled.div`
  display: grid;
  grid-auto-rows: min-content;

  ${GridHead}, ${GridRow} {
    padding: ${({ simple }) => (simple ? '10px 0' : '15px')};
    grid-gap: ${({ simple }) => (simple ? 10 : 15)}px;
    grid-template-columns: ${({ columns }) => columns};

    ${({ simple }) =>
      simple &&
      css`
        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      `};
  }

  ${({ simple }) =>
    (simple &&
      css`
        ${GridBody} {
          border: none;
        }
      `) ||
    css`
      ${GridRow} {
        &:nth-child(even) {
          background-color: #f9f9fa;
        }

        &:hover {
          box-shadow: 0 0 3px 2px rgba(154, 189, 234, 0.3);
          z-index: 1;
        }
      }
    `};
`;

Grid.Row = GridRow;
Grid.Head = GridHead;
Grid.Body = GridBody;
Grid.Cell = GridCell;

Grid.propTypes = {
  columns: PropTypes.string.isRequired,
  simple: PropTypes.bool,
};

Grid.Cell.propTypes = {
  vertical: PropTypes.oneOf(ALIGNS),
  horizontal: PropTypes.oneOf(ALIGNS),
};

Row.displayName = 'Row';
Grid.displayName = 'Grid';
Grid.Row.displayName = 'Grid.Row';
Grid.Head.displayName = 'Grid.Head';
Grid.Body.displayName = 'Grid.Body';
Grid.Cell.displayName = 'Grid.Cell';

export default Grid;
