import { handleActions } from 'redux-actions';

import {
  setSize,
  setLabel,
  setTotal,
  setCurrent,
  resetPaginator,
} from './actions.js';

const STANDARD_SIZE = 50;

export const initialPaginatorState = {
  total: 0,
  currentPage: 1,
  size: STANDARD_SIZE,
  dropdownLabel: `${STANDARD_SIZE} / page`,
};

export const paginationReducer = handleActions(
  {
    [setSize]: (state, { payload: size }) => ({
      ...state,
      size,
    }),
    [setLabel]: (state, { payload: dropdownLabel }) => ({
      ...state,
      dropdownLabel,
    }),
    [setTotal]: (state, { payload: total }) => ({
      ...state,
      total,
    }),
    [setCurrent]: (state, { payload: currentPage }) => ({
      ...state,
      currentPage,
    }),
    [resetPaginator]: () => initialPaginatorState,
  },
  initialPaginatorState,
);
