import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setSearchText } from '../redux/searchText.js';

export const useClearTableSearch = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSearchText(''));
  }, []);
};
