import React from 'react';

import { func, bool, string } from 'prop-types';
import styled from 'styled-components';
import { Icon, IconButton, InputWithIcon } from 'poly-book';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  transition: 0.3s;

  input {
    color: #fff;
    border-color: transparent;
  }

  button {
    svg {
      transition: 0.1s;
    }
  }
`;

export function GlobalSearchInput({
  searchValue,
  onChangeSearch,
  onClearSearch,
  onBlur,
  onFocus,
  isSearchFilled,
}) {
  return (
    <Wrapper>
      <InputWithIcon
        name="global-search"
        placeholder="Search"
        icon={
          <Icon
            name="search"
            fill="#c8c8c8"
            key="search-icon"
            dimensions={{ height: 18 }}
          />
        }
        iconAlign="left"
        value={searchValue}
        onChange={onChangeSearch}
        onFocus={onFocus}
        onBlur={!isSearchFilled ? onBlur : undefined}
      />
      <IconButton type="button" onClick={onClearSearch}>
        <Icon
          name="close"
          fill="#fff"
          dimensions={{ width: isSearchFilled ? 10 : 0 }}
        />
      </IconButton>
    </Wrapper>
  );
}

GlobalSearchInput.propTypes = {
  searchValue: string.isRequired,
  onChangeSearch: func.isRequired,
  onClearSearch: func.isRequired,
  onFocus: func.isRequired,
  onBlur: func.isRequired,
  isSearchFilled: bool.isRequired,
};
