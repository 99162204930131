export const tabsNames = {
  projects: 'projects',
  recurring: 'recurring',
  files: 'files',
  updates: 'updates',
  assets: 'assets',
  pms: 'pms',
};

export const tabsTitles = {
  [tabsNames.projects]: 'WOs',
  [tabsNames.recurring]: 'Recurring',
  [tabsNames.files]: 'Files',
  [tabsNames.updates]: 'Updates',
  [tabsNames.assets]: 'Assets',
  [tabsNames.pms]: 'PMs',
};
