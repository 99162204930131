import React from 'react';
import PropTypes from 'prop-types';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

/* eslint-disable @cspell/spellchecker */
const defaultTheme = {
  fonts: {
    heading: `
      'FFMarkWebProNarrowBook',
      'Helvetica Neue Light',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      sans-serif
    `,
    text: `
      'FFMarkWebProNarrowBook',
      'Helvetica Neue Light',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      sans-serif
    `,
    meta: `
      'FFMarkWebProNarrowMedium',
      'Helvetica Neue Light',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      sans-serif
    `,
    code: `
      'Courier New',
      'DejaVu Sans Mono',
      'Bitstream Vera Sans Mono',
      'Monaco',
      monospace
    `,
  },
};

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'FFMarkWebProNarrowBook';
    src: url('/fonts/MarkPro-NarrowBook.eot');
    src: url('/fonts/MarkPro-NarrowBook.eot?#iefix') format('embedded-opentype'),
      url('/fonts/MarkPro-NarrowBook.woff2') format('woff2'),
      url('/fonts/MarkPro-NarrowBook.woff') format('woff'),
      url('/fonts/MarkPro-NarrowBook.ttf') format('truetype');
  }

  @font-face {
    font-family: 'FFMarkWebProNarrowMedium';
    src: url('/fonts/MarkPro-NarrowMedium.eot');
    src: url('/fonts/MarkPro-NarrowMedium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/MarkPro-NarrowMedium.woff') format('woff'),
      url('/fonts/MarkPro-NarrowMedium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'FFMarkWebProNarrowItalic';
    src: url('/fonts/MarkPro-NarrowItalic.eot');
    src: url('/fonts/MarkPro-NarrowItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/MarkPro-NarrowItalic.woff') format('woff'),
      url('/fonts/MarkPro-NarrowItalic.ttf') format('truetype');
  }
  
  *,
  ::before,
  ::after {
    box-sizing: inherit;
  }

  html {
    line-height: 1.15;
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  body {
    font-size: 13px;
    color: #2d2f33; /* $darkest */
    font-family: ${defaultTheme.fonts.text};
    background-color: #fcfcfc;
    cursor: default;
  }

  a {
    color: #4676b4;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  img {
    border-style: none;
  }

  strong {
    font-weight: bolder;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
    font-size: 100%;
    line-height: 1.15;
    font-family: sans-serif;
  }
  
  input,
  textarea {
    border: 1px solid #e3e5ed;
    border-radius: 3px;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: none;
  }

  textarea {
    overflow: auto;
  }

  [type="checkbox"],
  [type="radio"] {
    padding: 0;
    box-sizing: border-box;
  }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  [type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [hidden] {
    display: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
  }

  :-moz-ui-invalid,
  :-moz-ui-invalid:-moz-focusring {
    box-shadow: none;
  }

  :focus {
    outline: none;
  }
`;
/* eslint-enable @cspell/spellchecker */

function PolyThemeProvider({ theme = defaultTheme, children }) {
  return (
    <ThemeProvider {...{ theme }}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
}

PolyThemeProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  theme: PropTypes.object,
  children: PropTypes.node.isRequired,
};

PolyThemeProvider.displayName = 'PolyThemeProvider';

export default PolyThemeProvider;
