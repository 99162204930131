import React from 'react';
import { insertParamIntoURL } from 'poly-utils';
import { Link } from 'poly-client-routing';
import { arrayOf, element, oneOfType, string } from 'prop-types';

import { routes } from '../routes.js';

export function ProjectLink({ projectId, highlightedProjectId, target }) {
  const href = insertParamIntoURL(
    'projectId',
    routes.projectDetails,
    projectId,
  );
  return (
    <Link href={href} target={target}>
      {highlightedProjectId || projectId}
    </Link>
  );
}

ProjectLink.propTypes = {
  projectId: string,
  highlightedProjectId: oneOfType([
    element,
    string,
    arrayOf(oneOfType([string, element])),
  ]),
  target: string,
};
