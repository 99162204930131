import * as R from 'ramda';
import React from 'react';
import { Grid } from 'poly-book';
import { arrayOf, shape, string } from 'prop-types';
import {
  formatTotal,
  convertCentsToDollars,
  calculateTotal,
  getPropertiesDeepByHierarchyNodeR,
} from 'poly-utils';

import {
  spendReportHierarchyPropTypes,
  spendReportTableHeaderPropTypes,
} from './prop-types.js';
import { GridRow, FooterText, TotalInvoiceCell } from './components.js';

// calculateSpendReportTotal :: [PropertySpendReport] -> String
const calculateSpendReportTotal = R.compose(
  formatTotal,
  convertCentsToDollars,
  calculateTotal(R.prop('clientInvoicesTotal')),
  R.flatten,
  R.map(getPropertiesDeepByHierarchyNodeR([])),
);

export function SpendReportTableFooter({ gridColumns, headers, reports }) {
  return (
    <Grid columns={gridColumns}>
      <GridRow>
        <TotalInvoiceCell start={headers.length}>
          <FooterText type="content">Invoices total:</FooterText>
          {calculateSpendReportTotal(reports)}
        </TotalInvoiceCell>
      </GridRow>
    </Grid>
  );
}

SpendReportTableFooter.propTypes = {
  gridColumns: string.isRequired,
  headers: arrayOf(shape(spendReportTableHeaderPropTypes)).isRequired,
  reports: arrayOf(shape(spendReportHierarchyPropTypes)),
};
