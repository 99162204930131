import * as R from 'ramda';
import { number, object, shape, string } from 'prop-types';
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components';
import { insertQueryParamsIntoURL } from 'poly-utils';

import { TotalItem } from '../../../components/TotalItem.js';
import {
  blockedProjectsSearchQuery,
  completedProjectsQuery,
  getSearchQueryWithinDateRange,
} from '../../ProjectsPage/helpers.js';
import { getRangeDates } from '../../../constants/common.js';
import { routes } from '../../../routes.js';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
`;

const propertyProjectsTotalQuery = gql`
  query propertyProjectsTotalQuery(
    $propertyId: ID!
    $searchInput: CollectionSearchParams!
  ) {
    property(id: $propertyId) {
      _id
      searchProjects(input: $searchInput) {
        total
      }
    }
  }
`;

// getProjectsTotal :: Object -> Number
const getProjectsTotal = R.pathOr(0, ['property', 'searchProjects', 'total']);

const usePropertyTotal = (propertyId, searchQuery) => {
  const { data } = useQuery(propertyProjectsTotalQuery, {
    variables: {
      propertyId,
      searchInput: {
        from: 0,
        size: 0,
        query: searchQuery,
      },
    },
  });
  return getProjectsTotal(data);
};

const WOsStartedSearchQuery = getSearchQueryWithinDateRange(
  {
    bool: {
      must_not: [blockedProjectsSearchQuery],
    },
  },
  'startDate',
);

const WOsCompletedSearchQuery = getSearchQueryWithinDateRange(
  {
    bool: {
      must: completedProjectsQuery,
      must_not: blockedProjectsSearchQuery,
    },
  },
  'workCompletionDate',
);

function PropertyWOsTotal({ propertyId, searchQuery, title, icon }) {
  const total = usePropertyTotal(propertyId, searchQuery);
  return (
    <TotalItem
      title={title}
      total={total}
      icon={icon}
      isMoney={false}
      tooltip={{ isVisible: R.F }}
    />
  );
}

PropertyWOsTotal.propTypes = {
  propertyId: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  searchQuery: object.isRequired,
  title: string.isRequired,
  icon: shape({
    name: string.isRequired,
    dimensions: shape({ width: number, height: number }),
  }),
};

// getPropertySpendTotal :: Property -> Number
const getPropertySpendTotal = R.propOr(0, 'spendTotal');

export function PropertyWOsTotalSection({
  propertyId,
  selectedRange,
  property,
}) {
  const url = property
    ? insertQueryParamsIntoURL(
        {
          ...getRangeDates(selectedRange),
          propertyName: property.name,
          propertyId,
        },
        routes.spendReport,
      )
    : null;
  return (
    <Container>
      <TotalItem
        title="Total Spend"
        total={getPropertySpendTotal(property)}
        tooltip={{ isVisible: R.F }}
        url={url}
      />
      <PropertyWOsTotal
        title="WOs Started"
        propertyId={propertyId}
        searchQuery={WOsStartedSearchQuery(selectedRange)}
        icon={{ name: 'opened', dimensions: { width: 20, height: 20 } }}
      />
      <PropertyWOsTotal
        title="WOs Completed"
        propertyId={propertyId}
        searchQuery={WOsCompletedSearchQuery(selectedRange)}
        icon={{ name: 'completed', dimensions: { width: 20, height: 20 } }}
      />
    </Container>
  );
}

PropertyWOsTotalSection.propTypes = {
  propertyId: string.isRequired,
  selectedRange: string.isRequired,
  property: shape({
    name: string.isRequired,
  }),
};
