import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const types = {
  header: css`
    font-size: 14px;
    color: #9dacdc;
  `,
  title: css`
    font-family: ${({ theme }) => theme.fonts.meta};
    font-size: 12px;
    color: #6f7a9f;
    letter-spacing: 1px;
    text-transform: uppercase;
  `,
  content: css`
    font-size: 14px;
    color: #5e6271;
  `,
  badge: css`
    font-family: ${({ theme }) => theme.fonts.meta};
    letter-spacing: 1px;
    font-size: 12px;
    color: #90939d;
  `,
};

const S = styled.span`
  ${({ type }) => types[type] || ''};
  color: ${({ textColor }) => textColor || ''};
  font-size: ${({ size }) => (size && `${size}px`) || ''};
  font-family: ${({ bold, theme }) => bold && theme.fonts.meta};
  text-transform: ${({ uppercase }) => uppercase && 'uppercase'};
  text-decoration: ${({ underline }) => underline && 'underline'};
  text-decoration: ${({ strikeThrough }) => strikeThrough && 'line-through'};
`;

S.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  bold: PropTypes.bool,
  uppercase: PropTypes.bool,
  underline: PropTypes.bool,
  strikeThrough: PropTypes.bool,
};

S.displayName = 'PolyBook_S';

export default S;
