import React, { useState, useEffect } from 'react';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { v4 as uuidV4 } from 'uuid';
import { toast } from 'react-toastify';
import { useMutation, gql } from '@apollo/client';
import { removeFileExtension } from 'poly-utils';

import { EditFileModalComp } from './EditFileModalComp.js';
import { ALERTS } from '../../constants/alerts.js';
import { setFileModal } from '../../redux/fileModal.js';

const updateFile = gql`
  mutation updateFile($id: ID!, $input: UpdateFileInput!) {
    updateFile(id: $id, input: $input) {
      file {
        _id
        fileType
        fileName
        createdAt
        url
      }
    }
  }
`;

export function EditFileModal({ onConfirm, ...props }) {
  const fileModal = useSelector(R.prop('fileModal'));
  const selectedFile = R.pathOr(null, ['data', 'file'], fileModal);

  const dispatch = useDispatch();

  const [fileMutation] = useMutation(updateFile);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const prevFileName = R.prop('name', selectedFile);
  const [fileName, setFileName] = useState(prevFileName);

  useEffect(() => {
    if (prevFileName) {
      setFileName(removeFileExtension(prevFileName));
    }
  }, [prevFileName]);

  const onChange = (e) => {
    const {
      target: { value },
    } = e;

    if (value.length) {
      setError(null);
    } else {
      setError('File name is required');
    }

    return setFileName(value);
  };

  const handleConfirm = async () => {
    if (error) {
      return false;
    }

    if (R.path(['data', 'useMutation'], fileModal)) {
      try {
        setLoading(true);
        await fileMutation({
          variables: {
            id: R.prop('_id', selectedFile),
            input: { fileName },
          },
        });
        setLoading(false);
        dispatch(setFileModal(null));
        return toast.success('File Name Edit Successfully Saved');
      } catch (err) {
        setLoading(false);
        setError({ server: err.message });
        return toast.error(ALERTS.GeneralError);
      }
    }

    dispatch(setFileModal(null));

    return onConfirm({
      fileName,
      upload: selectedFile,
      key: uuidV4(),
    });
  };

  if (R.isNil(fileName)) {
    return null;
  }

  return (
    <EditFileModalComp
      {...props}
      fileName={fileName}
      loading={loading}
      onChange={onChange}
      closeModal={() => dispatch(setFileModal(null))}
      onConfirm={handleConfirm}
      show={R.propEq('type', 'edit', fileModal)}
      errors={{ fileName: error }}
    />
  );
}

EditFileModal.propTypes = {
  onConfirm: func,
};
