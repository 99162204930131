import styled from 'styled-components';
import { S, L } from 'poly-book';

const getTextColor = ({ textColor }) => textColor || '#ff8d02';

export const A = styled(S.withComponent(L)).attrs((props) => ({
  type: 'title',
  textColor: getTextColor(props),
}))`
  &:hover {
    color: ${getTextColor};
  }
`;
