import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import S from '../S/index.js';

const Conversation = styled.section`
  display: flex;
  flex-direction: column;
`;

Conversation.Item = styled.section`
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  ${({ badge }) =>
    badge &&
    css`
      position: relative;
      text-align: center;
      z-index: 1;

      > ${S} {
        padding: 0 30px;
        background: #fff;
      }

      &::before {
        content: '';
        width: 100%;
        height: 1px;
        display: block;
        background-color: #babfd2;
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        z-index: -1;
      }
    `};
`;

Conversation.Item.propTypes = {
  badge: PropTypes.bool,
};

Conversation.displayName = 'Conversation';
Conversation.Item.displayName = 'Conversation.Item';

export default Conversation;
