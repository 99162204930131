import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const ToastContainerS = styled(ToastContainer)`
  .Toastify__toast-container {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .Toastify__toast-container--top-right {
    top: 80px;
  }

  .Toastify__toast {
    font-family: inherit;
    min-height: 50px;
    border-radius: 3px;
    padding: 8px 30px;
    min-width: 100px;
    max-width: 450px;
  }

  .Toastify__toast--success {
    background: #d8f1bc;
    opacity: 0.9;
    border: solid 1px rgba(132, 188, 69, 0.31);

    & > .Toastify__toast-body {
      color: #529801;
    }
  }

  .Toastify__toast--error {
    background: #f6baba;
    opacity: 0.9;
    border: solid 1px rgba(181, 83, 83, 0.31);

    & > .Toastify__toast-body {
      color: #ac0a0a;
    }
  }

  .Toastify__toast-body {
    font-size: 15px;
    font-family: inherit;
    font-weight: 500;
  }

  .Toastify__close-button {
    align-self: center;
    margin-left: 20px;
    font-weight: 100;
    font-weight: 100;
    color: #3e4c5b;
  }
`;

export function Toaster(props) {
  return <ToastContainerS {...props} hideProgressBar newestOnTop />;
}
