import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/index.js';

function Wrapper({ children, size }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.number,
};

function Avatar({ type, content, size }) {
  switch (type) {
    case 'text':
      return (
        <Wrapper {...{ size }}>
          <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="#9dacdc" />
          <text
            x="50"
            y="64"
            fontSize="38"
            textAnchor="middle"
            fill="#fff"
            cursor="default"
          >
            {content}
          </text>
        </Wrapper>
      );

    case 'link':
      return (
        <Wrapper {...{ size }}>
          <g>
            <clipPath id="hexagonal-mask">
              <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" />
            </clipPath>
          </g>
          <image
            clipPath="url(#hexagonal-mask)"
            width="100"
            height="100"
            xlinkHref={content}
          />
        </Wrapper>
      );

    case 'poly':
      return <Icon name={type} fill="#d52147" dimensions={{ width: size }} />;

    default:
      return null;
  }
}

Avatar.propTypes = {
  type: PropTypes.oneOf(['text', 'link', 'poly']).isRequired,
  content: PropTypes.string,
  size: PropTypes.number,
};

Avatar.defaultProps = {
  size: 28,
};

Wrapper.displayName = 'AvatarWrapper';
Avatar.displayName = 'Avatar';

export default Avatar;
