import { string } from 'prop-types';
import React, { useMemo } from 'react';

import { EntityProjectsTab } from '../../components/EntityProjectsTab.js';
import {
  getPMProjectsSearchQuery,
  getWOSearchQuery,
} from '../ProjectsPage/helpers.js';

// getProjectsQueryByPropertyId :: ID -> ElasticQuery
export const getProjectsQueryByPropertyId = (propertyId) => ({
  bool: {
    should: [
      { match: { propertyId } },
      { term: { subPropertiesIds: propertyId } },
    ],
  },
});

export function PropertyProjectsTab({ propertyId }) {
  const projectsQueryByPropertyId = useMemo(
    () => getProjectsQueryByPropertyId(propertyId),
    [propertyId],
  );

  const searchQuery = useMemo(
    () => getWOSearchQuery(projectsQueryByPropertyId),
    [propertyId],
  );

  return <EntityProjectsTab searchQuery={searchQuery} />;
}

PropertyProjectsTab.propTypes = {
  propertyId: string.isRequired,
};

export function PropertyPMTab({ propertyId }) {
  const projectsQueryByPropertyId = useMemo(
    () => getProjectsQueryByPropertyId(propertyId),
    [propertyId],
  );

  const searchQuery = useMemo(
    () => getPMProjectsSearchQuery(projectsQueryByPropertyId),
    [propertyId],
  );

  return <EntityProjectsTab searchQuery={searchQuery} />;
}

PropertyPMTab.propTypes = {
  propertyId: string.isRequired,
};
