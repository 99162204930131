import React from 'react';
import RcPagination from 'rc-pagination';
import enUS from 'rc-pagination/lib/locale/en_US.js';
import styled from 'styled-components';

const RcPaginationS = styled(RcPagination)`
  margin: 0;
  padding: 0;
  list-style: none;

  &:after {
    height: 0;
    display: block;
    content: ' ';
    visibility: hidden;
    overflow: hidden;
    clear: both;
  }

  .Poly-Pagination-item {
    height: 25px;
    min-width: 30px;
    display: inline-block;
    margin-right: 8px;
    line-height: 25px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #fff;
    border: 1px solid #e3e5ed;
    border-radius: 3px;
    outline: 0;
    transition: all 0.3s;
    will-change: transition;
  }

  .Poly-Pagination-item a {
    margin: 0 6px;
    font: inherit;
    font-size: 14px;
    text-decoration: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #6f7a9f;
  }

  .Poly-Pagination-item:focus,
  .Poly-Pagination-item:hover,
  .Poly-Pagination-item-active {
    border-color: #1890ff;
  }

  .Poly-Pagination-item:focus a,
  .Poly-Pagination-item:hover a,
  .Poly-Pagination-item-active a {
    color: #1890ff;
  }

  .Poly-Pagination-item-active:focus a,
  .Poly-Pagination-item-active:hover a {
    color: #40a9ff;
  }

  .Poly-Pagination-jump-prev,
  .Poly-Pagination-jump-next {
    outline: 0;
  }

  .Poly-Pagination-jump-prev:after,
  .Poly-Pagination-jump-next:after {
    display: block;
    content: '•••';
    font-size: 14px;
    text-align: center;
    letter-spacing: 2px;
    color: #6f7a9f;
  }

  .Poly-Pagination-jump-prev:focus:after,
  .Poly-Pagination-jump-next:focus:after,
  .Poly-Pagination-jump-prev:hover:after,
  .Poly-Pagination-jump-next:hover:after {
    color: #1890ff;
  }

  .Poly-Pagination-jump-prev:focus:after,
  .Poly-Pagination-jump-prev:hover:after {
    content: '<<';
  }

  .Poly-Pagination-jump-next:focus:after,
  .Poly-Pagination-jump-next:hover:after {
    content: '>>';
  }

  .Poly-Pagination-prev,
  .Poly-Pagination-jump-prev,
  .Poly-Pagination-jump-next {
    margin-right: 8px;
  }

  .Poly-Pagination-prev,
  .Poly-Pagination-next,
  .Poly-Pagination-jump-prev,
  .Poly-Pagination-jump-next {
    min-width: 30px;
    height: 25px;
    display: inline-block;
    line-height: 25px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #6f7a9f;
    border-radius: 3px;
    transition: all 0.3s;
  }

  .Poly-Pagination-prev,
  .Poly-Pagination-next {
    position: relative;
    background-color: #fff;
    border: 1px solid #e3e5ed;
    border-radius: 3px;
    outline: none;
    transition: all 0.3s;
  }

  .Poly-Pagination-prev:hover,
  .Poly-Pagination-next:hover {
    border-color: #40a9ff;
  }

  .Poly-Pagination-prev .Poly-Pagination-item-link:after,
  .Poly-Pagination-next .Poly-Pagination-item-link:after {
    width: 8px;
    height: 8px;
    display: block;
    content: '';
    position: absolute;
    top: calc(50% - 4px);
    border: 1px solid #6f7a9f;
    transform: rotate(-45deg);
    transition: all 0.3s;
  }

  .Poly-Pagination-prev:focus .Poly-Pagination-item-link:after,
  .Poly-Pagination-next:focus .Poly-Pagination-item-link:after,
  .Poly-Pagination-prev:hover .Poly-Pagination-item-link:after,
  .Poly-Pagination-next:hover .Poly-Pagination-item-link:after {
    border-color: #1890ff;
    color: #1890ff;
  }

  .Poly-Pagination-prev .Poly-Pagination-item-link:after {
    left: calc(50% - 2px);
    border-right: none;
    border-bottom: none;
  }

  .Poly-Pagination-next .Poly-Pagination-item-link:after {
    right: calc(50% - 2px);
    border-top: none;
    border-left: none;
  }

  .Poly-Pagination-disabled,
  .Poly-Pagination-disabled:hover,
  .Poly-Pagination-disabled:focus {
    cursor: not-allowed;
  }

  .Poly-Pagination-disabled a,
  .Poly-Pagination-disabled:hover a,
  .Poly-Pagination-disabled:focus a,
  .Poly-Pagination-disabled:hover,
  .Poly-Pagination-disabled:focus,
  .Poly-Pagination-disabled .Poly-Pagination-item-link,
  .Poly-Pagination-disabled .Poly-Pagination-item-link:after,
  .Poly-Pagination-disabled:hover .Poly-Pagination-item-link,
  .Poly-Pagination-disabled:focus .Poly-Pagination-item-link,
  .Poly-Pagination-disabled:hover .Poly-Pagination-item-link:after,
  .Poly-Pagination-disabled:focus .Poly-Pagination-item-link:after {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }

  .Poly-Pagination-options {
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px;
  }
`;

function PolyPagination(props) {
  return <RcPaginationS locale={enUS} prefixCls="Poly-Pagination" {...props} />;
}

export default PolyPagination;
