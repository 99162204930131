import * as R from 'ramda';
import { array, func, string } from 'prop-types';
import React from 'react';
import { Widget, Grid } from 'poly-book';

export function SearchGrid({ rows, columnConfig, searchTerm, gridColumns }) {
  const columns = R.map(R.nth(0), columnConfig(searchTerm));

  const formats = R.map(
    R.compose(R.defaultTo(R.identity), R.nth(1)),
    columnConfig(searchTerm),
  );

  return (
    <Grid columns={gridColumns} simple>
      {rows.map((row) => (
        <Widget.Item key={row._id}>
          <Grid.Row>
            {columns.map((_, i) => (
              <Grid.Cell key={`${row._id}_${R.inc(i)}`}>
                {R.compose(formats[i], columns[i])({ ...row })}
              </Grid.Cell>
            ))}
          </Grid.Row>
        </Widget.Item>
      ))}
    </Grid>
  );
}

SearchGrid.propTypes = {
  // eslint-disable-next-line
  rows: array.isRequired,
  columnConfig: func.isRequired,
  searchTerm: string.isRequired,
  gridColumns: string.isRequired,
};
