import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import S from '../S/index.js';

const activeStyles = css`
  > svg {
    flex-shrink: 0;

    polygon {
      fill: ${({ fill }) => fill};
    }

    g {
      fill: #fff;
    }

    g,
    polygon {
      transition: fill 0.3s;
    }
  }
`;

const IconButton = styled.button`
  display: inline-flex;
  padding: 3px;
  align-items: center;
  border: none;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;

  ${S} {
    margin-top: 3px;
    margin-right: 12px;
    color: ${({ fill }) => fill};
  }

  ${({ hover }) =>
    hover &&
    css`
      &:hover {
        ${activeStyles};
      }
    `};

  ${({ active }) => active && activeStyles};

  &[disabled] {
    &,
    &:hover {
      cursor: not-allowed;
      user-select: none;
    }
  }
`;

IconButton.propTypes = {
  fill: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  hover: PropTypes.bool,
  active: PropTypes.bool,
};

IconButton.defaultProps = {
  fill: '#3a3d46',
};

IconButton.displayName = 'IconButton';

export default IconButton;
