import React from 'react';
import { string } from 'prop-types';
import { updateDetailsFragment } from 'poly-site-ui';
import { gql } from '@apollo/client';
import { collectionNames } from 'poly-constants';

import { EntityUpdatesTab } from '../../components/EntityUpdatesTab.js';

const propertyUpdatesQuery = gql`
  query getProjectUpdates(
    $propertyId: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    property(id: $propertyId) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...updateFragment
        }
        total
      }
    }
  }
  ${updateDetailsFragment}
`;

export function PropertyUpdatesTab({ propertyId }) {
  return (
    <EntityUpdatesTab
      collection={collectionNames.properties}
      documentId={propertyId}
      entity="property"
      query={propertyUpdatesQuery}
    />
  );
}

PropertyUpdatesTab.propTypes = {
  propertyId: string.isRequired,
};
