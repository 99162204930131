import React from 'react';
import PropTypes, { string } from 'prop-types';
import { Header, Heading, Holder } from 'poly-book';
import { FilterByStatus } from 'poly-site-ui';
import styled from 'styled-components';
import { propertyStatuses } from 'poly-constants';

import { TextSearchComponent } from '../../components/TextSearchComponent.js';
import { usePropertiesQuery } from './usePropertiesQuery.js';

const HeaderBlockRight = styled(Header.Block)`
  align-self: flex-end;
`;

const UpRow = styled(Header.Row)`
  margin-top: -10px;
`;

const FILTERS_CONFIG = [
  {
    title: 'Active',
    status: propertyStatuses.ACTIVE,
    color: '#9bdc53',
    showPip: true,
  },
  {
    title: 'Inactive',
    status: propertyStatuses.INACTIVE,
    color: '#ffbc28',
    showPip: true,
  },
];

function Filter({ status, ...props }) {
  const { total } = usePropertiesQuery(status);
  return <FilterByStatus typeFilter={status} count={total} {...props} />;
}

Filter.propTypes = {
  status: string.isRequired,
};

export function PropertiesPageHeader({ onClick, activeFilter }) {
  return (
    <Header.Sub>
      <Header.Row>
        <Header.Block>
          <Heading.h1 lighter>Properties</Heading.h1>
        </Header.Block>
      </Header.Row>
      <UpRow>
        <Holder margin={10} center>
          {FILTERS_CONFIG.map(({ status, ...props }) => (
            <Filter
              key={status}
              active={activeFilter === status}
              status={status}
              {...{ onClick }}
              {...props}
            />
          ))}
        </Holder>
        <HeaderBlockRight>
          <TextSearchComponent />
        </HeaderBlockRight>
      </UpRow>
    </Header.Sub>
  );
}

PropertiesPageHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeFilter: PropTypes.string.isRequired,
};
