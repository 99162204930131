import { gql } from '@apollo/client';
import { updateDetailsFragment } from './updates.js';

export const addProjectMutation = gql`
  mutation addProject($input: CreateProjectInput) {
    createProject(input: $input) {
      project {
        _id
        projectId
      }
    }
  }
`;

export const projectUpdatesQuery = gql`
  query getProjectUpdates(
    $projectId: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    project(projectId: $projectId) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...updateFragment
        }
        total
      }
    }
  }
  ${updateDetailsFragment}
`;

export const projectAssetsDetailsFragment = gql`
  fragment projectAssetsDetailsFragment on Asset {
    _id
    photo {
      fileName
      url
    }
    description
    equipmentType
    location
    manufacturer
    model
    room
    serial
    startUp
  }
`;

export const projectAssetsQuery = gql`
  query getProjectAssets($id: ID) {
    project(projectId: $id) {
      _id
      property {
        _id
        name
      }
      type
      searchAssets {
        hits {
          ...projectAssetsDetailsFragment
        }
      }
    }
  }

  ${projectAssetsDetailsFragment}
`;
