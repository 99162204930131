import moment from 'moment';
import pluralize from 'pluralize';
import { WorkOrderStatus as WO } from 'poly-constants';
import * as R from 'ramda';
import { forceTitleCase } from 'poly-utils';
import {
  PROJECT_STATUS_COLORS,
  PROJECT_STATUS_COLOR_MAP,
  PROJECT_STATUS_TEXT_MAP,
} from '../constants/projects.js';
import { EntityStatusFormatter } from './formatters.js';

const formatDate = (date) => moment(date).format('YYYYMMDD');

const getTodayDate = () => formatDate(moment());

const getTomorrowDate = () => formatDate(moment().add(1, 'days'));

// formatEndDate :: Project -> String
const formatEndDate = R.compose(formatDate, R.prop('endDate'));

// isEndDateIsToday :: Project -> Boolean
const isEndDateIsToday = R.converge(R.equals, [formatEndDate, getTodayDate]);

// isEndDateIsTomorrow :: Project -> Boolean
const isEndDateIsTomorrow = R.converge(R.equals, [
  formatEndDate,
  getTomorrowDate,
]);

// isEndDateLessToday :: Project -> Boolean
export const isEndDateLessToday = R.converge(R.lt, [
  formatEndDate,
  getTodayDate,
]);

// transformProjectEndDate :: Project -> String
export const transformProjectEndDate = (project) => {
  const now = moment(new Date());
  const { endDate, status } = project;

  if (!endDate || status === WO.COMPLETED) {
    return null;
  }

  if (isEndDateIsToday(project)) {
    return 'Due Today';
  }

  if (isEndDateLessToday(project)) {
    const difference = now.diff(moment(endDate), 'days');
    return `Past Due ${difference} ${pluralize('day', difference)}`;
  }

  if (isEndDateIsTomorrow(project)) {
    return 'Due Tomorrow';
  }

  return `Due in ${moment(endDate).diff(now, 'days')} days`;
};

const QUEUE_STATUS_TEXT = 'QUEUE';

// getProjectStatusColor :: Project -> String
export const getProjectStatusColor = R.cond([
  [
    R.both(
      R.propSatisfies(R.includes(R.__, [WO.ACTIVE, WO.IN_PROCESS]), 'status'),
      isEndDateLessToday,
    ),
    R.always(PROJECT_STATUS_COLORS.pastDue),
  ],
  [R.T, R.compose(R.prop(R.__, PROJECT_STATUS_COLOR_MAP), R.prop('status'))],
]);

// has active status but no technicians
const isQueue = R.both(
  R.propEq('status', WO.ACTIVE),
  R.pipe(
    R.props(['technicians', 'suppliers', 'manager']),
    R.all(R.anyPass([R.isEmpty, R.isNil])),
  ),
);

// getProjectStatusText :: Project -> String
const getProjectStatusText = R.ifElse(
  isQueue,
  R.always(QUEUE_STATUS_TEXT),
  R.compose(
    forceTitleCase,
    R.prop(R.__, PROJECT_STATUS_TEXT_MAP),
    R.prop('status'),
  ),
);

// projectStatusUITransform :: Project -> ReactNode
export const projectStatusUITransform = R.converge(EntityStatusFormatter, [
  getProjectStatusColor,
  getProjectStatusText,
  R.prop('search'),
]);

// getProjectStatusTextNoQueue :: Project -> String
export const getProjectStatusTextNoQueue = R.compose(
  forceTitleCase,
  R.prop(R.__, PROJECT_STATUS_TEXT_MAP),
  R.prop('status'),
);

// projectStatusUITransformNoQueue :: Project -> ReactNode
export const projectStatusUITransformNoQueue = R.converge(
  EntityStatusFormatter,
  [getProjectStatusColor, getProjectStatusTextNoQueue, R.prop('search')],
);

// isDateFilterNil :: Project -> Boolean
export const isDateFilterNil = R.both(
  R.pathSatisfies(R.isNil, ['dateFilter', 'startDate']),
  R.pathSatisfies(R.isNil, ['dateFilter', 'endDate']),
);
