import styled from 'styled-components';
import React, { useState } from 'react';
import { shape, string, arrayOf, func, oneOfType, node } from 'prop-types';

const CommonLinksWrapperS = styled.div`
  gap: 5px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-height: ${({ showMore }) => (showMore ? 'auto' : '57px')};
  overflow: hidden;

  a:not(:last-child) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }

  a:last-child {
    width: calc(100% - 55px);
  }

  a:nth-child(3) {
    width: ${({ showMore }) => (showMore ? '100%' : 'calc(100% - 55px)')};
  }
`;

const ShowMoreButtonS = styled.div`
  bottom: 0;
  right: -10px;
  cursor: pointer;
  color: #4676b4;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
  background-color: #f6f9fd;
`;

export function SubLinksComponent({ subLinks, LinkComponent }) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <CommonLinksWrapperS showMore={showMore}>
        {subLinks.map((entity) => (
          <LinkComponent key={entity._id} {...entity} />
        ))}
      </CommonLinksWrapperS>
      {subLinks.length > 3 ? (
        <ShowMoreButtonS onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Show less' : 'Show more'}
        </ShowMoreButtonS>
      ) : null}
    </>
  );
}

SubLinksComponent.displayName = 'SubLinksComponent';

SubLinksComponent.propTypes = {
  LinkComponent: oneOfType([func, node]).isRequired,
  subLinks: arrayOf(shape({ _id: string.isRequired })).isRequired,
};
