import styled from 'styled-components';

const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: sticky;
  left: 0;
  top: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const HeaderRow = styled.section`
  display: flex;
  padding: 0 30px;
  flex-direction: column;
`;

Header.Main = styled(HeaderRow)`
  background-color: #014398;
  z-index: 4;
`;

Header.Sub = styled(HeaderRow)`
  background-color: #fff;
  z-index: 3;
`;

const HeaderBlock = styled.div`
  display: flex;
`;

Header.Row = styled(HeaderBlock)`
  flex-wrap: wrap;
  justify-content: space-between;
`;

Header.Block = styled(HeaderBlock)`
  min-height: 60px;
  align-items: center;
`;

Header.Item = styled(HeaderBlock)`
  &:not(:last-child) {
    margin-right: 40px;
  }
`;

Header.displayName = 'Header';
Header.Main.displayName = 'Header.Main';
Header.Sub.displayName = 'Header.Sub';
Header.Row.displayName = 'Header.Row';
Header.Block.displayName = 'Header.Block';
Header.Item.displayName = 'Header.Item';

export default Header;
