import styled, { css } from 'styled-components';

import S from '../S/index.js';

const Nav = styled.nav`
  min-height: 60px;
  display: flex;
  flex-wrap: wrap;
`;

const activeColor = css`
  ${S} {
    color: #fff;
  }
`;

Nav.Item = styled.a`
  display: flex;
  align-items: center;
  position: relative;
  line-height: 30px;

  &:not(:last-child) {
    margin-right: 40px;
  }

  &:hover {
    ${activeColor};
  }

  ${({ active }) =>
    active &&
    css`
      ${activeColor};

      &::after {
        width: 100%;
        height: 4px;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #ff8c00;
      }
    `};
`;

Nav.displayName = 'Nav';
Nav.Item.displayName = 'Nav.Item';

export default Nav;
