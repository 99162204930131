import React from 'react';
import { applySpecWithFields } from 'poly-utils';
import * as R from 'ramda';
import { NOTHING_UI_STRING } from 'poly-constants';
import { string } from 'prop-types';
import styled from 'styled-components';
import { setImagesGalleryModal } from 'poly-site-ui';
import { useDispatch } from 'react-redux';

import { AssetStatusColors, AssetStatusUI } from '../../constants/asset.js';
import { DetailsRow, DetailsSection } from '../ProjectsPage/DetailsSection.js';
import { assetPropTypes } from './assetPropTypes.js';
import { getAssetDetails } from './helpers.js';

// getAssetDetailsUI :: Asset -> Object
const getAssetDetailsUI = R.compose(
  applySpecWithFields({
    status: R.compose(
      R.applySpec({
        text: R.prop(R.__, AssetStatusUI),
        color: R.prop(R.__, AssetStatusColors),
      }),
      R.prop('status'),
    ),
  }),
  getAssetDetails,
);

const AssetImage = styled.img`
  width: 120px;
  height: 120px;
`;

function AssetPhoto({ url, fileName }) {
  if (!url) {
    return NOTHING_UI_STRING;
  }
  return <AssetImage src={url} alt={fileName} />;
}

AssetPhoto.propTypes = {
  url: string,
  fileName: string,
};

export const useViewAssetPhoto = (photo) => {
  const dispatch = useDispatch();
  return () => {
    if (photo?.url) {
      dispatch(
        setImagesGalleryModal({
          images: [photo],
          currentIndex: 0,
        }),
      );
    }
  };
};
export function AssetDetailsSection({ asset }) {
  const {
    equipmentType,
    status,
    property,
    description,
    polyAssetCode,
    manufacturer,
    model,
    serial,
    location,
    warrantyExpiration,
    purchasePrice,
    commissioningDate,
    photo,
  } = getAssetDetailsUI(asset);

  const onClickAssetPhoto = useViewAssetPhoto(photo);

  return (
    <DetailsSection title={equipmentType} status={status}>
      <DetailsRow title="Property" content={property} />
      <DetailsRow title="Description" content={description} />
      <DetailsRow title="Poly Asset Code" content={polyAssetCode} />
      <DetailsRow title="Manufacturer" content={manufacturer} />
      <DetailsRow title="Model" content={model} />
      <DetailsRow title="Serial Number" content={serial} />
      <DetailsRow title="Asset Type" content={equipmentType} />
      <DetailsRow title="Location" content={location} />
      <DetailsRow title="Warranty Expiration" content={warrantyExpiration} />
      <DetailsRow title="Purchase Price" content={purchasePrice} />
      <DetailsRow title="Commissioning Date" content={commissioningDate} />
      <DetailsRow
        title="Asset Photo"
        isVertical
        content={<AssetPhoto url={photo.url} />}
        onClick={onClickAssetPhoto}
      />
    </DetailsSection>
  );
}

AssetDetailsSection.propTypes = {
  asset: assetPropTypes,
};
