import React, { useState } from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { convertCentsToDollars, formatTotal } from 'poly-utils';
import { string, number, bool, oneOfType, func, shape } from 'prop-types';
import { useNavigate } from 'poly-client-routing';

import { Icon } from './Icon.js';

const TotalItemContainer = styled.div`
  height: 136px;
  background: #f6f9fd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  border-radius: 4px;
  position: relative;
  cursor: ${({ isClickable }) => isClickable && 'pointer'};
`;

const TitleText = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #5e6271;
`;

const TotalWithPercentsContainer = styled.div`
  display: flex;
  margin-top: 12px;
`;

const GrowthRatePercentsContainer = styled.div`
  display: flex;
  height: 40px;
  background: #fff;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  margin-right: 8px;
`;

const PercentsText = styled.span`
  color: #1a1514;
  margin-left: 10px;
  font-weight: 500;
`;

const TotalContainer = styled.span`
  color: #1a1514;
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
`;

const TooltipHeaderText = styled.span`
  font-size: 14px;
  line-height: 18px;
`;

const TooltipMessageText = styled.span`
  font-size: 10px;
  line-height: 12px;
`;

const InfoTooltipContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 8px;
  background-color: #12347a;
  padding: 10px 15px;
  left: ${({ alignTooltipLeft }) => (alignTooltipLeft ? -200 : 40)}px;
  color: #fff;
  width: 185px;
  height: 60px;
  align-items: flex-start;
  padding: 12px 20px;
  border-radius: 5px;
  z-index: 2;

  :after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    transform-origin: 0 0;
    box-sizing: border-box;
    border: 15px solid #12347a;
    top: 30px;
    width: 8px;
    transform: rotate(-45deg);
    margin: 0;
    border-width: 8px;
    ${({ alignTooltipLeft }) =>
      alignTooltipLeft ? 'right: -5px' : 'left: -9px'}
  }
`;

const InfoTooltipContainer = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoIconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
`;

function InfoTooltip({ text, alignTooltipLeft }) {
  const [isVisible, setIsVisible] = useState(false);
  const onMouseEnter = () => setIsVisible(true);
  const onMouseLeave = () => setIsVisible(false);

  return (
    <InfoTooltipContainer
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Icon name="question" />
      {isVisible && (
        <InfoTooltipContentContainer alignTooltipLeft={alignTooltipLeft}>
          <TooltipHeaderText>Don&#39;t worry</TooltipHeaderText>
          <TooltipMessageText>{text}</TooltipMessageText>
        </InfoTooltipContentContainer>
      )}
    </InfoTooltipContainer>
  );
}

InfoTooltip.propTypes = {
  alignTooltipLeft: bool,
  text: string.isRequired,
};

// getGrowthRateStyles :: Number -> Object
const getGrowthRateStyles = (growthRate, icon) => {
  if (growthRate === 0) {
    return { name: 'equal', dimensions: { width: 15, height: 5 } };
  }
  if (growthRate < 0) {
    return { name: 'arrow-down', dimensions: { width: 12, height: 18 } };
  }
  if (growthRate > 0) {
    return { name: 'arrow-up', dimensions: { width: 12, height: 18 } };
  }
  return icon;
};

function GrowthRatePercents({ growthRate, icon }) {
  const { name, dimensions } = getGrowthRateStyles(growthRate, icon);
  return (
    <GrowthRatePercentsContainer>
      <Icon name={name} dimensions={dimensions} fill="#014398" />
      {Number.isInteger(growthRate) && (
        <PercentsText>{growthRate}%</PercentsText>
      )}
    </GrowthRatePercentsContainer>
  );
}

export const iconPropTypes = shape({
  name: string,
  dimensions: shape({ width: number, height: number }),
});

GrowthRatePercents.propTypes = {
  growthRate: number,
  icon: iconPropTypes,
};

GrowthRatePercents.defaultProps = {
  icon: { name: 'cash', dimensions: { width: 20, height: 15 } },
};

// formatTotalWithConverting :: Number -> String
const formatTotalWithConverting = R.compose(formatTotal, convertCentsToDollars);

export function TotalItem({
  title,
  total,
  growthRate,
  alignTooltipLeft,
  icon,
  isMoney,
  tooltip,
  url,
}) {
  const navigate = useNavigate();
  const onClick = url ? () => navigate(url) : () => {};
  return (
    <TotalItemContainer onClick={onClick} isClickable={!!url}>
      <InfoIconContainer>
        {tooltip.isVisible(total) && (
          <InfoTooltip
            alignTooltipLeft={alignTooltipLeft}
            text={tooltip.text}
          />
        )}
      </InfoIconContainer>
      <TitleText>{title}</TitleText>
      <TotalWithPercentsContainer>
        <GrowthRatePercents growthRate={growthRate} icon={icon} />
        <TotalContainer>
          {isMoney ? formatTotalWithConverting(total) : total}
        </TotalContainer>
      </TotalWithPercentsContainer>
    </TotalItemContainer>
  );
}

TotalItem.propTypes = {
  title: string.isRequired,
  total: oneOfType([string, number]),
  growthRate: number,
  alignTooltipLeft: bool,
  icon: iconPropTypes,
  isMoney: bool,
  url: string,
  tooltip: shape({
    text: string,
    isVisible: func,
  }),
};

TotalItem.defaultProps = {
  alignTooltipLeft: false,
  isMoney: true,
  tooltip: {
    text: 'All data will appear next month',
    isVisible: (total) => total === 0,
  },
};
