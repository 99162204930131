export const extractGQLOptionsFromProps = ({
  size = 50,
  from = 0,
  query = null,
  sort = {},
  searchTerm = '',
  additionalVars = {},
  queryOptions = {},
}) => ({
  variables: {
    ...additionalVars,
    input: { from, size, query, sort, searchTerm },
  },
  ...queryOptions,
  fetchPolicy: 'network-only',
});
