import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { wordBreakFallback } from '../utils/typography.js';

const styles = css`
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const before = css`
  &::before {
    content: '';
    margin-right: 10px;
    transition: box-shadow 0.3s;
    ${styles};
  }
`;

const Pip = styled.span`
  flex-shrink: 0;
  ${wordBreakFallback};
  ${({ children }) => (children ? before : styles)};
`;

Pip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Pip.defaultProps = {
  color: '#9fa2ab',
};

Pip.displayName = 'Pip';

export default Pip;
