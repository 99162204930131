import { arrayOf, number, shape, string } from 'prop-types';

export const spendReportTableHeaderPropTypes = {
  title: string.isRequired,
  type: string.isRequired,
};

export const spendReportPropertiesPropTypes = {
  clientInvoicesTotal: number,
  invoicesTotal: number,
  propertyName: string,
  propertyId: string,
  propertyInvoicesReport: arrayOf(
    shape({
      projectNumber: string,
      clientInvoicesAmount: number,
      invoices: arrayOf(
        shape({
          file: string,
          invoiceNumber: string,
          date: string,
          supplier: string,
          serviceType: string,
          total: number,
        }),
      ),
    }),
  ),
};

export const spendReportHierarchyPropTypes = {
  _id: string,
  name: string,
  properties: arrayOf(shape(spendReportPropertiesPropTypes)),
};
spendReportHierarchyPropTypes.children = arrayOf(
  shape(spendReportHierarchyPropTypes),
);
