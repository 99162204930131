import styled from 'styled-components';

const WIDTH = 12;
const HEIGHT = 6;

const getDegree = ({ open }) => {
  const degrees = [45, 135];

  if (open) {
    degrees.reverse();
  }

  return `${degrees[0]}deg`;
};

const Arrow = styled.span`
  width: ${WIDTH}px;
  height: ${HEIGHT}px;
  display: inline-block;
  position: relative;

  &::after,
  &::before {
    width: 1px;
    height: 8px;
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 4px);
    pointer-events: none;
    will-change: transform, transition;
    transition: all 0.3s;
    background-color: ${({ bgColor }) => bgColor || '#2d2f33'};
  }

  &::before {
    right: 8px;
    transform: rotate(-${getDegree});
  }

  &::after {
    right: 3px;
    transform: rotate(${getDegree});
  }
`;

Arrow.WIDTH = WIDTH;

Arrow.HEIGHT = HEIGHT;

Arrow.displayName = 'Arrow';

export default Arrow;
