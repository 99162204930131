import React, { useEffect } from 'react';
import * as R from 'ramda';
import {
  projectUpdatesQuery,
  updatesSubscription,
  UpdatesTabWithAddUpdate,
  usePaginatorState,
} from 'poly-site-ui';
import { useReactiveQuery } from 'poly-client-utils';
import { collectionNames, UpdateTypes } from 'poly-constants';
import { string } from 'prop-types';
import { ProjectAssetsTab } from './ProjectAssetsTab/ProjectAssetsTab.js';
import { tabsNames } from '../../constants/tabs.js';

export const projectTabs = [
  { title: 'Updates', value: tabsNames.updates },
  { title: 'Assets', value: tabsNames.assets },
];

const useProjectUpdates = (projectId) => {
  const { paginator } = usePaginatorState();
  const { size, currentPage } = paginator;
  const { data, loading, debouncedRefetch } = useReactiveQuery(
    projectUpdatesQuery,
    updatesSubscription,
    {
      queryOptions: {
        variables: {
          documentUpdatesInput: {
            from: (currentPage - 1) * size,
            size,
          },
          projectId,
        },
        skip: !projectId,
      },
      subscriptionOptions: {
        variables: { input: { documentId: projectId } },
        skip: !projectId,
      },
    },
  );
  return {
    updates: R.pathOr([], ['project', 'documentUpdates', 'hits'], data),
    total: R.pathOr(0, ['project', 'documentUpdates', 'total'], data),
    debouncedRefetch,
    loading,
  };
};

function ProjectUpdatesTab({ projectId, projectDocumentId }) {
  const { updates, total, debouncedRefetch } = useProjectUpdates(projectId);

  useEffect(() => {
    if (projectId) {
      debouncedRefetch();
    }
  }, []);

  return (
    <UpdatesTabWithAddUpdate
      collection={collectionNames.projects}
      type={UpdateTypes.CLIENT_UPDATE}
      documentId={projectDocumentId}
      updateTitle="Client Comment"
      refetch={debouncedRefetch}
      onUserClick={() => {}}
      updates={updates}
      updatesWithTitle
      total={total}
    />
  );
}

ProjectUpdatesTab.propTypes = {
  projectId: string,
  projectDocumentId: string,
};

export const activeProjectsTabsMap = {
  [tabsNames.updates]: ProjectUpdatesTab,
  [tabsNames.assets]: ProjectAssetsTab,
};
