/* eslint-disable react/forbid-prop-types */
import React from 'react';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { Grid, S } from 'poly-book';
import {
  func,
  object,
  string,
  arrayOf,
  shape,
  oneOfType,
  element,
} from 'prop-types';
import {
  generateFileNameByTitle,
  openPrintWindowWithData,
} from 'poly-client-utils';
import { mapIndexed } from 'poly-utils';

import { ALERTS } from '../constants/alerts.js';
import { useSaveDataForTableExport } from '../hooks/useSaveDataForTableExport.js';

export function withSaveDataForTableExport(Component) {
  return function NewComponent(props) {
    const allProps = useSaveDataForTableExport(props);

    NewComponent.displayName = 'TableWithPrintAndExport';

    return <Component {...allProps} />;
  };
}

// prepareHeaders :: [String] -> [{header: String, index: Number}]
const prepareHeaders = mapIndexed((header, index) => ({
  header,
  index,
}));

export function TableComponent({
  headers,
  rows,
  columns,
  formats,
  gridColumns,
  props,
  headerStyle,
}) {
  const preparedHeaders = prepareHeaders(headers);

  return (
    <Grid columns={gridColumns}>
      <Grid.Head style={headerStyle}>
        {preparedHeaders.map(({ header, index }) => (
          <Grid.Cell
            key={`${header}_${index}`}
            {...R.propOr({}, 'align')(props[index])}
          >
            <S type="title" textColor="#90939d">
              {header}
            </S>
          </Grid.Cell>
        ))}
      </Grid.Head>
      <Grid.Body>
        {rows.map((row) => (
          <Grid.Row key={row._id}>
            {columns.map((_, i) => (
              <Grid.Cell
                key={`${row._id}_${headers[i] || i}`}
                {...R.propOr({}, 'align')(props[i])}
              >
                {R.compose(formats[i], columns[i])({ ...row })}
              </Grid.Cell>
            ))}
          </Grid.Row>
        ))}
      </Grid.Body>
    </Grid>
  );
}

TableComponent.propTypes = {
  headers: arrayOf(oneOfType([string, element]).isRequired).isRequired,
  rows: arrayOf(
    shape({
      _id: string.isRequired,
    }).isRequired,
  ).isRequired,
  columns: arrayOf(func.isRequired).isRequired,
  formats: arrayOf(func.isRequired).isRequired,
  props: arrayOf(object),
  gridColumns: string.isRequired,
  headerStyle: shape({ paddingTop: string }),
};

TableComponent.defaultProps = {
  props: [],
};

/**
 * printVisibleTable :: Params -> Object -> Promise _ _
 *
 * Params = {
 *   title: String
 *   PrintLayout: Function
 * }
 */
export const printVisibleTable =
  ({ title, PrintLayout }) =>
  (exportTableProps) => {
    if (R.isEmpty(exportTableProps)) {
      toast.error(ALERTS.NoDataToDisplay);
      return Promise.resolve(null);
    }
    return openPrintWindowWithData({
      ...exportTableProps,
      Layout: PrintLayout,
      metaData: { title },
      Table: TableComponent,
      fileName: generateFileNameByTitle(title),
    });
  };
