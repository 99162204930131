import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import PropTypes, { bool, func } from 'prop-types';
import { Avatar, Conversation, Message, S, Username } from 'poly-book';
import { isNilOrEmpty, removeFileExtension } from 'poly-utils';
import {
  UpdateSentEmailsPreview,
  getFirstLetterOfNames,
  isEmailFile,
} from 'poly-client-utils';
import { SYSTEM_USER_NAME } from 'poly-constants';

import { urlify } from '../../utils/formatters.js';
import { UpdateCommentsSection } from './UpdateCommentsSection.js';
import {
  MessageHeader,
  Content,
  DateTime,
  ImagesRow,
  ImageWrapper,
  AttachmentsWrapper,
} from './components.js';
import {
  formatDateWithSlashes,
  checkAvatarType,
  getKeyFromURL,
  getAttachmentIcon,
  filterImages,
  getUpdateAttachments,
} from './utils.js';

const UpdateSentEmailsPreviewS = styled(UpdateSentEmailsPreview)`
  margin-left: 40px;
  font-size: 14px;
  color: #5e6271;
  font-family: ${R.path(['theme', 'fonts', 'meta'])};
`;

function UpdateItem({
  user,
  title,
  message,
  createdAt,
  attachments,
  onUserClick,
  onImageClick,
  updatesWithTitle,
  updateComments,
}) {
  const { date, time } = formatDateWithSlashes(createdAt);
  const images = filterImages(attachments);
  const emailFiles = R.filter(isEmailFile, attachments);
  const imagesAndEmails = R.concat(images, emailFiles);
  const restAttachments = R.without(imagesAndEmails, attachments);
  const showCommentsSection = !isNilOrEmpty(updateComments);

  return (
    <Conversation.Item>
      <Message>
        <MessageHeader>
          <Username showLink onClick={() => onUserClick(user)} {...user}>
            <Avatar
              type={checkAvatarType(user.fullName)}
              content={getFirstLetterOfNames(user.firstName, user.lastName)}
              size={24}
            />
          </Username>
          <DateTime
            type="badge"
            textColor="#5e6271"
            size={14}
            title={`${date} ${time}`}
          >
            {`${date}   ${time}`}
          </DateTime>
        </MessageHeader>
        {updatesWithTitle && (
          <Message.Content>
            <Content type="content" bold>
              {title}
            </Content>
          </Message.Content>
        )}
        <Message.Content>
          <Content type="content" bold>
            {urlify(message || '')}
          </Content>
        </Message.Content>
      </Message>
      <AttachmentsWrapper>
        <ImagesRow>
          {R.complement(R.isEmpty)(images) &&
            images.map(({ url, fileName }, index) => (
              <ImageWrapper
                key={getKeyFromURL(url)}
                onClick={() => onImageClick(images, index)}
              >
                <img src={url} alt={fileName} />
              </ImageWrapper>
            ))}
        </ImagesRow>
        {R.complement(R.isEmpty)(restAttachments) &&
          restAttachments.map(({ url, fileName, fileType }) => (
            <div key={getKeyFromURL(url)}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {getAttachmentIcon(fileType)} {removeFileExtension(fileName)}
              </a>
            </div>
          ))}
      </AttachmentsWrapper>
      <UpdateSentEmailsPreviewS emailFiles={emailFiles} />
      {showCommentsSection && (
        <UpdateCommentsSection updateComments={updateComments} />
      )}
    </Conversation.Item>
  );
}

UpdateItem.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    fullName: PropTypes.string,
    _id: PropTypes.string.isRequired,
  }),
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      fileType: PropTypes.string.isRequired,
    }),
  ),
  updateComments: PropTypes.arrayOf(
    PropTypes.shape({ _id: PropTypes.string.isRequired }),
  ),
  onUserClick: PropTypes.func,
  onImageClick: PropTypes.func,
  updatesWithTitle: PropTypes.bool,
  title: PropTypes.string,
};

function DateLine({ title }) {
  return (
    <Conversation.Item badge>
      <S type="badge" uppercase>
        {title}
      </S>
    </Conversation.Item>
  );
}

DateLine.propTypes = {
  title: PropTypes.string.isRequired,
};

// getUserByUpdate :: {createdBy: User} -> User
const getUserByUpdate = R.compose(
  R.converge(R.mergeLeft, [
    R.identity,
    R.applySpec({
      _id: R.propOr('0', '_id'),
      name: R.propOr(SYSTEM_USER_NAME, 'fullName'),
      firstName: R.propOr(SYSTEM_USER_NAME, 'firstName'),
      lastName: R.propOr('', 'lastName'),
    }),
  ]),
  R.propOr({}, 'createdBy'),
);

export function UpdateSection({
  title,
  onUserClick,
  onImageClick,
  updates,
  updatesWithTitle,
}) {
  return (
    <>
      <DateLine {...{ title }} />
      {updates.map((update) => (
        <UpdateItem
          updatesWithTitle={updatesWithTitle}
          key={update._id}
          message={update.message}
          title={update.title}
          createdAt={update.createdAt}
          updateComments={update.updateComments}
          user={getUserByUpdate(update)}
          attachments={getUpdateAttachments(update)}
          onImageClick={onImageClick}
          onUserClick={onUserClick}
        />
      ))}
    </>
  );
}

UpdateSection.propTypes = {
  title: PropTypes.string.isRequired,
  updates: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      attachments: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    }),
  ).isRequired,
  onImageClick: func.isRequired,
  onUserClick: func.isRequired,
  updatesWithTitle: bool,
};
