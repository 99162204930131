import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { arrayOf, bool, func, object, oneOfType, string } from 'prop-types';

import { setFileModal } from '../../redux/fileModal.js';
import { useEntityFiles } from './useEntityFiles.js';
import { Loader } from '../Loader.js';
import { mapConfigTableProps } from '../../utils/table.js';
import { FilesTabComp } from './FilesTabComp.js';

const tableConfigPropType = arrayOf(arrayOf(oneOfType([string, func, object])));

export function FilesTab({
  id,
  fileColumns,
  collection,
  documentId,
  searchText,
  ...props
}) {
  const { files, loading, debouncedRefetch } = useEntityFiles({
    collection,
    documentId,
  });

  useEffect(() => {
    debouncedRefetch();
  }, []);

  const dispatch = useDispatch();

  const openAddFileModal = () =>
    dispatch(
      setFileModal({
        type: 'add',
        data: { documentId, collection },
      }),
    );

  if (loading || !documentId) {
    return <Loader />;
  }

  const filesWithSearchText = files.map((file) => ({ ...file, searchText }));
  const tableProps = mapConfigTableProps(filesWithSearchText, fileColumns);

  return (
    <FilesTabComp
      {...props}
      files={tableProps}
      openAddFileModal={openAddFileModal}
      collection={collection}
      documentId={documentId}
      id={id}
    />
  );
}

FilesTab.propTypes = {
  id: string,
  withAddNewBtn: bool,
  fileColumns: tableConfigPropType,
  collection: string,
  documentId: string,
  searchText: string,
};
