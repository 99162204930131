import React from 'react';
import * as R from 'ramda';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Modal from '../Modal/index.js';
import IconButton from '../IconButton/index.js';
import Icon from '../Icon/index.js';

const ModalS = styled(Modal)`
  background-color: rgba(0, 0, 0, 0.7);

  > section {
    padding: 0;
    border: none;
    box-shadow: none;
    width: calc(100% - 100px);
    background-color: #777777;
  }
`;

const SliderS = styled(Slider)`
  .slick-arrow {
    z-index: 1;
    width: 50px;
    height: 50px;

    :before {
      font-size: 40px;
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-slide {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  img {
    max-width: 100%;
    width: auto !important;
  }
`;

const IconButtonS = styled(IconButton)`
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 30px;
  background-color: #ffffff;
  opacity: 0.5;
  z-index: 2;

  :hover {
    opacity: 0.8;
  }
`;

function Gallery({ onOutsideClick, settings, images }) {
  return (
    <ModalS
      show
      ModalBtn={
        <IconButtonS onClick={onOutsideClick}>
          <Icon name="close" dimensions={{ width: 25 }} fill="#777777" />
        </IconButtonS>
      }
    >
      <SliderS {...settings}>
        {R.map(
          ({ fileName, url }) => (
            <img
              src={url}
              alt={fileName}
              key={R.pipe(R.split('/'), R.last)(url)}
            />
          ),
          images,
        )}
      </SliderS>
    </ModalS>
  );
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  settings: PropTypes.shape({
    speed: PropTypes.number,
    infinite: PropTypes.bool,
    slidesToShow: PropTypes.number,
    slidesToScroll: PropTypes.number,
  }),
  onOutsideClick: PropTypes.func.isRequired,
};

Gallery.defaultProps = {
  images: [],
  settings: {},
};

export default Gallery;
