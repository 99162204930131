import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { EXPORT_XLS_CAPTION } from 'poly-constants';
import { A } from './A.js';
import { SmallLoader } from './Loader.js';

import { useLoading } from '../hooks/useLoading.js';

const Loader = styled(SmallLoader)`
  position: absolute;
  background: #ffffff;
  width: 100%;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  position: relative;
  a {
    vertical-align: top;
  }
`;

export function ButtonWithLoading(props) {
  const { asyncAction, title, textColor } = props;

  const { performAsyncAction, isLoading } = useLoading(asyncAction);

  return (
    <ButtonWrapper>
      {isLoading && <Loader />}
      <A textColor={textColor} onClick={performAsyncAction}>
        {title}
      </A>
    </ButtonWrapper>
  );
}

ButtonWithLoading.propTypes = {
  asyncAction: PropTypes.func.isRequired,
  title: PropTypes.string,
  textColor: PropTypes.string,
};
ButtonWithLoading.defaultProps = {
  title: EXPORT_XLS_CAPTION,
};
