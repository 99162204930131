import PropTypes from 'prop-types';
import styled from 'styled-components';

import S from '../S/index.js';

const Holder = styled.section`
  display: flex;
  ${({ center }) => center && 'align-items: center'};
  ${({ width }) => !!width && `min-width: ${width}`}

  > {
    ${S} {
      display: inline-flex;
      margin-top: 3px;
    }

    *:not(:last-child) {
      margin-right: ${({ margin }) => margin}px;
    }
  }
`;

Holder.propType = {
  center: PropTypes.bool,
  margin: PropTypes.number,
};

Holder.defaultProps = {
  margin: 10,
};

Holder.displayName = 'Holder';

export default Holder;
