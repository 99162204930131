import { gql } from '@apollo/client';

export const clientPortalGlobalSearchQuery = gql`
  query CLIENT_PORTAL_GLOBAL_SEARCH($input: CollectionSearchParams!) {
    searchProjects(input: $input) {
      maxScore
      hits {
        _id
        projectId
        status
        type
        endDate
        description
        client {
          _id
          name
        }
        property {
          _id
          name
        }
        technicians {
          _id
        }
      }
    }

    searchProperties(input: $input) {
      maxScore
      hits {
        _id
        status
        name
        address {
          formatted_address
        }
        client {
          _id
          name
        }
      }
    }
  }
`;
