import styled, { css } from 'styled-components';

import L from '../L/index.js';
import Widget from '../Widget/index.js';

const Tabs = styled.div``;

const TabsHeader = styled.header`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1px;
  background-color: transparent;
`;

Tabs.Header = styled(TabsHeader)`
  margin-bottom: -1px;
`;

Tabs.Items = styled(TabsHeader)`
  padding: 0 20px;
  border-bottom: 1px solid #e3e5ed;
`;

const activeColor = css`
  color: #014398;
`;

const activeItemStyles = css`
  ${activeColor};

  &::after {
    width: 100%;
    height: 2px;
    display: block;
    content: '';
    position: absolute;
    left: 0;
    bottom: -1px;
    background-color: currentColor;
  }
`;

const activeTabStyles = css`
  ${activeColor};
  background-color: #fff;
  border-color: #e3e5ed;
  border-top-color: currentColor;
`;

const Tab = styled(L)`
  display: inline-flex;
  padding-top: 15px;
  padding-bottom: 12px;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  color: #2d2f33;

  &:hover {
    ${activeColor};
  }
`;

Tabs.Item = styled(Tab)`
  &:not(:last-child) {
    margin-right: 30px;
  }

  ${({ active }) => active && activeItemStyles};
`;

Tabs.Tab = styled(Tab)`
  margin-left: -1px;
  padding-left: 15px;
  padding-right: 15px;
  border-top: 1px solid #e3e5ed;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;

  &:first-child {
    margin-left: 0;
    border-left-color: #e3e5ed;
  }

  &:last-child {
    border-right-color: #e3e5ed;
  }

  &::after {
    width: 1px;
    height: 70%;
    display: block;
    content: '';
    position: absolute;
    top: 15%;
    right: -1px;
    background-color: #e3e5ed;
  }

  &:hover {
    z-index: 1;
  }

  ${({ active }) => active && activeTabStyles};
`;

Tabs.Content = styled(Widget)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

Tabs.displayName = 'Tabs';
Tabs.Header.displayName = 'Tabs.Header';
Tabs.Items.displayName = 'Tabs.Items';
Tabs.Item.displayName = 'Tabs.Item';
Tabs.Tab.displayName = 'Tabs.Tab';
Tabs.Content.displayName = 'Tabs.Content';

export default Tabs;
