import styled, { css } from 'styled-components';

import Widget from '../Widget/index.js';
import Arrow from '../Arrow/index.js';

const Dropdown = styled.div`
  width: 100%;
  min-width: 150px;
  position: relative;
  background-color: transparent;
`;

Dropdown.Items = styled(Widget.withComponent('ul'))`
  width: 100%;
  max-height: 322px; // visible 10 items
  margin: 0;
  padding: 10px 0;
  display: none;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  overflow-y: auto;
  box-shadow: 0 0 7px 0 rgba(205, 192, 192, 0.5);
  z-index: 3;

  &:not(:empty) {
    display: ${({ open }) => open && 'flex'};
  }
`;

Dropdown.Pip = styled(Arrow)`
  position: absolute;
  top: calc(50% - ${Arrow.HEIGHT / 2}px);
  right: ${({ transparent }) => (!transparent ? '20px' : 0)};
`;

const DropDownItem = css`
  cursor: pointer;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

Dropdown.Item = styled.li`
  flex-shrink: 0;
  ${DropDownItem};
  padding: 7px 20px;
  list-style: none;
  background-color: #fff;

  &:hover {
    background-color: #f0f0f0;
  }
`;

Dropdown.Header = styled(Widget)`
  ${DropDownItem};
  min-height: 40px;
  padding: 10px 40px 10px 20px;
  position: relative;
  background-color: transparent;

  ${({ transparent }) =>
    transparent &&
    css`
      padding-left: 0;
      padding-right: 20px;
      border-color: transparent;
    `};

  &[disabled],
  &[disabled]:hover {
    user-select: none;
    cursor: not-allowed;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:hover {
    background-color: transparent;
  }
`;

Dropdown.displayName = 'Dropdown';
Dropdown.Items.displayName = 'Dropdown.Items';
Dropdown.Pip.displayName = 'Dropdown.Pip';
Dropdown.Item.displayName = 'Dropdown.Item';
Dropdown.Header.displayName = 'Dropdown.Header';

export default Dropdown;
