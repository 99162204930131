import PropTypes from 'prop-types';
import styled from 'styled-components';

const L = styled.a`
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  font-family: ${({ bold, theme }) => bold && theme.fonts.meta};
  color: ${({ textColor }) => textColor || '#4676b4'};

  &:hover {
    color: #014398;
  }
`;

L.propTypes = {
  bold: PropTypes.bool,
};

L.displayName = 'PolyBook_L';

export default L;
