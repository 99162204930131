import React from 'react';
import { PolyThemeProvider } from 'poly-book';
import { node } from 'prop-types';
import { ExportTableProvider } from 'poly-site-ui';
import { createGlobalStyle } from 'styled-components';

import { StoreProvider } from './StoreProvider.js';
import { ApolloClientProvider } from './ApolloClientProvider.js';

/* eslint-disable @cspell/spellchecker */
const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'TTNormsMedium';
    src: url('/fonts/tt_norms_pro_medium-webfont.woff2') format('woff2'),
    url('/fonts/tt_norms_pro_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  body {
    font-family: 'TTNormsMedium';
    font-style: normal;
    font-weight: 400;
    background-color: #fff;
  }
`;

function ThemeProvider({ children }) {
  return (
    <PolyThemeProvider>
      {children}
      <GlobalStyle />
    </PolyThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: node.isRequired,
};

export function AppProvider({ children }) {
  return (
    <ApolloClientProvider>
      <StoreProvider>
        <ExportTableProvider>
          <ThemeProvider>{children}</ThemeProvider>
        </ExportTableProvider>
      </StoreProvider>
    </ApolloClientProvider>
  );
}

AppProvider.propTypes = {
  children: node.isRequired,
};
