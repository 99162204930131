/* eslint-disable import/no-unused-modules */
import {
  ApolloClient,
  InMemoryCache,
  split as splitLinks,
  from as combineLinks,
} from '@apollo/client';
import * as R from 'ramda';
import { createClient } from 'graphql-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities/index.js';
import { BatchHttpLink } from '@apollo/client/link/batch-http/index.js';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions/index.js';
import { setContext } from '@apollo/client/link/context/index.js';
import {
  graphqlWSSubscribeRoute,
  graphqlPublicRoute,
  waitForTimeoutP,
} from 'poly-utils';

import { authMiddleWare, appMaintenanceMiddleware } from './middlewares.js';
import { authAfterWare, errorAfterWare } from './afterwares.js';
import {
  shouldRetryWSLinkSubscription,
  setLastClosedConnectionCode,
} from './utils.js';
import {
  accountTransactionsPagination,
  fromSizeHitsPagination,
} from './pagination.js';

const { NODE_ENV, GRAPHQL_HTTP_URL = '', GRAPH_WS_URL = '' } = process.env;

const GRAPHQL_PUBLIC_URL = graphqlPublicRoute(GRAPHQL_HTTP_URL);

const GRAPHQL_SUBSCRIPTION_URL = graphqlWSSubscribeRoute(GRAPH_WS_URL);

const OFFLINE_RECONNECT_RETRY_TIME = 60 * 1000;

const apolloCache = new InMemoryCache({
  typePolicies: {
    User: {
      fields: {
        profile: {
          merge: false,
        },
        settings: {
          merge: false,
        },
        accessItems: {
          merge: false,
        },
      },
    },
    Supplier: {
      fields: {
        company: {
          merge: false,
        },
        tax: {
          merge: false,
        },
      },
    },
    SupplierTax: {
      keyFields: false,
    },
    ProjectSupplier: {
      keyFields: false,
      fields: {
        company: {
          merge: false,
        },
      },
    },
    MasterSupplier: {
      fields: {
        company: {
          merge: false,
        },
      },
    },
    Client: {
      fields: {
        configs: { merge: false },
        searchContacts: fromSizeHitsPagination(),
        address: {
          merge: false,
        },
        financial: {
          merge: false,
        },
      },
    },
    Property: {
      fields: {
        address: {
          merge: false,
        },
      },
    },
    Batch: {
      fields: {
        workflow: { merge: false },
      },
    },
    PropertyHierarchySpendReportResult: {
      fields: { properties: { merge: false } },
    },
    InvoiceSpendReportResult: {
      keyFields: false,
    },
    SpendReportInvoice: {
      keyFields: false,
    },
    BatchInvoiceSpendReportResult: {
      keyFields: false,
    },
    BatchSpendReportInvoice: {
      keyFields: false,
    },
    Project: {
      fields: {
        searchAssets: fromSizeHitsPagination(),
        suppliers: { merge: false },
        technicians: { merge: false },
      },
    },
    RecurringProject: {
      fields: {
        searchAssets: fromSizeHitsPagination(),
        suppliers: { merge: false },
        technicians: { merge: false },
      },
    },
    Query: {
      fields: {
        dashboard: {
          merge: false,
        },
        searchRecurringProjects: fromSizeHitsPagination(),
        searchProperties: fromSizeHitsPagination(),
        searchSuppliers: fromSizeHitsPagination(),
        searchMoneyTransactions: fromSizeHitsPagination(),
        searchProjects: fromSizeHitsPagination(),
        searchInvoices: fromSizeHitsPagination(),
        searchUserAlerts: fromSizeHitsPagination(),
        searchUsers: fromSizeHitsPagination(),
        searchAssets: fromSizeHitsPagination(),
        searchOCRInvoices: fromSizeHitsPagination(),
        getEditableClientPayments: fromSizeHitsPagination(),
        accountingPeriodsUpdates: fromSizeHitsPagination(),
        searchTaskTemplates: fromSizeHitsPagination(),
        supplierChecks: fromSizeHitsPagination([
          'input',
          [
            'checkNumber',
            'bankAccountId',
            'isPrinted',
            'paymentDateFrom',
            'paymentDateTo',
            'status',
          ],
        ]),
        getManualJournals: fromSizeHitsPagination([
          'input',
          ['checkFromDate', 'checkToDate'],
        ]),
        clientInvoices: fromSizeHitsPagination([
          'input',
          [
            'status',
            'clientId',
            'projectId',
            'clientBatchId',
            'invoiceNumberFrom',
            'invoiceNumberTo',
            'invoiceNumber',
            'startDate',
            'endDate',
            'isPrinted',
            'sort',
          ],
        ]),
        wipRecords: fromSizeHitsPagination([
          'input',
          ['clientId', 'date', 'projectManagerId', 'projectAccountingStatus'],
        ]),
        arAgingReport: fromSizeHitsPagination([
          'input',
          ['clientId', 'country'],
        ]),
        apAgingReport: fromSizeHitsPagination([
          'input',
          [
            'country',
            'supplierId',
            'invoiceEndDate',
            'invoiceStartDate',
            'clientInvoiceStatus',
            'excludeReadyToInvoice',
          ],
        ]),
        salesTaxReport: fromSizeHitsPagination([
          'input',
          ['startDate', 'endDate', 'state'],
        ]),
        accountTransactions: accountTransactionsPagination(),
        requestedSupplierDocuments: fromSizeHitsPagination([
          'input',
          ['supplierId', 'documentType', 'sort'],
        ]),
      },
    },
  },
});

export const createApolloClient = (
  redirectPageHandler,
  handleApolloError,
  handleApplicationMaintenance,
  { token = null, overrideLinks = null, additionalAfterWareLinks = [] } = {},
) => {
  const subscriptionLink = new GraphQLWsLink(
    createClient({
      url: GRAPHQL_SUBSCRIPTION_URL,
      shouldRetry: shouldRetryWSLinkSubscription(handleApplicationMaintenance),
      retryAttempts: Infinity,
      lazy: true,
      connectionParams: () => ({ token }),
      retryWait: async (retryCount) => {
        // exponential backoff reconnection timeout strategy
        const retryTimeout = retryCount < 60 ? (retryCount + 1) * 1000 : 60e3;

        if (navigator.onLine) {
          await waitForTimeoutP(retryTimeout);
        } else {
          await waitForTimeoutP(OFFLINE_RECONNECT_RETRY_TIME);
        }
      },
      on: {
        closed: ({ code }) => {
          setLastClosedConnectionCode(code);
        },
      },
    }),
  );

  const commonLinks = overrideLinks || [
    ...additionalAfterWareLinks,
    authAfterWare(redirectPageHandler),
    errorAfterWare(handleApolloError),
    appMaintenanceMiddleware(handleApplicationMaintenance),
    authMiddleWare(),
  ];

  const batchHttpLink = new BatchHttpLink({
    uri: GRAPHQL_PUBLIC_URL,
    credentials: 'include',
  });

  const uploadLink = createUploadLink({
    uri: GRAPHQL_PUBLIC_URL,
    credentials: 'include',
  });

  const mutationLink = combineLinks(R.concat(commonLinks, [uploadLink]));

  const queryLink = combineLinks(R.concat(commonLinks, [batchHttpLink]));

  const isOperation =
    (operationName) =>
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === operationName;
    };

  const requestLink = splitLinks(
    isOperation('subscription'),
    subscriptionLink,
    queryLink,
  );

  const terminalLink = splitLinks(
    isOperation('mutation'),
    mutationLink,
    requestLink,
  );

  return new ApolloClient({
    link: terminalLink,
    cache: apolloCache,
    connectToDevTools: NODE_ENV === 'development',
    defaultOptions: {
      // do not consider request failed if errors present. Except for mutations.
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
    },
  });
};

export const createApolloClientWitchPersistCache = async ({
  redirectPageHandler,
  handleApolloError,
  persistCache,
  storage,
}) => {
  await persistCache({
    cache: apolloCache,
    storage,
  });

  return createApolloClient(redirectPageHandler, handleApolloError);
};

export { getErrorMessagesByApolloError } from './error-helpers.js';

export const getApolloClientForWorker = () =>
  createApolloClient(R.identity, R.identity, null, {
    overrideLinks: [
      setContext(() => ({
        headers: {
          'apollo-require-preflight': true,
          'poly-client-version': process.env.APP_VERSION,
        },
      })),
    ],
  });

export { getTemporalUserToken, setTemporalUserToken } from './utils.js';
