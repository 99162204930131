import React from 'react';
import { instanceOf, func, bool } from 'prop-types';
import { S, Holder, Input } from 'poly-book';

import { DatePicker } from './DatePiker.js';

export function DateRangePiker({ startDate, endDate, onChange, isLabel }) {
  return (
    <Holder margin={30} center>
      {isLabel && (
        <S type="title" uppercase>
          Date Selector:
        </S>
      )}
      <Holder margin={10} center>
        <S type="badge">From</S>
        <DatePicker
          customInput={<Input name="startDate" />}
          dateFormat="MM-dd-yyyy"
          onChange={(date) => onChange({ endDate, startDate: date })}
          selected={startDate}
          placeholderText="Select date"
          maxDate={endDate}
        />
        <S type="badge">to</S>
        <DatePicker
          customInput={<Input name="endDate" />}
          dateFormat="MM-dd-yyyy"
          onChange={(date) => onChange({ startDate, endDate: date })}
          selected={endDate}
          placeholderText="Select date"
          minDate={startDate}
        />
      </Holder>
    </Holder>
  );
}

DateRangePiker.defaultProps = {
  isLabel: true,
};

DateRangePiker.propTypes = {
  onChange: func.isRequired,
  startDate: instanceOf(Date),
  endDate: instanceOf(Date),
  isLabel: bool,
};
