import { MAX_FILE_SIZE_ERROR_MESSAGE } from 'poly-constants';

export const ALERTS = {
  NoDataToDisplay: 'No Data To Display',
  MaxRangeError:
    'We are unable to pull the information as your range exceeds 10,000 results. ' +
    'Try using a smaller range.',
  MaxFileSizeError: MAX_FILE_SIZE_ERROR_MESSAGE,
  GeneralError:
    'Oops, something went wrong. You may be able to try again. ' +
    'If the the problem continues, please contact support.',
  AddFileSuccess: 'File Successfully Saved',
  AddUpdateSuccess: 'Update Successfully Created',
};
