import { string } from 'prop-types';
import React, { useMemo } from 'react';

import { EntityMasterProjectsTab } from '../../components/EntityRecurringProjectsTab.js';
import { getProjectsQueryByPropertyId } from './PropertyProjectsTab.js';

export function PropertyMasterProjectsTab({ propertyId }) {
  const searchQuery = useMemo(
    () => getProjectsQueryByPropertyId(propertyId),
    [propertyId],
  );

  return <EntityMasterProjectsTab searchQuery={searchQuery} />;
}

PropertyMasterProjectsTab.propTypes = { propertyId: string.isRequired };
