import * as R from 'ramda';
import {
  AccountingStatus,
  onHoldWorkOrderStatuses,
  ProjectType,
  RecurringProjectTypes,
  WorkOrderStatus,
} from 'poly-constants';
import { endOfCurrentDay } from 'poly-utils';
import { dateFieldLt } from 'poly-client-utils';
import { startOfToday } from 'date-fns';

import { getStartDateOfRange } from '../../constants/common.js';

export const PROJECTS_FILTERS = {
  reactive: 'reactive',
  recurring: 'recurring',
  pm: 'pm',
  all: 'all',
  onHold: 'onHold',
  pastDue: 'pastDue',
  completedPendingInvoice: 'completedPendingInvoice',
};

const HOLD_STATUS_QUERY = [
  { match: { status: onHoldWorkOrderStatuses[0] } },
  { match: { status: onHoldWorkOrderStatuses[1] } },
  { match: { status: onHoldWorkOrderStatuses[2] } },
];

const isActiveProjectSearchQuery = {
  match: { status: WorkOrderStatus.ACTIVE },
};

export const completedProjectsQuery = {
  match: { status: WorkOrderStatus.COMPLETED },
};

export const blockedProjectsSearchQuery = {
  match: {
    status: WorkOrderStatus.BLOCKED,
  },
};

const isPMProjectSearchQuery = {
  match: { type: ProjectType.PREVENTIVE_MAINTENANCE },
};

const isChildProjectSearchQuery = { exists: { field: 'parentId' } };

const isParentOfTypeSearchQuery = (type) => ({
  match: { 'parent.type': type },
});

const getReactiveProjectsSearchQuery = (must) => ({
  bool: {
    must_not: [isChildProjectSearchQuery, isPMProjectSearchQuery],
    must,
  },
});

export const getPMProjectsSearchQuery = (must) => ({
  bool: {
    minimum_should_match: 1,
    should: [
      isPMProjectSearchQuery,
      isParentOfTypeSearchQuery(
        RecurringProjectTypes.preventiveMaintenanceProject,
      ),
    ],
    ...(must ? { must } : {}),
  },
});

export const getWOSearchQuery = (must) => ({
  bool: {
    must_not: [getPMProjectsSearchQuery()],
    ...(must ? { must } : {}),
  },
});

// getProjectsSearchQuery :: (String, String) -> ElasticQuery
export const getProjectsSearchQuery = (status, clientId) => {
  const clientSearchQuery = clientId
    ? [
        {
          match: { clientId },
        },
      ]
    : [];

  const map = {
    [PROJECTS_FILTERS.reactive]: getReactiveProjectsSearchQuery([
      isActiveProjectSearchQuery,
      ...clientSearchQuery,
    ]),
    [PROJECTS_FILTERS.recurring]: {
      bool: {
        must: [
          isActiveProjectSearchQuery,
          isChildProjectSearchQuery,
          isParentOfTypeSearchQuery(RecurringProjectTypes.recurringProject),
          ...clientSearchQuery,
        ],
      },
    },
    [PROJECTS_FILTERS.pm]: getPMProjectsSearchQuery([
      isActiveProjectSearchQuery,
      ...clientSearchQuery,
    ]),
    [PROJECTS_FILTERS.all]: {
      bool: {
        must: [isActiveProjectSearchQuery, ...clientSearchQuery],
      },
    },
    [PROJECTS_FILTERS.onHold]: {
      bool: {
        must: [
          {
            bool: {
              should: HOLD_STATUS_QUERY,
            },
          },
          ...clientSearchQuery,
        ],
      },
    },
    [PROJECTS_FILTERS.pastDue]: {
      bool: {
        must: [
          isActiveProjectSearchQuery,
          dateFieldLt('endDate', startOfToday()),
          ...clientSearchQuery,
        ],
      },
    },
    [PROJECTS_FILTERS.completedPendingInvoice]: {
      bool: {
        must: [
          completedProjectsQuery,
          { match: { accountingStatus: AccountingStatus.READY_TO_INVOICE } },
          ...clientSearchQuery,
        ],
        must_not: [{ match: { type: ProjectType.REPORT_ONLY } }],
      },
    },
  };

  return map[status];
};

// getDateRangeQuery :: String -> SelectedRange -> SearchQuery
const getDateRangeQuery = (applyRangeFilterTo) => (selectedRange) => {
  const startDate = getStartDateOfRange(selectedRange);
  return {
    range: {
      [applyRangeFilterTo]: {
        lte: endOfCurrentDay(),
        ...(startDate ? { gte: startDate } : {}),
      },
    },
  };
};

// getSearchQueryWithinDateRange :: (SearchQuery, String) -> SelectedRange -> SearchQuery
export const getSearchQueryWithinDateRange = (baseQuery, applyRangeFilterTo) =>
  R.compose(
    R.assocPath(['bool', 'filter'], R.__, baseQuery),
    getDateRangeQuery(applyRangeFilterTo),
  );
