import React from 'react';
import * as R from 'ramda';
import { Icon } from 'poly-book';
import { isFileImage } from 'poly-client-utils';
import { format, formatDistanceToNow } from 'date-fns';
import { ensureIsDate } from 'poly-utils';

// formatDateWithSlashes :: Date -> String
export const formatDateWithSlashes = (datetime) => ({
  date: format(ensureIsDate(datetime), 'MM/dd/yyyy'),
  time: format(ensureIsDate(datetime), 'h:mma'),
});

// checkAvatarType :: String -> String
export const checkAvatarType = (name) =>
  name && name === 'Poly' ? 'poly' : 'text';

// getKeyFromURL :: String -> String
export const getKeyFromURL = R.compose(R.last, R.split('/'));

// getAttachmentIcon :: String -> Node
export const getAttachmentIcon = (fileType) => {
  const type = R.last(fileType.split('/'));
  const isRecognized = Icon({ name: type });

  return (
    <Icon name={isRecognized ? type : 'file'} dimensions={{ height: 18 }} />
  );
};

// filterImages :: [Attachment] -> [Attachment]
export const filterImages = R.filter(isFileImage);

// getUpdateAttachments :: UpdateComment -> [Attachment]
export const getUpdateAttachments = R.propOr([], 'attachments');

// getLastUpdateCommentInfo :: [UpdateComment] -> { count: Number, user: String, time: String }
export const getLastUpdateCommentInfo = R.converge(R.mergeDeepLeft, [
  R.pipe(R.length, R.objOf('count')),
  R.pipe(
    R.last,
    R.applySpec({
      user: R.path(['createdBy', 'fullName']),
      time: R.pipe(R.prop('createdAt'), ensureIsDate, formatDistanceToNow),
    }),
  ),
]);
