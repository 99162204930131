import * as R from 'ramda';
import styled from 'styled-components';
import { Icon } from 'poly-book';
import React, { useState } from 'react';
import { bool, element, number, shape, string } from 'prop-types';
import { preparePropertyHierarchyTotals } from 'poly-client-utils';

import { SpendReportTable } from './SpendReportTable.js';
import { isEmptyHierarchyNodeBranches } from './helpers.js';
import { spendReportPropertiesPropTypes } from './prop-types.js';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin: ${({ level }) => (level === 0 ? '20px 0' : '20px 0 0 0')};
  justify-content: space-between;
  background-color: ${({ level }) => (level === 0 ? 'transparent' : '#f1f0f0')};
  padding: ${({ level }) => (level === 0 ? '0' : `5px 0 5px ${level * 15}px`)};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const Text = styled.div`
  font-size: ${R.prop('size')};
  color: ${({ color }) => color || '#000'};
  font-weight: 500;
  line-height: 26px;
`;

const FooterTotalWrapper = styled.div`
  width: 135px;
  margin-right: 17px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-bottom: 1px solid #f5f2f2;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 15px;
  margin-right: 10px;
`;

function TotalC({ label, total, ...props }) {
  return (
    <FooterTotalWrapper {...props}>
      <Text size="12px" color="#999">
        {label}
      </Text>
      <Text size="16px">{total}</Text>
    </FooterTotalWrapper>
  );
}

TotalC.propTypes = {
  label: string.isRequired,
  total: string.isRequired,
};

const TotalCS = styled(TotalC)`
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
  margin-right: 0;

  div {
    margin-right: 10px;
  }
`;

export function CollapseBase({
  title,
  content,
  level,
  clientInvoiceTotal,
  withFooterTotals,
  totalLabel,
  footerTotalLabel,
}) {
  const [isOpen, setIsOpen] = useState(true);

  const size = level === 0 ? '20px' : '14px';

  const titleProps = {
    level,
    withFooterTotals,
    onClick: () => setIsOpen(!isOpen),
  };

  return (
    <>
      <TitleWrapper {...titleProps}>
        <Title>
          <IconWrapper>
            <Icon
              name={isOpen ? 'hierarchy-arrow-up' : 'hierarchy-arrow-down'}
            />
          </IconWrapper>
          <Text size={size}>{title}</Text>
        </Title>
        {(!withFooterTotals || !isOpen) && (
          <TotalCS label={totalLabel} total={clientInvoiceTotal} />
        )}
      </TitleWrapper>
      {isOpen && content}
      {withFooterTotals && isOpen && (
        <Footer>
          <TotalC
            isFooter
            label={footerTotalLabel}
            total={clientInvoiceTotal}
          />
        </Footer>
      )}
    </>
  );
}

CollapseBase.defaultProps = {
  totalLabel: '',
  footerTotalLabel: '',
};

CollapseBase.propTypes = {
  title: string.isRequired,
  content: element,
  level: number.isRequired,
  withFooterTotals: bool,
  clientInvoiceTotal: string,
  totalLabel: string,
  footerTotalLabel: string,
};

function SpendReportCollapse({ report, level, ...props }) {
  const { clientInvoiceTotal } = preparePropertyHierarchyTotals(report);

  const totalLabel = `Total level ${level + 1}:`;

  return (
    <CollapseBase
      clientInvoiceTotal={clientInvoiceTotal}
      totalLabel={totalLabel}
      footerTotalLabel="Total:"
      level={level}
      {...props}
    />
  );
}

SpendReportCollapse.defaultProps = {
  report: {},
};
SpendReportCollapse.propTypes = {
  title: string.isRequired,
  content: element,
  level: number.isRequired,
  withFooterTotals: bool,
  report: shape(spendReportPropertiesPropTypes),
};

export function SpendReportHierarchyTree({
  report,
  isTransparent,
  isPrint,
  level,
}) {
  const collapseProps = {
    level,
    title: report.name,
    key: report.name,
  };

  if (isEmptyHierarchyNodeBranches([report])) {
    return null;
  }

  if (report.properties) {
    return (
      <SpendReportCollapse
        {...collapseProps}
        withFooterTotals
        report={report}
        content={
          <SpendReportTable
            {...{ reports: report.properties, isTransparent, isPrint }}
          />
        }
      />
    );
  }

  return (
    <SpendReportCollapse
      {...collapseProps}
      report={report}
      content={
        <>
          {report.children.map((children) => (
            <SpendReportHierarchyTree
              key={children.name}
              report={children}
              isTransparent={isTransparent}
              isPrint={isPrint}
              level={level + 1}
            />
          ))}
        </>
      }
    />
  );
}

SpendReportHierarchyTree.propTypes = {
  level: number.isRequired,
  isPrint: bool,
  isTransparent: bool,
  report: shape(spendReportPropertiesPropTypes),
};
