import React, { useState } from 'react';
import { string } from 'prop-types';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getFileUploadErrorMessageByResult } from 'poly-client-utils';
import { MAX_FILE_SIZE } from 'poly-constants';
import { gql, useMutation } from '@apollo/client';
import { removeFileExtension } from 'poly-utils';

import { setFileModal } from '../../redux/fileModal.js';
import { AddFileModalComp } from './AddFileModalComp.js';
import { ALERTS } from '../../constants/alerts.js';

const attachFile = gql`
  mutation attachFilesToDocument($input: AttachFilesToDocumentInput!) {
    attachFilesToDocument(input: $input) {
      files {
        _id
        fileType
        fileName
        createdAt
        url
      }
      errors {
        error
      }
    }
  }
`;

export function AddFileModal({ heading }) {
  const fileModal = useSelector(R.prop('fileModal'));
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);

  const [attachFileMutation] = useMutation(attachFile);

  const onChange = (e) => {
    const {
      target: { value },
    } = e;

    if (value.length) {
      setError(null);
    } else {
      setError({ fileName: 'File name is required' });
    }

    return setFileName(value);
  };

  const onAddFile = (newFile) => {
    setFile(newFile);
    setFileName(removeFileExtension(newFile.filename));
  };

  const cleanUp = () => {
    setFile(null);
    setError(null);
    setFileName('');
  };

  const closeModal = () => {
    dispatch(setFileModal(null));
    return cleanUp();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const fileSize = R.pathOr(0, ['file', 'size'], file);

    if (fileSize > MAX_FILE_SIZE) {
      toast.error(ALERTS.MaxFileSizeError);
      setLoading(false);
      return false;
    }

    if (error) {
      setLoading(false);
      return false;
    }

    try {
      const response = await attachFileMutation({
        variables: {
          input: {
            documentId: R.path(['data', 'documentId'], fileModal),
            collection: R.path(['data', 'collection'], fileModal),
            files: [
              {
                upload: R.prop('file', file),
                fileName,
              },
            ],
          },
        },
      });

      dispatch(setFileModal(null));

      const uploadError = getFileUploadErrorMessageByResult(response);
      if (uploadError) {
        toast.error(uploadError);
      } else {
        toast.success(ALERTS.AddFileSuccess);
        cleanUp();
      }
    } catch (err) {
      setError({ server: err.message });
      toast.error(ALERTS.GeneralError);
    } finally {
      setLoading(false);
    }

    return true;
  };

  if (fileModal && fileModal.type === 'add') {
    return (
      <AddFileModalComp
        file={file}
        errors={error}
        loading={loading}
        heading={heading}
        fileName={fileName}
        onSubmit={onSubmit}
        onChange={onChange}
        onAddFile={onAddFile}
        onRemoveFile={cleanUp}
        closeModal={closeModal}
        isFileNameDisabled={R.isNil(file)}
      />
    );
  }

  return null;
}

AddFileModal.propTypes = {
  heading: string,
};
