import React from 'react';
import styled from 'styled-components';
import { L, Icon } from 'poly-book';
import { bool, func, number } from 'prop-types';

const HEIGHT = 20;
const PADDING = 3;

const Arrow = styled(L.withComponent('span'))`
  padding: ${PADDING}px;
  position: absolute;
  cursor: pointer;
  top: calc(50% - ${({ height }) => height / 2}px - ${PADDING}px);
  ${({ alignLeft }) => alignLeft && 'right: 100%;'};
`;

const Wrapper = styled.div`
  position: relative;
`;

export function BackArrowBase({ onClick, height, alignLeft }) {
  return (
    <Wrapper>
      <Arrow onClick={onClick} height={height} alignLeft={alignLeft}>
        <Icon name="arrow-left" dimensions={{ height }} fill="#babfd2" />
      </Arrow>
    </Wrapper>
  );
}

BackArrowBase.propTypes = {
  onClick: func,
  height: number,
  alignLeft: bool,
};

BackArrowBase.defaultProps = {
  height: HEIGHT,
  alignLeft: true,
};
