import React from 'react';
import { Select } from 'poly-book';

import { SpendReportSelectWrapper } from './PropertySelect.js';

export function HierarchySelect(props) {
  return (
    <SpendReportSelectWrapper>
      <Select
        {...props}
        clearable={false}
        placeholder="Start typing hierarchy"
        noResultsText="No Hierarchy Found"
        onSelectResetsInput={false}
      />
    </SpendReportSelectWrapper>
  );
}
