import * as R from 'ramda';
import React from 'react';
import { string } from 'prop-types';
import {
  formatDate,
  formatTotal,
  convertCentsToDollars,
  isNilOrEmpty,
} from 'poly-utils';
import { NOTHING_UI_STRING } from 'poly-constants';

import { ProjectLink } from '../../components/ProjectLink.js';
import { spendReportCellType } from './constants.js';

function InvoiceNumberLink({ file, invoiceNumber }) {
  return file ? (
    <a href={file} target="_blank" rel="noopener noreferrer">
      {invoiceNumber}
    </a>
  ) : (
    invoiceNumber
  );
}

InvoiceNumberLink.propTypes = {
  file: string,
  invoiceNumber: string.isRequired,
};

const getTableConfig = (isTransparent, isPrint) => [
  [
    { title: 'Project #', type: spendReportCellType.default },
    ({ projectNumber }) =>
      isPrint ? (
        projectNumber
      ) : (
        <ProjectLink projectId={projectNumber} target="_blank" />
      ),
  ],
  ...(isTransparent
    ? [
        [
          { title: 'Invoice #', type: spendReportCellType.default },
          InvoiceNumberLink,
        ],
      ]
    : []),
  [
    { title: 'Invoice Date', type: spendReportCellType.default },
    R.compose(formatDate, R.prop('date')),
  ],
  ...(isTransparent
    ? [
        [
          { title: 'Supplier', type: spendReportCellType.default },
          R.propOr(NOTHING_UI_STRING, 'supplier'),
        ],
      ]
    : []),
  [
    { title: 'Service Type', type: spendReportCellType.default },
    R.propOr(NOTHING_UI_STRING, 'serviceType'),
  ],
  [
    { title: 'Description', type: spendReportCellType.default },
    R.propOr(NOTHING_UI_STRING, 'description'),
  ],
  ...(isTransparent
    ? [
        [
          { title: 'Cost', type: spendReportCellType.money },
          R.compose(formatTotal, convertCentsToDollars, R.propOr(0, 'total')),
        ],
      ]
    : []),
  [
    { title: 'Client Invoice', type: spendReportCellType.money },
    R.compose(
      formatTotal,
      convertCentsToDollars,
      R.propOr(0, 'clientInvoicesAmount'),
    ),
  ],
];

// prepareInvoicesReport :: [PropertyInvoicesReport] -> [PropertyInvoicesReport]
const prepareInvoicesReport = R.compose(
  R.unnest,
  R.juxt([
    R.converge(R.mergeRight, [
      R.compose(R.head, R.propOr([], 'invoices')),
      R.applySpec({
        projectNumber: R.prop('projectNumber'),
        description: R.prop('description'),
        clientInvoicesAmount: R.prop('clientInvoicesAmount'),
      }),
    ]),
    R.compose(R.tail, R.propOr([], 'invoices')),
  ]),
);

// prepareSpendReportTableData :: [PropertySpendReport] -> [PropertySpendReport]
const prepareSpendReportTableData = R.compose(
  R.map(
    R.applySpec({
      clientInvoicesTotal: R.prop('clientInvoicesTotal'),
      invoicesTotal: R.prop('invoicesTotal'),
      propertyName: R.prop('propertyName'),
      propertyId: R.prop('propertyId'),
      propertyInvoicesReport: R.compose(
        R.unnest,
        R.map(prepareInvoicesReport),
        R.propOr([], 'propertyInvoicesReport'),
      ),
      childProperties: R.compose(
        R.map(
          R.unless(
            R.propSatisfies(isNilOrEmpty, 'propertyInvoicesReport'),
            R.over(
              R.lensProp('propertyInvoicesReport'),
              R.compose(R.unnest, R.map(prepareInvoicesReport)),
            ),
          ),
        ),
        R.propOr([], 'childProperties'),
      ),
    }),
  ),
);

export const useTableProps = (reports, isTransparent, isPrint) => {
  const tableConfig = getTableConfig(isTransparent, isPrint);

  return {
    rows: prepareSpendReportTableData(reports),
    columns: R.map(R.nth(1), tableConfig),
    headers: R.map(R.nth(0), tableConfig),
    gridColumns: isTransparent
      ? 'repeat(3, minmax(50px, 200px)) repeat(2, minmax(100px, 300px)) minmax(350px, 500px) repeat(2, minmax(100px, 200px))'
      : 'repeat(2, minmax(50px, 200px)) minmax(200px, 300px) 1fr minmax(200px, 250px)',
    isTransparent,
  };
};
