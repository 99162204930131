import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Loader as PolyBookLoader, Overlay } from 'poly-book';
import styled from 'styled-components';

const FixedOverlay = styled(Overlay)`
  position: fixed;
  height: 300px;
  width: 550px;
  z-index: 2;
`;

export function Loader({ size, fixed, ...props }) {
  const Wrapper = fixed ? FixedOverlay : Overlay;
  return (
    <Wrapper {...R.omit(['loading'], props)}>
      <PolyBookLoader {...{ size }}>
        <PolyBookLoader.Circle {...{ size }} />
      </PolyBookLoader>
    </Wrapper>
  );
}

export function AbsoluteLoader() {
  return <Loader absolute />;
}

Loader.propTypes = {
  size: PropTypes.number,
  fixed: PropTypes.bool,
};

Loader.defaultProps = {
  size: 50,
  fixed: false,
};

export function SmallLoader(props) {
  return <Loader {...props} size={20} />;
}

const SIZE = 26;

export const HeaderInfoLoader = styled(Loader).attrs(() => ({
  size: SIZE,
}))`
  width: ${SIZE}px;
  height: ${SIZE}px;
  background-color: transparent;
`;
