import { gql } from '@apollo/client';

export const propertiesForSelectQuery = gql`
  query propertiesForSelectQuery($input: CollectionSearchParams) {
    searchProperties(input: $input) {
      total
      hits {
        _id
        name
      }
    }
  }
`;
