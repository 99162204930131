import styled from 'styled-components';

const Heading = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Base = styled.h1`
  margin: 0;
  padding: 0;
  color: ${({ lighter }) => (lighter ? '#5e6271' : '#2d2f33')};
  font-family: ${({ theme }) => theme.fonts.meta};
  font-weight: 500;
`;

Heading.h1 = styled(Base.withComponent('h1'))`
  font-size: 28px;
`;

Heading.h2 = styled(Base.withComponent('h2'))`
  font-size: 24px;
`;

Heading.h3 = styled(Base.withComponent('h3'))`
  font-size: 20px;
`;

Heading.h4 = styled(Base.withComponent('h4'))`
  font-size: 16px;
`;

Heading.h5 = styled(Base.withComponent('h5'))`
  font-size: 14px;
`;

Heading.h6 = styled(Base.withComponent('h6'))`
  font-size: 12px;
`;

Heading.displayName = 'Heading';
Heading.h1.displayName = 'Heading.h1';
Heading.h2.displayName = 'Heading.h2';
Heading.h3.displayName = 'Heading.h3';
Heading.h4.displayName = 'Heading.h4';
Heading.h5.displayName = 'Heading.h5';
Heading.h6.displayName = 'Heading.h6';

export default Heading;
