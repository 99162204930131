const SET_GLOBAL_SEARCH_STRING = 'SET_GLOBAL_SEARCH_STRING';
const TOGGLE_GLOBAL_SEARCH_FOCUS = 'TOGGLE_GLOBAL_SEARCH_FOCUS';

const defaultState = {
  globalSearchString: '',
  isSearchFilled: false,
  isSearchFocused: false,
};

export const globalSearchReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case SET_GLOBAL_SEARCH_STRING: {
      return {
        ...state,
        globalSearchString: action.payload,
        isSearchFilled: !!action.payload.length,
      };
    }
    case TOGGLE_GLOBAL_SEARCH_FOCUS: {
      return {
        ...state,
        isSearchFocused: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export const setGlobalSearchStringAction = (search) => ({
  type: SET_GLOBAL_SEARCH_STRING,
  payload: search,
});

export const toggleGlobalSearchFocusAction = (payload) => ({
  type: TOGGLE_GLOBAL_SEARCH_FOCUS,
  payload,
});
