import PropTypes from 'prop-types';
import styled from 'styled-components';

import S from '../S/index.js';
import Loader from '../Loader/index.js';

const [COLOR, BORDER, BACKGROUND] = ['color', 'border', 'background'];

const modes = {
  orange: {
    [COLOR]: '#fff',
    [BORDER]: '#f8a957',
    [BACKGROUND]: '#ff8c00',
  },
  blue: {
    [COLOR]: '#fff',
    [BORDER]: '#80a1cb',
    [BACKGROUND]: '#2465bd',
  },
  gray: {
    [COLOR]: '#a1a8c3',
    [BORDER]: '#abaeb9',
    [BACKGROUND]: 'transparent',
  },
  grayWhite: {
    [COLOR]: '#fff',
    [BORDER]: '#abaeb9',
    [BACKGROUND]: '#abaeb9',
  },
  default: {
    [COLOR]: '#4f7db8',
    [BORDER]: '#4675b4',
    [BACKGROUND]: 'transparent',
  },
};

const getValueByName =
  (property) =>
  ({ mode }) =>
    (mode && modes[mode]?.[property]) || modes.default[property];

const getProperty = (property) => {
  switch (property) {
    case BORDER:
      return 'border-color';
    case BACKGROUND:
      return 'background-color';
    case COLOR:
    default:
      return 'color';
  }
};

const getPropertyByName =
  (property) =>
  ({ mode }) => {
    const value = getValueByName(property)({ mode });

    return value && `${getProperty(property)}: ${value}`;
  };

const Button = styled.button`
  display: inline-flex;
  padding: 6px 20px;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  ${getPropertyByName(BORDER)};
  ${getPropertyByName(BACKGROUND)};

  > ${Loader} {
    > ${Loader.Circle} {
      stroke: ${getValueByName(COLOR)};
    }

    + ${S} {
      opacity: 0;
    }
  }

  > ${S} {
    margin-top: 3px;
    transition: opacity 0.3s;
    ${getPropertyByName(COLOR)};
  }

  &[disabled] {
    &,
    &:hover {
      cursor: not-allowed;
      user-select: none;
    }
  }
`;

Button.propTypes = {
  type: PropTypes.string,
};

Button.defaultProps = {
  type: 'button',
};

Button.displayName = 'Button';

export default Button;
