import { useSortableTable } from 'poly-client-utils';

export const useTableSorting = (initialSortingColumn, order, tableConfig) => {
  const { sort, sorting, onHeaderCellClick } = useSortableTable({
    column: initialSortingColumn,
    sortQueryOptionOrder: 3,
    tableConfig,
    order,
  });
  return {
    sort,
    onHeaderCellClick,
    sorting: { key: sorting.columnKey, dir: sorting.dir },
  };
};
