import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { string, object } from 'prop-types';

import { iconPropTypes, TotalItem } from '../../components/TotalItem.js';
import { useAssetReactiveSpendQuery } from './useAssetReactiveSpendQuery.js';
import { useProjectsTotalByDatePeriodQuery } from './useAssetProjectsTotalQuery.js';
import {
  getPMProjectsSearchQuery,
  getWOSearchQuery,
} from '../ProjectsPage/helpers.js';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 17px;
`;

function AssetReactiveSpend({ assetId, selectedRange }) {
  const { total } = useAssetReactiveSpendQuery(assetId, selectedRange);
  return (
    <TotalItem
      title="Reactive Spend for asset"
      total={total}
      tooltip={{ isVisible: R.F }}
    />
  );
}

AssetReactiveSpend.propTypes = {
  assetId: string.isRequired,
  selectedRange: string,
};

function AssetProjectsTotal({ assetId, selectedRange, query, title, icon }) {
  const { total } = useProjectsTotalByDatePeriodQuery(
    assetId,
    query,
    selectedRange,
  );
  return (
    <TotalItem
      title={title}
      total={total}
      icon={icon}
      isMoney={false}
      tooltip={{ isVisible: R.F }}
    />
  );
}

AssetProjectsTotal.propTypes = {
  assetId: string.isRequired,
  selectedRange: string,
  // eslint-disable-next-line react/forbid-prop-types
  query: object.isRequired,
  title: string.isRequired,
  icon: iconPropTypes,
};
export function AssetOverviewSection({ assetId, selectedRange }) {
  return (
    <Container>
      <AssetReactiveSpend assetId={assetId} selectedRange={selectedRange} />
      <AssetProjectsTotal
        assetId={assetId}
        selectedRange={selectedRange}
        query={getWOSearchQuery()}
        title="WOs Completed"
        icon={{ name: 'work-order', dimensions: { width: 18, height: 18 } }}
      />
      <AssetProjectsTotal
        assetId={assetId}
        selectedRange={selectedRange}
        query={getPMProjectsSearchQuery()}
        title="PMs Completed"
        icon={{ name: 'white-star', dimensions: { width: 18, height: 18 } }}
      />
    </Container>
  );
}

AssetOverviewSection.propTypes = {
  assetId: string.isRequired,
  selectedRange: string,
};
