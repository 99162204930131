import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

import Icon from '../Icon/index.js';
import IconButton from '../IconButton/index.js';

const HEXAGON_SIZE = 50;
const CLOSE_ICON_SIZE = 22;

const CloseButton = styled(IconButton)`
  padding: 0;
  position: absolute;
  left: -${HEXAGON_SIZE / 2}px;
  top: 15px;
`;

const SidebarBody = styled.div`
  min-width: 300px;
  max-width: 700px;
  height: 100%;
  padding: 30px;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  box-shadow: -3px 0 10px 0 rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
  transform: translateX(
    ${({ open }) => (!open ? `calc(100% + ${HEXAGON_SIZE / 2}px)` : 0)}
  );
  z-index: 6;
`;

SidebarBody.propTypes = {
  open: PropTypes.bool.isRequired,
};

export function Sidebar({ children, style, in: inProp }) {
  return (
    <Transition
      timeout={{
        enter: 0,
        exit: 300,
      }}
      onEnter={({ scrollTop }) => scrollTop}
      in={inProp}
    >
      {(state) => (
        <SidebarBody
          {...{ style }}
          open={state === 'entering' || state === 'entered'}
        >
          {children}
        </SidebarBody>
      )}
    </Transition>
  );
}

Sidebar.propTypes = {
  in: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Sidebar.Close = function ({ fill, onClick }) {
  return (
    <CloseButton {...{ onClick }}>
      <svg
        width={HEXAGON_SIZE}
        height={HEXAGON_SIZE}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="cut">
            <rect x="0" y="0" width="50%" height="100%" />
          </clipPath>
          <filter id="dropShadow">
            <feDropShadow
              dx="-4"
              stdDeviation="5.5"
              floodColor="#000"
              floodOpacity="0.15"
            />
          </filter>
        </defs>
        <polygon
          {...{ fill }}
          filter="url(#dropShadow)"
          clipPath="url(#cut)"
          strokeWidth="2"
          points="50,1 95,25 95,75 50,99 5,75 5,25"
        />
        <g transform={`translate(${50 - CLOSE_ICON_SIZE / 2}, 0)`}>
          <Icon
            name="close"
            fill="#888b97"
            dimensions={{ width: CLOSE_ICON_SIZE }}
          />
        </g>
      </svg>
    </CloseButton>
  );
};

Sidebar.Close.propTypes = {
  fill: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Sidebar.Close.defaultProps = {
  fill: '#fff',
};

function Close({ fill, onClick }) {
  return (
    <CloseButton {...{ onClick }}>
      <svg
        width={HEXAGON_SIZE}
        height={HEXAGON_SIZE}
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="cut">
            <rect x="0" y="0" width="50%" height="100%" />
          </clipPath>
          <filter id="dropShadow">
            <feDropShadow
              dx="-4"
              stdDeviation="5.5"
              floodColor="#000"
              floodOpacity="0.15"
            />
          </filter>
        </defs>
        <polygon
          {...{ fill }}
          filter="url(#dropShadow)"
          clipPath="url(#cut)"
          strokeWidth="2"
          points="50,1 95,25 95,75 50,99 5,75 5,25"
        />
        <g transform={`translate(${50 - CLOSE_ICON_SIZE / 2}, 0)`}>
          <Icon
            name="close"
            fill="#888b97"
            dimensions={{ width: CLOSE_ICON_SIZE }}
          />
        </g>
      </svg>
    </CloseButton>
  );
}

Close.propTypes = {
  fill: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Close.defaultProps = {
  fill: '#fff',
};

const SidebarSiteLayoutS = styled(SidebarBody)`
  width: 500px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  top: 60px;
  right: ${({ move }) => (move ? `${move}px` : 0)};
`;

export function SidebarSiteLayout({ children, onClose, open, move }) {
  return (
    <SidebarSiteLayoutS open={open} onClose={onClose} move={move}>
      <Close onClick={onClose} />
      {children}
    </SidebarSiteLayoutS>
  );
}

SidebarSiteLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  move: PropTypes.number,
};
