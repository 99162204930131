import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown as PolyBookDropdown } from 'poly-book';

export class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };

    this.onClick = this.onClick.bind(this);
    this.innerRef = this.innerRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside({ target }) {
    if (this.node.contains(target)) {
      return;
    }
    this.onClick();
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }

    if (!this.state.open) {
      window.document.body.addEventListener(
        'click',
        this.handleClickOutside,
        true,
      );
    } else {
      window.document.body.removeEventListener(
        'click',
        this.handleClickOutside,
        true,
      );
    }

    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  }

  innerRef(node) {
    this.node = node;
  }

  render() {
    const { open } = this.state;
    const { innerRef, onClick } = this;
    const {
      header,
      headerProps,
      arrowColor: bgColor,
      children,
      transparent,
      DropdownComponent,
      HeaderComponent,
      ItemsComponent,
      PipComponent,
      ...props
    } = this.props;

    return (
      <DropdownComponent {...props} ref={innerRef} {...{ onClick }}>
        <HeaderComponent {...{ transparent }} {...headerProps}>
          {header}
          {PipComponent && <PipComponent {...{ open, transparent, bgColor }} />}
        </HeaderComponent>
        <ItemsComponent {...{ open }}>{children}</ItemsComponent>
      </DropdownComponent>
    );
  }
}

const componentPropTypes = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.node,
  PropTypes.object,
]);

DropDown.propTypes = {
  arrowColor: PropTypes.string,
  transparent: PropTypes.bool,
  headerProps: PropTypes.shape({
    mode: PropTypes.string,
  }),
  DropdownComponent: componentPropTypes,
  HeaderComponent: componentPropTypes,
  ItemsComponent: componentPropTypes,
  PipComponent: componentPropTypes,
  onClick: PropTypes.func,
  header: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

DropDown.defaultProps = {
  transparent: false,
  arrowColor: '#2d2f33',
  header: null,
  DropdownComponent: PolyBookDropdown,
  HeaderComponent: PolyBookDropdown.Header,
  ItemsComponent: PolyBookDropdown.Items,
  PipComponent: PolyBookDropdown.Pip,
  headerProps: {},
  onClick: null,
};
