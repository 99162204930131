import {
  bool,
  func,
  array,
  number,
  object,
  string,
  oneOfType,
} from 'prop-types';
import * as R from 'ramda';
import Select from 'react-select';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import React, { useState, useCallback } from 'react';
import { debounce } from 'poly-utils';

import {
  ALL_SELECTED_OPTION,
  isAllOption,
  preparePropertyOptions,
  rejectAllOption,
} from './helpers.js';
import { useCurrentClientId } from '../../hooks/useCurrentClientId.js';
import { MultiSelectActions } from './constants.js';

export const SpendReportSelectWrapper = styled.div`
  width: ${R.propOr('300px', 'width')};
  margin-left: 35px;
  position: relative;
`;

const propertySelectOptionsFragment = gql`
  fragment propertySelectOptionsFragment on Property {
    _id
    name
    # "isMaster" and "masterProperty" fields used to
    # auto remove all child properties from property select filter
    # when master is removed
    isMaster
    masterProperty {
      _id
    }
  }
`;

const PROPERTIES_SEARCH_QUERY = gql`
  query PROPERTIES_SEARCH_QUERY($searchInput: CollectionSearchParams!) {
    searchProperties(input: $searchInput) {
      hits {
        ...propertySelectOptionsFragment
        # "childProperties" field used to auto add all
        # child properties to property select filter
        childProperties {
          ...propertySelectOptionsFragment
        }
      }
    }
  }

  ${propertySelectOptionsFragment}
`;

// getPropertiesOptions :: SearchProperties -> [Option]
const getPropertiesOptions = R.compose(
  R.prepend(ALL_SELECTED_OPTION),
  preparePropertyOptions,
  R.pathOr([], ['searchProperties', 'hits']),
);

const SelectS = styled(Select)`
  height: 39px;
  width: 100%;
  .select__control {
    height: 40px;
    padding-bottom: 10px;
    border-color: #e3e5ed;
  }
  .select__control--is-focused {
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
    border: none;
    .select__value-container {
      padding-top: 10px;
    }
  }
  .select__control:not(.select__control--is-focused) {
    .select__multi-value {
      background-color: #fff;
    }
    .select__multi-value__remove {
      display: none;
    }
  }
  .select__indicators {
    height: 39px;
  }
  .select__value-container {
    overflow: hidden;
    height: 100%;
  }
  .select__multi-value__label {
    font-size: 100%;
  }
`;

const Count = styled.div`
  width: 22px;
  height: 20px;
  margin-right: 10px;
  background-color: hsl(0, 0%, 90%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function DropdownIndicator({ hasValue, isFocused, getValue }) {
  const selectedProperties = getValue();
  const selectedPropertiesCount = selectedProperties.length;
  return hasValue && !isFocused && selectedPropertiesCount > 1 ? (
    <Count>{selectedPropertiesCount}</Count>
  ) : null;
}

DropdownIndicator.propTypes = {
  hasValue: bool.isRequired,
  isFocused: bool.isRequired,
  getValue: func.isRequired,
};

export const BaseSelectComponent = React.forwardRef((props, ref) => (
  <SelectS
    styles={{ menuPortal: (base) => ({ ...base, zIndex: 2 }) }}
    menuPortalTarget={document.body}
    menuPosition="absolute"
    classNamePrefix="select"
    ref={ref}
    closeMenuOnSelect={false}
    components={{
      IndicatorSeparator: () => null,
      DropdownIndicator,
    }}
    {...props}
  />
));

// removeAllChildPropertyOptions :: ID -> [PropertySelectOption] -> [PropertySelectOption]
const removeAllChildPropertyOptions = R.curry((masterPropertyId, options) =>
  R.reject(R.pathEq(['masterProperty', '_id'], masterPropertyId))(options),
);

// addAllChildPropertyOptions :: Input -> [PropertySelectOption]
// Input = {
//    options: [PropertySelectOption]
//    childProperties: [Property]
// }
const addAllChildPropertyOptions = R.compose(
  rejectAllOption,
  R.uniqBy(R.prop('value')),
  R.converge(R.concat, [
    R.propOr([], 'options'),
    R.compose(preparePropertyOptions, R.propOr([], 'childProperties')),
  ]),
);

export function PropertySelect({ value, onChange, width }) {
  const [searchTerm, setSearchTerm] = useState('');
  const selectRef = React.useRef(null);

  const clientId = useCurrentClientId();

  const { data, loading } = useQuery(PROPERTIES_SEARCH_QUERY, {
    variables: {
      searchInput: {
        query: { term: { clientId } },
        searchTerm,
        size: 25,
      },
    },
    skip: !clientId,
  });

  const onChangeDebounced = useCallback(
    debounce(400)((searchText) => {
      setSearchTerm(searchText);
    }),
    [],
  );

  const onChangeHandler = (options, { action, option, removedValue }) => {
    const isAdd = action === MultiSelectActions.ADD;
    const isRemove = action === MultiSelectActions.REMOVE;

    if (isAllOption(option)) {
      return onChange([ALL_SELECTED_OPTION]);
    }

    if (isRemove && removedValue?.isMaster) {
      const newOptions = removeAllChildPropertyOptions(
        removedValue.value,
        options,
      );

      return onChange(newOptions);
    }

    if (isAdd && option.isMaster) {
      const newOptions = addAllChildPropertyOptions({
        options,
        childProperties: option?.childProperties,
      });

      return onChange(newOptions);
    }

    if (options) {
      return onChange(rejectAllOption(options));
    }

    return onChange([]);
  };

  const options = getPropertiesOptions(data);

  return (
    <SpendReportSelectWrapper width={width}>
      <BaseSelectComponent
        placeholder="Start typing properties"
        noResultsText="No Property Found"
        onInputChange={onChangeDebounced}
        onChange={onChangeHandler}
        onSelectResetsInput={false}
        isLoading={loading}
        options={options}
        ref={selectRef}
        value={value}
        isMulti
      />
    </SpendReportSelectWrapper>
  );
}

const optionPropTypes = oneOfType([array, object, string, number]);
PropertySelect.propTypes = {
  value: optionPropTypes,
  onChange: func,
  width: string,
};
