import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { Gallery } from 'poly-book';

import { setImagesGalleryModal } from '../../redux/imagesGallery.js';

export function ImagesGallery() {
  const { modal } = useSelector(R.prop('imagesGallery'));
  const dispatch = useDispatch();

  const onOutsideClick = () => dispatch(setImagesGalleryModal(null));

  if (!modal) {
    return null;
  }

  return (
    <Gallery
      onOutsideClick={onOutsideClick}
      settings={{
        speed: 500,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        initialSlide: modal.currentIndex,
      }}
      images={modal.images}
    />
  );
}
