import { createGlobalStyle } from 'styled-components';

export const SiteGlobalStyle = createGlobalStyle`
a > em {
  display: contents;
}

em {
  font-style: normal;
  color: #ff8c00;
}

.filepond--root.Poly-File.filepond--hopper {
  font-family: inherit;
  margin-bottom: 0;
}

.Poly-File .filepond--panel-root {
  background-color: transparent;
  border: 1px dashed #4675b4;
}

.Poly-File .filepond--drop-label {
  color: #2d2f33;
}

.Poly-File .filepond--label-action {
  color: #4676b4;
  text-decoration-color: #4676b4;
}

.Poly-File .filepond--label-action:hover {
  color: #014398;
  text-decoration-color: #014398;
}

.Poly-File .filepond--drip-blob {
  background-color: #4676b4;
}

.Poly-File .filepond--item-panel {
  background-color: #4675b4;
}

.Poly-File .filepond--file-action-button {
  background-color: #30517c;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input--invalid {
  border: 1px solid #ee0d45;
}

.react-datepicker-popper {
  z-index: 4;
}

.react-datepicker {
  padding: 27px;
  font-family: inherit;
  background-color: #fff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 9px 0 #dbdde6;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__header {
  padding-top: 0;
  background-color: #fff;
  border-bottom: none;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__current-month {
  margin-bottom: 20px;
  margin-left: 5px;
  font-size: 18px;
  text-align: left;
  color: #6f7a9f;
}

.react-datepicker__navigation {
  top: 25px;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  width: 22px;
  height: 22px;
  right: 25px;
  background-color: #f1f4f5;
  border: none;
  border-radius: 50%;
}

.react-datepicker__navigation--previous {
  right: 60px;
  left: auto;
  
  &::before {
    right: calc(50% - 5px); 
    transform: rotate(225deg);
  }
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 26px;
  height: 26px;
  margin: 3px 7px;
  font-size: 14px;
  
  &:first-child {
    margin-left: 0;
  }
  
  &:last-child {
    margin-right: 0;
  }
}

.react-datepicker__day-name {
  color: #fff;
  
  &::first-letter {
    color: #babfd2;
  }
}

.react-datepicker__day {
  color: #5e6271;
  
  &,
  &:hover {
    border-radius: 50%;
  }
  
  &.react-datepicker__day--outside-month {
    color: #babfd2;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  color: #fff;
  background-color: #9dacdc;
  
  &:hover {
    background-color: #8497d3;
  }
}
`;
