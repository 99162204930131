import React from 'react';
import * as R from 'ramda';
import { Layout, Header, Heading } from 'poly-book';
import styled from 'styled-components';
import { Link } from 'poly-client-routing';
import { arrayOf, func, shape, string } from 'prop-types';

import { Navigation } from '../Navigation.js';
import { LayoutContent } from '../MainLayout.js';
import { reportsConfig } from './reportsConfig.js';
import { useCurrentClientConfig } from '../../hooks/useCurrentClientConfig.js';

const HeaderRow = styled(Header.Row)`
  min-height: 90px;
`;

const GridWrapper = styled.section`
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: ${({ gap }) => gap};
`;

const ContentWrapper = styled(GridWrapper)`
  margin-top: 10px;
  grid-template-columns: repeat(3, 1fr);
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItemS = styled.li`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
`;

const LinkS = styled(Link)`
  font-weight: normal;
  &:hover {
    color: #014398;
  }
`;

function ListItem({ href, name, renderIf, ...props }) {
  if (renderIf(props)) {
    return (
      <ListItemS>
        <LinkS href={href}>{name}</LinkS>
      </ListItemS>
    );
  }

  return null;
}

ListItem.propTypes = {
  name: string.isRequired,
  href: string.isRequired,
  renderIf: func,
};

ListItem.defaultProps = {
  renderIf: R.T,
};

function ReportItem({ title, links, ...props }) {
  return (
    <GridWrapper gap="25px">
      <Heading.h3 lighter>{title}</Heading.h3>
      <GridWrapper>
        <List>
          {links.map((link) => (
            <ListItem key={link.name} {...{ ...link, ...props }} />
          ))}
        </List>
      </GridWrapper>
    </GridWrapper>
  );
}

ReportItem.propTypes = {
  title: string.isRequired,
  links: arrayOf(
    shape({
      name: string.isRequired,
      href: string.isRequired,
    }),
  ),
};

export function ReportsPage() {
  const client = useCurrentClientConfig();

  return (
    <>
      <Layout.Header>
        <Navigation />
        <Header.Sub>
          <HeaderRow>
            <Header.Block>
              <Heading.h1 lighter>Report Directory</Heading.h1>
            </Header.Block>
          </HeaderRow>
        </Header.Sub>
      </Layout.Header>
      <LayoutContent>
        <ContentWrapper gap="50px 10px">
          {reportsConfig.map((config) => (
            <ReportItem key={config.title} {...{ ...config, client }} />
          ))}
        </ContentWrapper>
      </LayoutContent>
    </>
  );
}
