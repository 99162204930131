import React, { useEffect } from 'react';
import { RouterProvider } from 'poly-client-routing';
import styled from 'styled-components';
import { func, node, string } from 'prop-types';
import {
  printVisibleTable as printVisibleTableBase,
  PrintAndExportButtons as PrintAndExportButtonsBase,
  useExportTableContext,
} from 'poly-site-ui';

import { AppProvider } from '../providers/AppProvider.js';

export const useSaveDataForTableExport = (props) => {
  const { setExportTableProps } = useExportTableContext();
  const { total } = props;

  useEffect(() => {
    if (total) {
      setExportTableProps(props);
    } else {
      setExportTableProps({});
    }

    return () => setExportTableProps({});
  }, [total]);
};

const TitleStatus = styled.div`
  font-size: 18px;
  padding: 10px 20px;
`;

export function PrintLayout({ title, status, children }) {
  return (
    <RouterProvider>
      <AppProvider>
        <div>
          <h2 style={{ padding: '10px 20px' }}>{title}</h2>
          {status && <TitleStatus text={status.text} color={status.color} />}
          {children}
        </div>
      </AppProvider>
    </RouterProvider>
  );
}

PrintLayout.propTypes = {
  title: string.isRequired,
  status: string,
  children: node.isRequired,
};

// printVisibleTable :: String -> Object -> Promise _ _
const printVisibleTable = ({ title }) =>
  printVisibleTableBase({ title, PrintLayout });

export function PrintAndExportButtons(props) {
  return (
    <PrintAndExportButtonsBase printFunction={printVisibleTable} {...props} />
  );
}

PrintAndExportButtons.propTypes = {
  ExportButtonComponent: func,
};
