import React from 'react';
import { RoutesGenerator } from 'poly-client-routing';

import { LoginPage } from './pages/LoginPage.js';
import { routes } from './routes.js';
import { ChangePasswordPage } from './pages/ChangePasswordPage.js';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage.js';
import { MainPage } from './pages/ProjectsPage/ProjectsPage.js';
import { PropertiesPage } from './pages/PropertiesPage/PropertiesPage.js';
import { ReportsPage } from './pages/ReportsPage/ReportsPage.js';
import { SpendReportPage } from './pages/SpendReportPage/SpendReportPage.js';
import { ProjectDetailsPage } from './pages/ProjectsPage/ProjectDetailsPage.js';
import { PropertyDetailsPage } from './pages/PropertiesPage/PropertyDetailsPage/PropertyDetailsPage.js';
import { AssetDetailsPage } from './pages/AssetDetails/AssetDetailsPage.js';
import { NotFoundPage } from './pages/NotFoundPage.js';
import { AssetReactiveSpendReportPage } from './pages/AssetReactiveSpendReportPage/AssetReactiveSpendReportPage.js';
import { MainLayout } from './layouts/MainLayout.js';
import { LoginLayout } from './layouts/LoginLayout.js';

const config = [
  {
    layout: LoginLayout,
    routes: [
      [routes.login, LoginPage],
      [routes.changePassword, ChangePasswordPage],
      [routes.forgotPassword, ForgotPasswordPage],
    ],
  },
  {
    layout: MainLayout,
    routes: [
      [routes.home, MainPage],
      [routes.properties, PropertiesPage],
      [routes.reports, ReportsPage],
      [routes.spendReport, SpendReportPage],
      [routes.projectDetails, ProjectDetailsPage],
      [routes.propertyDetails, PropertyDetailsPage],
      [routes.assetDetails, AssetDetailsPage],
      [routes.assetReactivitySpendReport, AssetReactiveSpendReportPage],
      [routes.notFound, NotFoundPage],
    ],
  },
];

export function Router() {
  return <RoutesGenerator config={config} />;
}
