import * as R from 'ramda';
import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

let timer = null;

export const useTableSearch = (props) => {
  const {
    setTableSearchString,
    setPaginationCurrentPage,
    tableSearchString,
    disabled,
  } = props;

  let ref = null;
  const dispatch = useDispatch();

  useEffect(() => {
    if (tableSearchString) {
      dispatch(setTableSearchString(tableSearchString));
    }
  }, [tableSearchString]);

  const [hideCloseIcon, setHideCloseIcon] = useState(true);

  const setRef = (innerRef) => {
    ref = innerRef;
  };
  const getRef = () => ref;

  useEffect(() => {
    if (ref) {
      if (disabled || (R.isEmpty(tableSearchString) && !R.isEmpty(ref))) {
        ref = '';
        setHideCloseIcon(true);
      }
    }
  }, [disabled, tableSearchString, ref, setHideCloseIcon]);

  const onClear = useCallback(() => {
    const input = getRef();
    input.value = '';

    setHideCloseIcon(true);
    setTimeout(() => dispatch(setTableSearchString('')), 10);
  }, [getRef, setHideCloseIcon, setTableSearchString]);

  const onChange = useCallback(
    ({ target: { value } }) => {
      setHideCloseIcon(value.trim().length === 0);

      clearTimeout(timer);
      timer = setTimeout(() => {
        // some pages don't have pagination yet
        if (!R.isNil(setPaginationCurrentPage)) {
          dispatch(setPaginationCurrentPage(1));
        }

        dispatch(setTableSearchString(value));
      }, 300);
    },
    [
      setHideCloseIcon,
      setTableSearchString,
      setPaginationCurrentPage,
      setHideCloseIcon,
    ],
  );

  return {
    onClear,
    onChange,
    hideCloseIcon,
    setRef,
    getRef,
    disabled,
  };
};
