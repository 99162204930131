import PropTypes from 'prop-types';
import styled from 'styled-components';

import Header from '../Header/index.js';

const Grid = styled.section`
  display: grid;
`;

const Layout = styled(Grid)`
  min-height: 100vh;
  grid-template-rows: auto 1fr;
`;

Layout.Header = Header;

Layout.Content = styled(Grid.withComponent('main'))`
  padding: 20px 30px;
  position: relative;
  grid-template-columns: ${({ columns }) => columns};
  grid-gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: ${({ media }) => media};
  }
`;

Layout.Content.propTypes = {
  columns: PropTypes.string,
  media: PropTypes.string,
};

Layout.Content.defaultProps = {
  columns: '1fr',
  media: '1fr',
};

Grid.displayName = 'LayoutGrid';
Layout.displayName = 'Layout';
Layout.Header.displayName = 'Layout.Header';
Layout.Content.displayName = 'Layout.Content';

export default Layout;
