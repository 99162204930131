import React from 'react';
import PropTypes, { arrayOf, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { Arrow, Button, Dropdown, S } from 'poly-book';
import { useHasUserAccessWithPermissionByProps } from 'poly-client-utils';
import { DropDown } from '../components/DropDown.js';

const ItemsDropDown = styled.div`
  display: inline-flex;
  position: relative;
`;

ItemsDropDown.Button = styled(Button)`
  margin: 11px 0;
  border-color: transparent;

  > ${Arrow} {
    margin-top: 0;
    margin-left: 10px;
  }
`;

ItemsDropDown.Items = styled(Dropdown.Items)`
  width: auto;
  max-width: 250px;
  padding: 10px 0;
  left: auto;
  right: 0;

  ${S} {
    display: inline-block;
    margin-top: 5px;
  }
`;

function ModifiedItemComp({ title, onClick }) {
  return (
    <Dropdown.Item onClick={onClick}>
      <S type="title">{title}</S>
    </Dropdown.Item>
  );
}

ModifiedItemComp.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ModifiedItemComp.defaultProps = {
  onClick: () => undefined,
};

function ModifiedItem({ permissions, ...restProps }) {
  const ifHasPermission = useHasUserAccessWithPermissionByProps(permissions);

  return ifHasPermission ? <ModifiedItemComp {...restProps} /> : null;
}

ModifiedItem.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function DropDownByPermissions({ permissions, ...restProps }) {
  const ifHasPermission = useHasUserAccessWithPermissionByProps(permissions);

  return ifHasPermission ? <DropDown {...restProps} /> : null;
}

DropDownByPermissions.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export function GlobalAddButton({ onClick, permissions, items }) {
  return (
    <DropDownByPermissions
      onClick={onClick}
      DropdownComponent={ItemsDropDown}
      HeaderComponent={ItemsDropDown.Button}
      ItemsComponent={ItemsDropDown.Items}
      PipComponent={Arrow}
      header={<S type="title">Add</S>}
      headerProps={{ mode: 'orange' }}
      arrowColor="#fff"
      permissions={permissions}
    >
      {items.map((item) => (
        <ModifiedItem
          key={item.title}
          title={item.title}
          onClick={item.onClick}
          permissions={item.permissions}
        />
      ))}
    </DropDownByPermissions>
  );
}

GlobalAddButton.propTypes = {
  onClick: func.isRequired,
  permissions: arrayOf(string),
  items: arrayOf(
    shape({
      title: string.isRequired,
      onClick: func.isRequired,
      permissions: arrayOf(string),
    }),
  ),
};
